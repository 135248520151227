<template>
  <v-card :loading="loadUserslist">
    <template v-slot:progress>
      <v-progress-linear
        color="secondary"
        indeterminate
        rounded
        height="3"
      ></v-progress-linear>
    </template>
    <v-card-text v-if="usersList[0]">
      <v-data-table
        id="tableUserAuthorizations"
        :headers="headers"
        :items="usersList"
        hide-default-footer
        disable-sort
      >
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title class="primary--text title"
              >Utilisateurs</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="open_dialog_invitation()">
              <v-icon left>mdi-plus</v-icon>
              Inviter un collaborateur
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.user.displayName`]="{ item }">
          <!-- <v-icon small v-if="item.tenant.businessUnitKey"
                >mdi-folder-home</v-icon
              >
              <v-icon v-else small>mdi-home</v-icon> -->
          <span
            class="ml-1 font-weight-bold primary--text"
            style="font-size:12px"
          >
            {{ item.user.displayName | capitalize }}
          </span>
        </template>
        <template v-slot:[`item.user.state`]="{ item }">
          <span>
            <v-tooltip top v-if="item.user.state == 'LIVE'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary"
                  >mdi-check</v-icon
                >
              </template>
              <span> {{ "l'utilisateur s'est connecté" | capitalize }}</span>
            </v-tooltip>

            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-account-question</v-icon>
              </template>
              <span
                >{{ "l'utilisateur ne s'est jamais connecté" | capitalize }}
                <br />
                <span v-if="item.user.registration">
                  Inscription le
                  {{
                    item.user.registration.registrationDate | formatDate("LLL")
                  }}
                </span>
              </span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.user.lastActivity`]="{ item }">
          <span>
            {{ item.user.lastActivity | formatDate("LLL") }}
          </span>
        </template>

        <template v-slot:[`item.user.tenants`]="{ item }">
          <v-chip
            v-for="(tenant, i) of item.tenants"
            :key="i"
            :close="
              currentBu.tenant.apiKey == tenant.tenant.apiKey &&
              currentBu.session.userPath == item.user.userPath
                ? false
                : true
            "
            @click:close="open_dialog_confirmation(tenant, item.user)"
            v-blur
            class="mr-1 mt-1 mb-1"
            :style="color_text_icon(tenant)"
            style="font-size:12px"
            :color="
              tenant.tenant.backColor
                ? tenant.tenant.backColor
                : tenant.tenant.businessUnitKey == undefined
                ? 'primary'
                : 'secondary'
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  left
                  class="soft primary--text"
                  style="font-weight:bold; cursor:pointer"
                  @click="open_edit_user_grant_for_service(tenant, item.user)"
                >
                  {{ tenant.profil.profilLabel[0] }}
                </v-avatar>
              </template>
              <span>Droit d'accès </span>
              <strong>{{ tenant.profil.profilLabel }}</strong> <br />
              <span
                >Cliquez ici pour modifier le droit d'accès de
                l'utilisateur</span
              >
            </v-tooltip>

            <div :style="color_text_icon(tenant)">
              {{ tenant.tenant.label }}
            </div>
            <!-- <br /> -->
            <!-- {{ tenant.profil.profilLabel }} -->
          </v-chip>

          <v-chip
            @click="
              open_dialog_add_service_access_for_user(item.user, item.tenants)
            "
            color="soft"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">mdi-plus</v-icon>
              </template>
              <span>Ajouter un accès </span>
            </v-tooltip>
          </v-chip>
        </template>
        <template v-slot:[`item.user.userPath`]="{ item }">
          <v-btn
            v-if="
              item.user.state != 'LIVE' &&
                currentBu.authorizations['manageUser@auth'] == 'ACCESSIBLE'
            "
            @click="resend_user_invitation(item.user.userPath)"
            color="primary"
            class="ml-1 mt-1"
            :loading="loadResentInvitation"
            ><v-icon left>mdi-email-send</v-icon> Renvoyer l'invitation</v-btn
          >

          <div
            class="ml-1 mt-1"
            v-if="
              item.user.state != 'LIVE' &&
                currentBu.authorizations['manageUser@auth'] == 'ACCESSIBLE'
            "
          >
            <v-tooltip top v-if="item.user.registration">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="copy_valid_url_user(item.user.registration.apiKey)"
                  small
                  ><v-icon small>mdi-content-copy</v-icon></v-btn
                >
              </template>
              <span>Copier le lien d'activation dans le presse papier </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        color="primary"
        @input="fetch_users_list()"
      ></v-pagination>
    </v-card-text>

    <Dialog-revoke-user-authorization
      v-if="dialogRevokeServiceAccessForUser"
      :showDialog.sync="dialogRevokeServiceAccessForUser"
      :serviceSelected="serviceSelected"
      :userSelected="userSelected"
      @submited="fetch_users_list"
      @canceled="close_dialog_revoke_service_access_for_user"
    />

    <Dialog-new-user-invitation
      v-if="dialogInvitation"
      :showDialog.sync="dialogInvitation"
      :tenantOverview="tenantOverview"
      @submited="fetch_users_list"
      @canceled="close_dialog_edit_user_grant_by_service"
    />

    <Dialog-add-service-access-for-user
      v-if="dialogAddServiceAccessForUser"
      :showDialog.sync="dialogAddServiceAccessForUser"
      :tenantOverview="tenantOverview"
      :userSelected="userSelected"
      :currentUserServices="currentUserServices"
      @submited="fetch_users_list"
      @canceled="close_dialog_add_service_access_for_user"
    />

    <Edit-user-grant-of-the-service
      v-if="dialogEditUserGrantByService"
      :showDialog.sync="dialogEditUserGrantByService"
      :serviceSelected="serviceSelected"
      :userSelected="userSelected"
      :currentUserGrantPath="currentUserGrantOfTheServiceSelected"
      @submited="fetch_users_list"
    />

    <v-dialog v-model="dialogUnauthorizedAction" persistent max-width="800">
      <v-card>
        <v-card-title class="title primary--text">
          Action Impossible
        </v-card-title>
        <v-card-text class="mt-2">
          <v-alert type="warning" text dense class="body-2 ma-0">
            Il est impossible d'effectuer cette action. Afin de retirer vos
            propres droits de ce compte/classeur, veuillez changer de
            compte/classeur pour exécuter cette action.
          </v-alert>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            width="100px"
            text
            @click="dialogUnauthorizedAction = false"
          >
            {{ "fermer" | capitalize }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant";
import DialogNewUserInvitation from "./DialogNewUserInvitation.vue";
import EditUserGrantOfTheService from "./EditUserGrantOfTheService.vue";
import DialogAddServiceAccessForUser from "./DialogAddServiceAccessForUser.vue";
import DialogRevokeUserAuthorization from "./DialogRevokeUserAuthorization.vue";

export default {
  name: "UserList",
  components: {
    DialogNewUserInvitation,
    EditUserGrantOfTheService,
    DialogAddServiceAccessForUser,
    DialogRevokeUserAuthorization
  },
  computed: {
    ...mapGetters(["currentBu", "labelTenant", "labelCurrent"])
  },
  props: {
    tenantOverview: {
      type: Object,
      default: null,
      require: true
    }
  },
  data() {
    return {
      // Common
      ClassTenant: null,
      urlBack: process.env.VUE_APP_URL_HORUS + "auth/user/confirm/",

      // Table
      loadUserslist: false,
      usersList: [],
      pattern: null,
      totalPages: null,
      totalElements: 0,
      size: 10,
      page: 1,
      headers: [
        {
          text: "ÉTAT",
          align: "center",
          width: "36px",
          value: "user.state"
        },
        {
          text: "NOM",
          align: "left",
          width: "220px",
          value: "user.displayName"
        },
        {
          text: "EMAIL",
          align: "left",
          width: "360px",
          value: "user.email"
        },
        {
          text: "DERNIÈRE ACTIVITÉ",
          align: "left",
          width: "220px",
          value: "user.lastActivity"
        },
        {
          text: "ACCÈS",
          align: "left",
          sortable: false,
          value: "user.tenants"
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "user.userPath"
        }
      ],
      dialogUnauthorizedAction: false, // dialog avertissement pour les actions de la table.

      serviceSelected: null, // pour confirmation et grant, selection depuis la table.
      userSelected: null, // pour confirmation et grant, selection depuis la table.
      currentUserServices: [],
      currentUserGrantOfTheServiceSelected: null,

      // Grant
      dialogAddServiceAccessForUser: false,
      dialogEditUserGrantByService: false,

      // Confirmation
      dialogRevokeServiceAccessForUser: false,

      // Invitation
      dialogInvitation: false,
      // resent invitation
      loadResentInvitation: false
    };
  },
  created() {
    this.ClassTenant = new Tenant();
    this.fetch_users_list();
  },
  methods: {
    copy_valid_url_user(apiKeyUser) {
      let copy = this.urlBack + apiKeyUser;

      navigator.clipboard.writeText(copy);

      this.$store.commit("update_notif", {
        text: "L'URL de validation a été copié dans le presse papier",
        context: "success"
      });
    },

    color_text_icon(tenant) {
      if (tenant.tenant.textColor) {
        return "color:" + tenant.tenant.textColor;
      } else if (tenant.tenant.type == "TENANT") {
        return "color:white";
      } else {
        return "color:black";
      }
    },
    // INIT
    async fetch_users_list() {
      try {
        this.loadUserslist = true;
        const response = await this.ClassTenant.authorization_users_list(
          this.currentBu.tenant.apiKey,
          this.size,
          this.page,
          { pattern: this.pattern }
        );
        this.usersList = response.entities;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadUserslist = false;
      }
    },

    // REVOKE service Access
    open_dialog_confirmation(service, user) {
      if (
        this.currentBu.tenant.apiKey == service.tenant.apiKey &&
        this.currentBu.session.userPath == user.userPath
      ) {
        this.dialogUnauthorizedAction = true;
      } else {
        this.serviceSelected = service;
        this.userSelected = user;
        this.dialogRevokeServiceAccessForUser = true;
      }
    },
    close_dialog_revoke_service_access_for_user() {
      this.serviceSelected = null;
      this.userSelected = null;
    },

    // ADD service access
    open_dialog_add_service_access_for_user(user, currentUserServices) {
      this.currentUserServices = currentUserServices;
      this.userSelected = user;
      this.dialogAddServiceAccessForUser = true;
    },
    close_dialog_add_service_access_for_user() {
      this.currentUserServices = [];
      this.userSelected = null;
      this.serviceSelected = null;
    },

    // INVITATION
    open_dialog_invitation() {
      this.dialogInvitation = true;
    },

    async resend_user_invitation(userPath) {
      try {
        this.loadResentInvitation = true;

        await this.ClassTenant.resent_user_invitation(
          this.currentBu.tenant.apiKey,
          userPath
        );

        this.$store.commit("update_notif", {
          text: "Invitation envoyée",
          context: "success"
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.fetch_users_list();
        this.loadResentInvitation = false;
      }
    },

    // EDIT USER GRANT FOR THE SERVICE
    open_edit_user_grant_for_service(tenant, user) {
      if (
        this.currentBu.tenant.apiKey == tenant.tenant.apiKey &&
        this.currentBu.session.userPath == user.userPath
      ) {
        // If current user is userSelected and the Tenant/BU selected is the current Tenant/BU.
        this.dialogUnauthorizedAction = true;
      } else {
        this.userSelected = user;
        this.currentUserGrantOfTheServiceSelected = tenant.profil.profilPath; // current grant path (profil == object grant).
        this.serviceSelected = tenant.tenant; // BU/Tenant selected.
        this.dialogEditUserGrantByService = true;
      }
    },
    close_dialog_edit_user_grant_by_service() {
      this.userSelected = null;
      this.currentUserGrantOfTheServiceSelected = null;
      this.serviceSelected = null;
      this.dialogEditUserGrantByService = false;
    }
  }
};
</script>

<style>
#tableUserAuthorizations thead tr th {
  color: var(--v-primary-base);
  padding: 24px 16px;
}
#tableUserAuthorizations tbody tr td {
  padding: 10px 16px;
}
</style>
