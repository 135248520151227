import Vue from "vue";
import Router from "vue-router";

// Routes
import PagesRoutes from "./pages.routes";
import UsersRoutes from "./users.routes";

Vue.use(Router);

export const routes = [
  {
    path: "/",
    redirect: "/auth/signin"
  },
  ...PagesRoutes,
  ...UsersRoutes,
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/pages/error/NotFoundPage.vue"),
    meta: {
      layout: "error"
    }
  }
];

const router = new Router({
  mode: "hash",
  // NOTE scrollBehavior remonte en haut de page a chaque changement de page
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes
});

// NOTE verification de l'état de l'utilisateur s'il est connecter ou pas.
router.beforeEach(async (to, from, next) => {
  const store = require("@/store/index");
  const authUser = store.default.getters.userConnected;

  if (to.meta.requiresAuth && !authUser) {
    next({
      name: "auth-signin"
    });
  } else {
    next();
  }
});

export default router;
