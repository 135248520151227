<template>
  <v-card min-height="100%" color="transparent" flat class="d-flex flex-column">
    <Grant-header
      v-if="renderComponent"
      @grantSelected="grant_selected"
      class="mb-2"
      @searchModules="upd_search_modules"
    />
    <Grant-list
      v-if="renderComponent"
      :selectedGrant="selectedGrant"
      :searchModules="searchModules"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GrantHeader from "./GrantHeader.vue";
import GrantList from "./GrantList.vue";

export default {
  components: { GrantList, GrantHeader },
  name: "PageGrant",
  computed: {
    ...mapGetters(["currentBu"])
  },
  data() {
    return {
      renderComponent: true,
      selectedGrant: null,
      searchModules: null
    };
  },
  watch: {
    currentBu() {
      if (
        this.currentBu.authorizations["manageTenant@auth"] == "ACCESSIBLE" &&
        this.currentBu !== null
      ) {
        this.forceRerender();
      } else if (this.currentBu !== null) {
        this.$router.push({ name: "myOsmose" });
      }
    }
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    grant_selected(grant) {
      this.selectedGrant = grant;
    },
    upd_search_modules(search) {
      this.searchModules = search;
    }
  }
};
</script>
