export default [
  {
    path: "/myoblige",
    name: "dashboard-my-oblige",
    component: () => import("@/pages/myOblige/myObligeDashboard.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/contratslist",
    name: "my-contract-list",
    component: () => import("@/pages/mycontracts/MyContractsList.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/contractdetail/:contractPath",
    name: "contract-detail",
    meta: {
      requiresAuth: true
    },
    component: () => import("../pages/mycontracts/MyContractDetail")
  },
  {
    path: "/contractdetail/:contractPath/changecharacteristic",
    name: "change-characteristic",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import("../pages/mycontracts/MyContractChangeCharacteristic")
  },
  {
    path: "/contractdetail/:contractPath/audit",
    name: "audit-contract",
    meta: {
      requiresAuth: true
    },
    component: () => import("../pages/mycontracts/AuditContract")
  },
  {
    path: "/myalerts",
    name: "user-myAlerts",
    component: () => import("@/pages/myAlerts/myAlerts.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/managementalerts",
    name: "user-myAlerts-management",
    component: () => import("@/pages/myAlerts/myAlertManagement.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profil",
    name: "user-profil",
    component: () => import("@/pages/myProfil/profil.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/authorizations",
    name: "user-authorizations",
    component: () => import("@/pages/authorizations/authorizations.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing",
    name: "user-billing",
    component: () => import("@/pages/billing/billing.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rate",
    name: "user-rate",
    component: () => import("@/pages/billing/Rate.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/faq",
    name: "users-faq",
    component: () => import("@/pages/utility/HelpPage.vue"),
    meta: {
      requiresAuth: true
    }
  }
];
