<template>
  <div
    class="d-flex text-center flex-column flex-md-row flex-grow-1 backgroundPages"
  >
    <!-- backgroundImgDoodle -->
    <v-sheet
      class="layout-side mx-auto d-none d-md-flex flex-md-column justify-space-between px-2 secondary"
    >
      <div class="pa-2 d-flex flex-column justify-start">
        <v-img
          src="@/assets/OBLIGE_LOGO_RVB_COLOR.svg"
          contain
          style="cursor:pointer"
          @click="toLoginPage()"
        ></v-img>
        <div class="title my-2 primary--text">
          {{ $t("oblige.signinSubtitle") | capitalize }}
        </div>
      </div>

      <div class="pa-2">
        <v-img src="@/assets/images/ObligeBook.gif"></v-img>
        <!-- <SupportInfo :locale="this.$i18n.locale" type="btn" /> -->
        <!-- <HelpAuthPage class="pl-2" type="icon" /> -->
      </div>
    </v-sheet>

    <div
      class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
    >
      <div style="width:100%"></div>
      <div class="layout-content ma-auto w-full">
        <slot></slot>
        <div class="pa-3" v-if="this.$route.name == 'auth-signup'">
          <span class="subtitle-2 font-weight-bold primary--text">
            {{ $t("register.ctaSignin") | capitalize }}
          </span>
          <span
            @click="goToRoute('auth-signin')"
            style="cursor: pointer;"
            class="body-1 primary--text"
          >
            <u>{{ $t("register.signin") | capitalize }}</u>
          </span>
        </div>
      </div>
      <div class="overline mt-4"><toolbar-language /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToolbarLanguage from "@/components/toolbar/ToolbarLanguage";
import SupportInfo from "@/components/common/SupportInfo";

export default {
  name: "AuthLayout",
  components: {
    ToolbarLanguage,
    SupportInfo
  },
  computed: {
    ...mapState("app", ["product"])
  },
  mounted() {
    if (document.getElementById("ze-snippet")) {
      zE("messenger:set", "zIndex", -100);
    }
    this.langDetect();
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route });
    },

    langDetect() {
      const supported = ["en", "fr"];
      try {
        // get browser default language
        const { 0: browserLang } = navigator.language.split("-");

        if (supported.includes(browserLang)) this.$i18n.locale = browserLang;
      } catch (error) {
        console.error(error);
      }
    },

    toLoginPage() {
      if (this.$route.name == "utility-maintenance") {
        this.$router.push({ name: "auth-signin" });
      } else {
        window.open("https://www.oblige.fr", "_blank").focus();
      }
    }
  }
};
</script>

<style scoped>
.layout-side {
  width: 420px;
}
.layout-content {
  max-width: 480px;
}
.backgroundImgDoodle {
  background-image: url("/images/illustrations/background-doodle-yellow.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>
