var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column justify-space-between",attrs:{"height":"100%","min-height":"120","color":_vm.service.backColor != null && _vm.service.backColor != ''
      ? _vm.service.backColor
      : 'secondary'}},[_c('v-card-title',{staticClass:"pa-1 subtitle-1",style:(_vm.service.textColor ? 'color:' + _vm.service.textColor : 'color:black')},[_vm._v(" "+_vm._s(_vm.service.label)+" ")]),_c('v-card-actions',[(!_vm.isCurrentService)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.service.textColor ? _vm.service.textColor : 'black',"fab":"","icon":"","x-small":""},on:{"click":_vm.open_delete_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-empty")])],1)]}}],null,false,3349471778)},[_c('span',[_vm._v("Supprimer le classeur")])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.service.textColor ? _vm.service.textColor : 'black',"fab":"","icon":"","x-small":""},on:{"click":_vm.open_edit_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Modifier le classeur")])])],1),(_vm.dialogSetService)?_c('Dialog-set-service',{attrs:{"showDialog":_vm.dialogSetService,"editData":_vm.service,"isEdit":""},on:{"update:showDialog":function($event){_vm.dialogSetService=$event},"update:show-dialog":function($event){_vm.dialogSetService=$event},"submited":_vm.service_updated}}):_vm._e(),(_vm.dialogDeleteService)?_c('Dialog-delete-service',{attrs:{"showDialog":_vm.dialogDeleteService,"service":_vm.service},on:{"update:showDialog":function($event){_vm.dialogDeleteService=$event},"update:show-dialog":function($event){_vm.dialogDeleteService=$event},"submited":_vm.service_updated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }