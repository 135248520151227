<template>
  <v-dialog v-model="openDialog" max-width="800">
    <v-form
      v-model="validForm"
      ref="formSetGrant"
      lazy-validation
      @submit.prevent="submit_form"
    >
      <v-card>
        <v-card-title class="title primary--text">
          <span v-if="!isEdit">
            Création d'un nouveau profil de droit utilisateur
          </span>
          <span v-else>Modifier le profil de droit utilisateur</span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row dense>
            <v-col cols="9">
              <v-text-field
                v-model="form.profilLabel"
                :rules="[rules.required, rules.maxLength(48)]"
                :disabled="loadForm"
                label="Nom du rôle (obligatoire)"
                outlined
                dense
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="form.mnemo"
                :rules="[rules.maxLength(3)]"
                :disabled="loadForm"
                maxlength="3"
                counter
                label="Trigramme"
                outlined
                dense
                color="primary"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            :disabled="loadForm"
            @click="cancel_dialog"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            :loading="loadForm"
            :disabled="!validForm"
            type="submit"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";

export default {
  name: "DialogSetGrant",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    isEdit: {
      type: Boolean,
      require: false,
      default: false
    },
    editData: {
      type: Object,
      require: false, // true if isEdit is true.
      default: null
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },

    ...mapGetters(["apiKey"])
  },
  data() {
    return {
      ClassTenant: null,
      validForm: false,
      loadForm: false,
      form: {
        profilPath: null,
        profilLabel: null,
        mnemo: null,
        custom: true
      },
      rules: {
        required: v => !!v || "Ce champ est requis.",
        maxLength: len => v =>
          (v || "").length <= len || `${len} caractères maximum`
      }
    };
  },

  created() {
    if (this.ClassTenant == null) {
      this.ClassTenant = new Tenant();
    }
    if (this.isEdit) {
      this.assign_edit_data();
    }
  },
  methods: {
    async submit_form() {
      try {
        if (this.$refs.formSetGrant.validate()) {
          this.set_grant();
        } else {
          this.$refs.formSetGrant.validate();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async set_grant() {
      try {
        this.loadForm = true;
        await this.ClassTenant.create_grant(this.apiKey, this.form);
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("submited");
        this.loadForm = false;
        this.cancel_dialog();
      }
    },

    assign_edit_data() {
      for (const key in this.editData) {
        let check = Object.hasOwnProperty.call(this.form, key);
        if (check) {
          this.form[key] = this.editData[key];
        }
      }
    },

    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
