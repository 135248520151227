export default {
  // apps quickmenu
  // apps: [
  //   {
  //     icon: "mdi-email-outline",
  //     text: "Profile",
  //     key: "menu.profile",
  //     subtitle: "Hey!",
  //     link: "/users/profil"
  //   },
  //   {
  //     icon: "mdi-octagon-outline",
  //     text: "Management",
  //     key: "menu.management",
  //     subtitle: "Hey!",
  //     link: "/users/management"
  //   }
  // ],

  // user dropdown menu
  user: [
    {
      icon: "mdi-account-box-outline",
      key: "menu.profile",
      text: "profile",
      link: "/profil",
      dialog: true,
      dialogComponent: "profil"
    },
    {
      icon: "mdi-account-key-outline",
      key: "menu.authorizations",
      text: "authorizations",
      link: "/authorizations",
      dialog: true,
      dialogComponent: "authorizations"
    },
    // {
    //   icon: "mdi-account-key-outline",
    //   key: "menu.authorizations2",
    //   text: "authorizations",
    //   link: "/authorizations2",
    //   dialog: true,
    //   dialogComponent: "authorizations2"
    // },
    {
      icon: "mdi-domain",
      key: "menu.billing",
      text: "billing",
      link: "/billing",
      dialog: true,
      dialogComponent: "billing"
    },
    // {
    //   icon: "mdi-cash",
    //   key: "menu.rate",
    //   text: "rate",
    //   link: "/rate",
    //   dialog: true,
    //   dialogComponent: "rate"
    // },
    {
      icon: "mdi-bell-outline",
      key: "oblige.alertManagement",
      text: "alertManagement",
      link: "/managementalerts",
      dialog: true,
      dialogComponent: "managementalerts"
    }
  ]
};
