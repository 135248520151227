export default {
  form: {
    required: "required",
    validate: "validate",
    cancel: "Annuler",
    fieldsRequired: "some mandatory fields are not filled in",
    addressEmail: "address email",
    invalidEmail: "invalid email",
    invalidDate: "invalid date format",
    invalidDate1900: "warning, year less than 1900",
    invalidDate3000: "warning, year more than 3000",
    noAction: "no action",
    securityPasswordDifferent: "passwords are different",
    or: "or",
    success: "operation performed",
    error: "erreur",
    errorSelect: "select a field",
    maxCharacters: "max characters",
    authenticationMode: "authentication mode",
    EnterCodeReceivedBySms: "Enter the code received by SMS"
  },

  support: {
    support: "support",
    codeSupport: "support code",
    generateSupportLink: "access support creation",
    keySupport: "access support",
    keyMsg: "please copy this code and pass it on to support",
    copyToClipboard: "copy to clipboard",
    close: "close",
    msgToClipBoard: "link copy to clipboard",
    generateCodeSupport: "generate a support code",
    regenerateCodeSupport: "regenerate a support code",
    generateAbout:
      "the button below generates a code that you can send to support so that they can access your space"
  },

  auth: {
    welcome: "welcome",
    forgotPassword: "forgot password ?",
    resetPass: "reset",
    resetPassForm: "to change the password",
    changePass: "password customization",
    confirmYour: "confirm your",
    loginPage: "signin page",
    signupPage: "signup page",
    forgotPage: "forgot password",
    loginButton: "sign in",
    logout: "logout",
    noAccount: "you don't have an account ?",
    createAccount: "create one here",
    registrationButton: "sign up",
    yourPassword: "your password",
    accountAlreadyExist: "you already have an account",
    signinRedirection: "connect you",
    forgotSubtitle:
      "Enter your account email address and we will send you a link to reset your password.",
    signInNOK:
      "connection error, check if your account is validate or if the login / password pair is correct",
    changePassOk: "your password has been modify",
    tokenExpired: "session time ended please reconnect",
    passeNotIdentical: "the passwords are not the same",
    toAllow: "you are not authorized to access this resource",
    youHaveDaysLeftChangePwd:
      "you have {days} days left to change your password",
    changePwd: "do you want to change it ?",
    signUpOk: "your registration has been taken into account",
    userDisabled: "your account is desactivated, please contact the support.",
    securityUserUnknown: "the account does not exist",
    securityTokenInvalid: "your session has expired, please reconnect",
    securityAccessDenied:
      "you are not allowed to access to the last acount or workbook which you visited the last time you used Oblige, please try to reconnect you. ",
    dataTooMany: "email is undeterminated (multiples)",
    accountExists: "an account with this address already exists",
    modalLiveText: "the account attached to the",
    modalLiveTextAfter:
      "adress already. If you forget you can change your password. In this case an email will be sent to you containing a temporary password.",
    modalLiveTextAction: "would you like to reset the password ?",
    accountUnconfirmedSignUp: "this account exist but has never been validate",
    textSignUp:
      "we have taken into account your request to create an account, you will receive an email at",
    textSignUpAfter:
      "very soon containing the instructions to follow in order to acces to Osmose",
    titlePwdForgot: "password change made",
    textPwdForgot: "an email as been sent to",
    textPwdForgotAfter:
      "address containing your tomporary password which you will need to change the next time you log in",
    registrationDate: "first time registration :",
    pwdValidateDuration: "your new password will be valid for",
    userDisabledTitle: "user account disable",
    renewCredentialsExpired:
      "It is possible that the password renewal period has expired. In that cas please click on forgot password.  ",
    passwordPolicyHistory: "Mot de passe différent des mots de passes des",
    passwordPolicyMinDigit: "Nombre minimum de chiffre",
    passwordPolicyMinLen: "longueur minimale du mot de passe",
    passwordPolicyMaxLen: "longueur maximal du mot de passe",
    passwordPolicyMinSpecial: "Nombre minimum de caractère spécial",
    passwordPolicyMinUpper: "nombre minimum de majuscule",
    passwordValidity: "durée de validité"
    //A traduire OBL-1054
  },

  profile: {
    password: "mot de passe",
    name: "name",
    language: "language",
    email: "email",
    mobilePhone: "mobile phone",
    displayName: "display name",
    myProfil: "my profile",
    slackChannel: "slack channel",
    slackId: "slack token",
    yourInformation: "your informations",
    communicationMethod: "how to join me ?",
    authenticationMode: "authentication mode",
    profileTypes: {
      administrator: "administrator",
      manager: "manager",
      consultant: "consultant",
      guess: "guest"
    }
  },

  common: {
    add: "Add",
    cancel: "Cancel",
    description: "Description",
    delete: "Delete",
    title: "Title",
    save: "Save",
    faq: "FAQ",
    acceptance: "",
    contact: "Contact Us",
    tos: "Terms of Service",
    policy: "Privacy Policy",
    validate: "validate",
    none: "no"
  },
  board: {
    titlePlaceholder: "Enter a title for this card",
    deleteDescription: "Are you sure you want to delete this card?",
    editCard: "Edit Card",
    deleteCard: "Delete Card",
    state: {
      TODO: "TO DO",
      INPROGRESS: "INPROGRESS",
      TESTING: "TESTING",
      DONE: "DONE"
    }
  },
  chat: {
    online: "Users Online ({count})",
    addChannel: "Add Channel",
    channel: "Channel | Channels",
    message: "Message"
  },
  email: {
    compose: "Compose Email",
    send: "Send",
    subject: "Subject",
    labels: "Labels",
    emptyList: "Empty email list",
    inbox: "Inbox",
    sent: "Sent",
    drafts: "Drafts",
    starred: "Starred",
    trash: "Trash",
    work: "Work",
    invoice: "Invoice"
  },
  todo: {
    addTask: "Add Task",
    tasks: "Tasks",
    completed: "Completed",
    labels: "Labels"
  },
  dashboard: {
    activity: "Activity",
    weeklySales: "Weekly Sales",
    sales: "Sales",
    recentOrders: "Recent Orders",
    sources: "Traffic Sources",
    lastweek: "vs last week",
    orders: "Orders",
    customers: "Customers",
    tickets: "Support Tickets",
    viewReport: "View Report"
  },
  usermenu: {
    profile: "Profile",
    signin: "Sign In",
    dashboard: "Dashboard",
    signout: "Sign Out"
  },
  error: {
    notfound: "Page Not Found",
    other: "An Error Ocurred"
  },
  check: {
    title: "Set New Password",
    backtosign: "Back to Sign In",
    newpassword: "New Password",
    button: "Set new password and Sign in",
    error: "The action link is invalid",
    verifylink: "Verifying link...",
    verifyemail: "Verifying email address...",
    emailverified: "Email verified! Redirecting..."
  },
  forgot: {
    title: "Forgot Password?",
    subtitle:
      "Enter your account email address and we will send you a link to reset your password.",
    email: "Email",
    button: "Request Password Reset",
    backtosign: "Back to Sign In"
  },
  login: {
    title: "Sign In",
    email: "Email",
    password: "Password",
    button: "Sign In",
    orsign: "Or sign in with",
    forgot: "Forgot password?",
    noaccount: "Don't have an account?",
    create: "Create one here",
    error: "The email / password combination is invalid"
  },
  register: {
    ctaSignin: "already have an account ?",
    ctaSignup: "i don't have an account yet 🙁",
    title: "Create your Oblige account",
    displayName: "Display name",
    tenantName: "Account name",
    lastName: "Lastname",
    firstName: "Firstname",
    email: "Email",
    password: "Password",
    button: "next",
    registration: "registration",
    orsign: "Or sign up with",
    agree: "By signing up, you agree to the",
    account: "Already have an account?",
    signin: "Sign In"
  },
  utility: {
    maintenance: "In Maintenance"
  },
  faq: {
    call: "Have other questions? Please reach out "
  },

  rate: {
    rateCard: "plan tarifaire",
    rateCards: "plans tarifaires",
    noRateSelected: "vous n'avez aucun plan tarifaire de sélectionné",
    changeRate: "Changer de plan tarifaire",
    noDiscountActive: "Aucun code promotionnel actif",
    access: "access",
    essential: "essentiel",
    premium: "premium",
    month: "mois",
    twelveMonths: "engagement 12 mois",
    upTo: "jusqu'à {number} contrats",
    noEngagement: "sans engagement",
    oneUser: "1 seul utilisateur",
    numberUser: "jusqu'à {number} utilisateurs",
    contratheque: "contrathèque",
    notifications: "notifications automatiques",
    support: "support",
    email: "e-mail",
    chat: "chat",
    phone: "téléphone",
    mapExport: "export de la cartographie",
    trialPeriod: "essai gratuit 30 jours",
    moreContract: "vous avez au-delà de 250 contrats à stocker ?",
    consultOffer: "consultez-nous pour une offre personnalisée",
    moreQuestion: "Pour toute question, contactez-nous !",
    annualCheckout: "paiement annuel",
    mensualCheckout: "paiement mensuel",
    twentyDiscount: "Economisez 20%",
    expiryDate: "Expire le",
    setInfoBilling: "editer les informations de facturation",
    titleNoInfoBilling: "Aucune information de facturation",
    textNoInfoBilling:
      "Vous ne pouvez pas changer de forfait, car aucune information de facturation n'est renseigné"
  },

  ecommerce: {
    products: "Products",
    filters: "Filters",
    collections: "Collections",
    priceRange: "Price Range",
    customerReviews: "Customer Reviews",
    up: "and up",
    brand: "Brand",
    search: "Search for product",
    results: "Results ( {0} of {1} )",
    orders: "Orders",
    shipping: "Shipping",
    freeShipping: "Free Shipping",
    inStock: "In Stock",
    quantity: "Quantity",
    addToCart: "Add To Cart",
    buyNow: "Buy Now",
    price: "Price",
    about: "About this item",
    description: "Description",
    reviews: "Reviews",
    details: "Product Details",
    cart: "Cart",
    summary: "Order Summary",
    total: "Total",
    discount: "Discount",
    subtotal: "Subtotal",
    continue: "Continue Shopping",
    checkout: "Checkout"
  },
  menu: {
    search: 'Search (press "ctrl + /" to focus)',
    dashboard: "Dashboard",
    logout: "Logout",
    profile: "Profile",
    management: "Management",
    blank: "Blank Page",
    pages: "Pages",
    others: "Others",
    email: "Email",
    chat: "Chat",
    todo: "To Do",
    board: "Task Board",
    users: "Users",
    usersList: "List",
    usersEdit: "Edit",
    ecommerce: "Ecommerce",
    ecommerceList: "Products",
    ecommerceProductDetails: "Product Details",
    ecommerceOrders: "Orders",
    ecommerceCart: "Cart",
    auth: "Auth Pages",
    authLogin: "Signin / Login",
    authRegister: "Signup / Register",
    authVerify: "Verify Email",
    authForgot: "Forgot Password",
    authReset: "Reset Password",
    errorPages: "Error Pages",
    errorNotFound: "Not Found / 404",
    errorUnexpected: "Unexpected / 500",
    utilityPages: "Utility Pages",
    utilityMaintenance: "Maintenance",
    utilitySoon: "Coming Soon",
    utilityHelp: "FAQs / Help",
    levels: "Menu Levels",
    disabled: "Menu Disabled",
    docs: "Documentation",
    feedback: "Feedback",
    support: "Support",
    landingPage: "Landing Page",
    pricingPage: "Pricing Page",
    billing: "Billing",
    rate: " Tariff plans",
    authorizations: "Authorizations"
  },
  // Vuetify components translations
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items..."
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending."
      },
      sortBy: "Sort by"
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}"
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year"
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}"
      }
    },
    calendar: {
      moreEvents: "{0} more"
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)"
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}"
      }
    }
  },
  uploadFile: {
    sendingCompleted: "the file have been sent",
    multipleSendingCompleted: "the files have been sent",
    listUploadFiles: "list uploaded files",
    success_infoSendingFile:
      "your files is now being analyzed. You will receive an email to notify you as soon as it is ready to be reviewed by you. You are one step away from securing yourself on this contract.",
    success_infoSendingFiles:
      "your files are now being analyzed. You will receive an email to notify you as soon as they are ready to be reviewed by you. You are one step away from securing yourself on these contract.",
    maxSize: "the file exceeds {size} Mo",
    error_multipleFiles: "of your files could not be sent",
    error_singleFiles: "of your files could not be sent"
  },
  oblige: {
    //TEXT AUTH
    signIn: "sign in",
    forgotPassword: "forgot password",
    resetPass: "reset",
    resetPassForm: "to change the password",
    invalidEmail: "invalid email",
    signInTitle: "to login",
    signInOK: "you are well connected",
    logOut: "logout",
    changePass: "password customization",
    confirmYour: "confirm your",
    loginPage: "signin page",
    signupPage: "signup page",
    forgotPage: "forgot password",
    loginButton: "sign in",
    noAccount: "you don't have an account ?",
    createAccount: "create one here",
    registrationButton: "sign up",
    accountAlreadyExist: "you already have an account",
    signinRedirection: "connect you",
    signUp: "registration",
    forgotSubtitle:
      "Enter your account email address and we will send you a link to reset your password.",
    yourRoleIis: "your role is",

    infoSociety: "information about the company",

    //Auth Message
    helpAuth: "authentification help",
    helpAuthLink: "need help with connection ?",
    signInNOK:
      "wrong password, check if your account is validate or if the login / password pair is correct",
    changePassOk: "your password has been modify",
    tokenExpired: "session time ended please reconnect",
    pwdForgot:
      "instructions for resetting your password have been sent by email",
    passeNotIdentical: "the passwords are not the same",
    toAllow: "you are not authorized to access this resource",
    daysLeftBeforeChangePwd: "days left before changing your password : ",
    youHaveDaysLeftChangePwd:
      "you have {days} days left to change your password",
    changePwd: "do you want to change the password ?",
    editMyPassword: "edit my password",
    toChangePassword: "change password",
    signUpOk: "your registration has been taken into account",
    signUpUserExist:
      "user already exists, if you have an : Osmose account, try to connect with it",
    logOutMessage: "you are now disconnected",
    userDisabled:
      "your account is desactivated, please contact the support. It is possible that the password renewal period has expired. In that cas please click on forgot password.",
    userDisabledTitle: "user account disable",
    userNoconfirmedTitle: "user account not confirmed",
    userNoconfirmed:
      "Vous y êtes presque ! validez votre compte  en cliquant sur le lien envoyé dans votre boîte mail", // Trad
    renewCredentialsExpired:
      "the password renewal period has expired. In that cas please click on forgot password.",
    securityUserUnknown:
      "the account does not exist, if you think your account exists, please contact the support",
    securityTokenInvalid: "your session has expired, please reconnect",
    dataTooMany: "email is undeterminated (multiples)",
    securityAccessDenied:
      "you are not allowed to access to the last acount or workbook which you visited the last time you used Oblige, please try to reconnect you. ",
    accountExists: "an account with this address already exists",
    modalLiveText: "the account attached to the",
    modalLiveTextAfter:
      "adress already. If you forget you can change your password. In this case an email will be sent to you containing a temporary password.",
    modalLiveTextAction: "would you like to reset the password ?",
    accountUnconfirmedSignUp: "this account exist but has never been validate",
    textSignUp:
      "we have taken into account your request to create an account, you will receive an email at",
    textSignUpAfter:
      "very soon containing the instructions to follow in order to acces to Osmose",
    titlePwdForgot: "password renewal request made",
    textPwdForgot: "an email as been sent to",
    textPwdForgotAfter:
      "address containing a tomporary password which you will need to change the next time you log in",
    registrationDate: "first time registration :",
    userNotCertified:
      "unothorized action, validate your account to be able to execute this action",
    pwdValidateDuration: "your new password will be valid for 3 months",
    //FIN
    next: "next",
    previous: "previous",
    newContract: "nouveau contrat",
    whereIAm: "workspace or workbook that I actually logged in",
    fileLocation: "where the file is stored",
    noTag: "no tag",
    changeWorkspace: "change your workspace",
    chooseWorkspace: "choose your workspace",
    moreOptions: "more options",
    contract: "contract",
    total: "total",
    iAccept: "i accept",
    iRefuse: "i refuse",
    cookiePolicy: "policy about confidentiality and cookies",
    messageAcceptCookieP1:
      "Oblige ask you to accept our cookies to allow you the registration of your email address on the login page and on the forgot password page, it will allow you to have a better experience in our application. Accept cookie also register the last acount or workbook you visited during your last connection and allow you to rejoin this acount on your next connection. If you want more information about our cookies and the processing of your personal data, read our ",
    messageAcceptCookieP2: "in our",
    messageAcceptCookieP3:
      "Do you accept these cookies and the implications associated with the use of your personal data?",
    cookieParameter: "your cookies parameters",
    memberArea: "member area",
    signIn: "sign in",
    forgotPassword: "forgot password",
    password: "password",
    close: "close",
    later: "later",
    closeMsg: "close message",
    resetPass: "reset",
    resetPassForm: "to change the password",
    required: "required",
    invalidEmail: "invalid email",
    signInTitle: "to login",
    signInOK: "you are well connected",
    logOut: "logout",
    changePass: "password customization",
    confirmYour: "confirm your",
    toAllow: "you are not authorized to access this resource",
    yes: "yes",
    no: "no",
    true: "yes",
    false: "no",
    search: "search",
    search3Temper: "search (min 3 temper)",
    resarch: "research",
    favoris: "favorites",
    noSpaceFound: "no space found",
    researchTooltip: "start a research",
    home: "home",
    homeDashboard: "home",
    profile: "profile",
    error: "error",
    Error: "error",
    french: "french",
    english: "english",
    lastName: "last name",
    firstName: "first name",
    signUp: "registration",
    invite: "invite a collaborator",
    edit: "edit",
    send: "to send",
    sendDocument: "add document",
    choiceTenants: "choose a acount",
    tenantsList: "tenantsList",
    validate: "validate",
    management: "management",
    managementMyAlert: "manage my reminders",
    NumberOfContracts: "number of contracts",
    NumberOfContractsToValidate: "number of contracts to validate",
    loading: "loading",
    nextDeadlines: "next deadlines",
    nextDeadlines5: "5 next deadlines",
    nextDeadline: "next deadline",
    nextDeadlines_noData: "no deadline deducted",
    nextCallBack: "next call back",
    statusOfContracts: "status of contracts",
    allUnknownContract: "all contracts with an unknown nature",
    wContractNav: "contract",
    wContractNavTitle: "contract | contracts",
    wContractDetail: "contract",
    downloadFile: "download the file",
    download: "download",
    welcome: "welcome",
    signInText: "never miss a deadline",
    returnListContracts: "return to the list of contracts",
    returnContracts: "returns to retail contracts",
    moveADocument: "move a document",
    moveDocument: "move document",
    MyAlerts: "my reminders",
    wMyAlerts: "my reminders",
    year: "years",
    month: "month",
    week: "Week",
    day: "day | days",
    days4: "4 days",
    today: "today",
    dayss: "days",
    ongoingTreatment: "ongoing treatment",
    processingError: "processing error",
    numberOfDocuments: "number of documents | number of documents ",
    viewFile: "view the file",
    thereIs: "there is",
    and: "and",
    notDefined: "not defined",
    CGVStart: "i agree with the",
    CGVLink: "general conditions of use",
    agreeCGV: "i accept the",
    CGVStart: "i agree with the",
    CGVLink: "general conditions of use",
    legalNotice: "legal notice",
    legalNotice: "legal Notice",
    contact: "contact",
    sendInvitation: "invitation sent",
    sendMessage: "message sent",
    success: "success",
    title: "title",
    message: "message",
    messageSuccess: "message sent",
    messageSentText: "you're message as been sent",
    language: "language",
    contractName: "contract name",
    contractsPerPage: "contracts per page",
    backHome: "back to home",
    mentions: "mentions",
    mentions_noData: "no mention deducted",
    deadlines: "deadlines",
    characteristics: "characteristics",
    in: "in",
    otherCharacteristic: "other characteristic",
    document: "documents",
    documentss: "documents",
    processing: "processing",
    status: "status",
    from: "from",
    to: "to",
    every: "every",
    fromThe: "from the",
    the: "the",
    uploadContract: "upload your contracts here",
    support: "support",
    faq: "faq",
    _unknown: "undetermined",
    draft: "for review",
    DRAFT: "for review",
    live: "Under surveillance",
    LIVE: "Under surveillance",
    outOfScope: "out of scope",
    out_Of_Scope: "not followed",
    out_of_scope: "not followed",
    OUT_OF_SCOPE: "not followed",
    expired: "expired",
    Expired: "expired",
    EXPIRED: "expired",
    ARCHIVED: "archived",
    DONE: "done",
    IN_PROGRESS: "in progress",
    DONE_WITH_ERROR: "error",
    contractAttribut: "contract attributes",
    rate: "confidence",
    rateLevel: "level of confidence about detection",
    MANUAL_FREE: "manual entry",
    MANUAL_MENTION: "manually selected",
    AUTOMATIC: "automatic",
    DEDUCTION: "automatic deduction",
    deduction: "deduction",
    manual: "manual",
    deleteValue: "set default",
    deleteDoc: "delete document ",
    years: "years",
    text: "text",
    documents: "document | documents",
    addAddendum: "add an addendum",
    addAppendix: "add an appendix",
    addContract: "add a contract",
    addContractTooltip: "allow to add a contract in Oblige",
    duplicateDocument: "duplicate contracts",
    contactSupport: "Contact the support",
    authorization: "authorization",
    authorizations: "authorizations",
    alertManagement: "reminder management",
    contractSignature: "signature date",
    signDate: "sign on",
    law: "law",
    username: "username",
    noSubscribeAlert: "WARNING ! No reminder has been scheduled",
    errorDublicateFile:
      "cannot add this file, it already exist in this contract.",
    fillField: "thanks to fill at least 1 field ",
    openNewTab: "open in a new tab",
    closeTicket: "do you want to close this reminder ?",
    tooltip_closeTicket: "close this reminder?",
    deadlinePath: "reminder type",
    deadlineDate: "reminder date",
    ticketCreation: "creation date",
    lastChase: "date of last notification",
    firstChase: "date of first notification",
    noAlert: "no reminder",
    resultSearch: "result of the research",
    noResult: "no result",
    contractsInError: "contract in error",
    contractsInProgress: "ongoing treatment",
    reachThreshold: "live contract quota reached",
    deadlineCommingSoon: "due date",
    activityCode: "activity code",
    activityLabel: "activity label",
    category: "category",
    creationDate: "creation date",
    headquarterCode: "headquarter code",
    headquarterLocation: "Registered Address",
    identifier: "identify",
    lastAdministrativeDate: "last administrative date",
    legalType: "legal type",
    registeredCountry: "registered country",
    size: "size",
    vatin: "vatin",
    references: "references",
    contractUnknownLanguage: "contract unknown language",
    notify10DaysBeforeEvent: "notify 10 calendar days before the event",
    toComplete: "to complete",
    confirmSupp: "confirm deletion",
    tenantSupp: "tenant delete",
    supp: "delete",
    createTenant: "create an workspace",
    manageAccounts: "gérer mes comptes et mes classeurs",
    createBu: "create a workbook",
    buLabel: "workbook",
    tenantLabel: "account",
    tenantCreated: "acount created",
    buCreated: "workbook created",
    hours: "hours",
    deleted: "deleted",
    archived: "archived",
    removeValueDetect: "switch to not applicable",
    number: "numbers",
    money: "amount",
    contractChangeCharacteristic: "change in characteristic",
    actiondraft: "switch to Working Version",
    actionlive: "put under surveillance",
    actionout_of_scope: "exclude from monitoring",
    actiondeleted: "delete the contract",
    actionarchived: "archive the contract",
    alertMe: "remind me",
    alertMe2: "remind me",
    beforeDeadline: "before the deadline",
    before: "before",
    notApplicable: "not applicable",
    address: "address",
    blocpopup: "please deactivate your PopUp blocker to view the document",
    seeOnMaps: "seeOnMaps",
    actionclean: "remove manual values",
    actionreprocess: "restart a new detection",
    youDoNotAccount: "you do not have an account ?",
    registerForFree: "register for free",
    administrator: "administrator",
    manager: "manager",
    consultant: "consultant",
    guess: "guest",
    mapExport: {
      title: "map export",
      tooltip: "export your contract library in different formats",
      files: {
        label_overview: "list of contracts",
        label_cartography: "contract organization",
        label_taxonomy: "contract taxonomy",
        fileExcel: "Excel file"
      }
    },
    noActionPossible: "no action possible",
    myContracts: "my contracts",
    contractList: "contracts list",
    contractDetail: "contract detail",
    seeContractDetail: "see contract detail",
    myFilters: "filters",
    myNatures: "categories",
    filteredView: "filtered view",
    value: "value",
    confidenceRate: "% of confidence",
    changeCharacteristic: "change characteristic",
    person: "personn",
    organization: "Organization",
    service: "service",
    myProfil: "my profil",
    editProfil: "edit my profil",
    reason: "reason",
    noData: "no data",
    durationResolutionTicket: "average time to resolve a ticket",
    name: "name",
    email: "email",
    profil: "profile",
    state: "state",
    lastActivity: "last activity",
    action: "action",
    reloadInvite: "send a new invitation",
    removeUser: "remove user",
    eventCalendar: "calendar event",
    alertBy: "remind by",
    billingInformation: "your billing information",
    companyDetails: "company details",
    billingAddress: "billing address",
    invalidIban: "invalid IBAN or BIC",
    promoCodeInvalid: "invalid discount code",
    offerPack: "offre spécial",
    offerPackCode: "code ",
    bankDetails: "bank details",
    companyName: "company name",
    contactName: "contact name",
    country: "country",
    address: "address",
    city: "city",
    postCode: "postal code",
    iban: "iban",
    bic: "bic",
    noBillingInformation: "you have not yet saved billing information",
    complementAddress: "additional address",
    calendar: "deadline calendar",
    passwordPolicyMinLen:
      "your password must contains at least {size} characters",
    passwordPolicyMaxLen:
      "your password must contains at least {size} characters",
    passwordPolicyPattern:
      "your password does not satisfy the current policy requirements",
    passwordPolicyMaxLen: "your password should be less than {size} characters",
    passwordPolicyMinUpper:
      "your passwords must contains at least {size} uppercase letters",
    passwordPolicyMinDigit:
      "your passwords must contains at least {size} numbers",
    passwordPolicyMinSpecial:
      "your passwords must contains at least {size} special characters",
    changeCharacteristic_indication:
      "select a value among the propositions highlighted in the text",
    changeCharacteristic_btnSaisie: "enter manually",
    natureUnknown: "unknown contract nature",
    identification: "SIRET",
    analyst: "analyst",
    userNeverLogIn: "user has never logged in",
    userHasLoggedIn: "user has logged in",
    rememberContract: "rappelle",
    accessBillingModule: "accéder au module facturation", //TODO trad
    facturation: "mes données de facturation",
    myInfosContact: "mes informations de contact",
    filterByStatus: "filter by status",
    filterByTags: "filter by tags",
    infoAboutTags:
      "apparently, you haven't set up Tags to facilitate your ranking and searches. Take a few minutes to make your life easier and create the most relevant Tags possible !",
    addReminderAndNotified: "ajouter un rappel et être prévenu",
    contractWaitingDocumentDetail: "contrat sans document",

    //reason
    seeOriginalContract: "see original contract",
    contractMissingCharacteristic: "to complete",
    contractPendingReview: "to validate",
    contractReachTreshold: "treshold reached",
    contractReachTresholdLong:
      "Votre quota de contrats 'Sous surveillance' est atteint. ",
    missingIBAN: "merci de remplir un IBAN",
    invalidIBAN: "IBAN renseigné est invalide",
    missingGCC: "veuillez approuver les conditions tarifaires",
    contractUnknownNature: "unknown contract nature",
    contractUnknownLanguage: "unknown language",
    duplicateDocument: "to check (suspected duplicate)",
    internalServerError: "internal error",
    contractAutoExpired: "auto expired",
    contractTreatmentPending: "waiting for treatment",
    contractLiveDetail:
      "your contract is under surveillance. Add reminders to be notified of an upcoming deadline",
    contractExpired: "your contract has expired.",

    //OverviewReason
    contractMissingCharacteristicOverview: "contracts that I have to complete",
    contractPendingReviewOverview: "contracts that I have to validate",
    ContractReachTresholdOverview:
      "contracts which are waiting because the treshold is reached",
    contractUnknownLanguageOverview:
      "contracts which language is currently not supported",
    contractUnknownNatureOverview:
      "contracts which nature could not be recognized",
    duplicateDocumentOverview:
      "contracts which have a suspicion of duplication",
    internalServerErrorOverview:
      "contracts which are not correctly be treated by the system (contact support)",
    contractAutoExpiredOverview: "contracts which are expired",
    _otherOverview: "other",
    contractTreatmentPendingOverview:
      "contracts which are waiting for treatment",
    contractRenewalPendingOverview:
      "this contract seems to be expired without tacit renewal",
    contractWaitingDocument: "Contrats sans document",

    //DetailReason
    // contractPendingAnalysisDetail: "contract pending analysis detail",
    contractPendingAnalysisAnalyste: "Please fill in the missing information",
    contractRenewalPendingDetail:
      "this contract seems to be expired without tacit renewal, would you like to renew it ?",
    contractMissingCharacteristicDetail:
      "I need to complet the contract, some mandatory characteristics are missing",
    contractMissingCharacteristicAnalyste:
      "some mandatory characteristics are missing",
    contractPendingReviewDetail:
      "the mandatory characteristics of the contract are fully completed, I need to validate the contract",
    contractReachTresholdDetail:
      "update your flat fee, maximal treshold reached",
    contractUnknownLanguageDetail:
      "Oblige don't support this language for the moment",
    contractUnknownNatureDetail:
      "Oblige was unable to recognize this contract nature, fill it manually",
    duplicateDocumentDetail:
      "this contract is already in your Oblige space, verify this, then delete it or force it by giving it a status",
    internalServerErrorDetail:
      "Oblige was unable to treat correctly this contract, send this contract to the support",
    contractAutoExpiredDetail: "auto expired",
    natureUnknown: "unknown nature",
    contractTreatmentPending:
      "this contract is waiting for treatment, it will be done as soon as possible",

    // widgets MyContract
    widgets_noData: {
      reasonTable:
        "consult the number of actions to be taken on your contracts in stock!",
      deadlines: "no deadlines to display",
      contractByStatus:
        "Check the status of your contracts and take the necessary actions to finalize their registration!",
      contractNotLive:
        "Take the necessary actions to finalize the registration of your contracts!",
      graphContracts:
        "View your contracts sorted by type (commercial lease, employment contract, subscription, etc.)",
      graphContractsReason:
        "Visualize the quantity of actions to be taken on your contracts in the blink of an eye!",
      validateAlert: "Check your current reminders!",
      ticketsByAge: "Analyze your reminders that are not yet validated!",
      closedTicketsByValidator:
        "Consult who validates the recalls in your team!",
      ticketsVariation:
        "Analyze the evolution of the quantity of reminders you receive!",
      ticketResolutions: "Analyze the validation time of your reminders!",
      ticketsByValidator:
        "Check the number of reminders created by your team members",
      eventsByMonth:
        "Anticipate upcoming deadlines by observing the calendar. The darker the boxes, the more deadlines",
      treeMapClassification: "Quickly view your contract data room"
    },
    calendar_explication:
      "Visualisez vos prochaines échéances sur ce calendrier !", // a traduire.

    reasonGraph: "my contracts in the blink of an eye",
    reasonGraphDescription:
      "this graph gives you a quick visual on your contract quantity depending on the actions to be carried out on it.",
    reasonTable: "actions to be taken on my contracts",
    reasonTableDescription: "number of contracts having a joint action",
    chartTypeContracts: "natures of your contracts in stock",
    chartTypeContractsDescription:
      "graph representing the shares of each type of contract on your total contracts.",
    uploadContract: "download your contract here",
    uploadContractDescription:
      "allows you to add your contracts on Oblige, you can file your contracts either by dragging them onto the icon, or by clicking on it and selecting them, or by sending an email from your address used on oblige to the address:",
    uploadDropbox_btn: "use Dropbox to feed Oblige",
    uploadDropbox_modal_title: "use a Dropbox link",
    uploadDropbox_placeholder: "paste your link here",
    uploadDropbox_linkMatch: "the link must contain",
    dropboxText:
      "load your contracts here by drag-and-drop or choosing them in Dropbox.",
    dropboxDetail1: "compressed files (zip) are also accepted.",
    dropboxDetail2:
      "you also can send an email or transferred one directly at this address.",
    listOfContractsFollowed: "contract which need an action",
    listOfContractsFollowedDescription:
      "list of contracts showing the associated actions to be carried out for each",
    TypeNbContract: "status of your contracts in stock",
    TypeNbContractDescription:
      "list of contracts by type (eg: lease, employment contract, etc.) sort according to their status.",
    MyContractByStatusChevron: "status of your contracts in stock",
    MyContractByStatusChevronDescription: "list of contracts by status",
    myContractsByStatusChevron: {
      title: "my contracts by status"
    },
    tutorialAddContract: "help adding contract",
    wTutorialAddContract: "help adding contract",
    wTutorialAddContractDescription:
      "this widget aims to present a carousel of images to guide you from adding a contract to creating your reminders",

    // widgets MyAlerts
    WCalendar: "calendar",
    WCalendarDescription:
      "a calendar allowing to see in the long term the important dates to come.",
    WNextDeadlines: "Next 5 deadlines",
    WNextDeadlinesDescription:
      "a list of the 5 deadlines having the date closest to today's date.",
    WValidateAlert: "reminders",
    WValidateAlertDescription:
      "list of reminders currently in progress with the option to close them if they are no longer relevant.",
    ticketsByAge: "number of unsolved reminders by age",
    ticketsByAgeDescription:
      "a graph representing the number of reminders not validated according to defined seniority periods",
    closedTicketsByValidator: "who validates the reminders in my team",
    closedTicketsByValidatorDescription:
      "a graph representing which validates how many alreminderserts among the users having access to the account",
    ticketsVariation:
      "evolution per month of the number of identified reminders",
    ticketsVariationDescription:
      "line graph representing the change in the number of reminders issued compared to the number of reminders resolved",
    ticketResolutions: "average time to resolve a ticket",
    ticketResolutionsDescription:
      "average time between opening and closing a ticket on a monthly basis.",
    ticketsByValidator: "who validates the reminders in my team",
    ticketsByValidatorDescription:
      "number of reminders for each individual user on the common account",
    eventsByMonth: "deadlines evolution",
    eventsByMonthDescription:
      "calendar by type of reminders colored according to the number of reminders by type per month",
    alertManagementDescription:
      "list of reminders currently in progress with the option to close them if they are no longer relevant.",
    contractListTree: "my contract library",
    contractListTreeDescription: "list of contracts sort by contract family",

    changeStatus: "change status",
    signinSubtitle: "get your contracts simply managed !",
    numberAlertByAge: "number of unresolved reminders by age",
    personnWhoValidate: "Who validates the reminders in my team ?",
    historyValidateAlert: "List of validated reminders (history)",
    numberAlertMonth:
      "evolution per month of the number of identified reminders",
    contractsInDraft: "contract in draft",
    editWidgets: "edit widgets",
    save: "save",
    addWidgets: "add widgets",
    addWidgetsOk: "the widget has been added",
    moveWidgets: "move the widget",
    deleteWidgets: "remove Widget",
    mobilePhone: "mobile phone",
    noMobilePhone: "you did not enter a phone number",
    slackChannel: "slack channel",
    slackId: "slack token",
    personnWhoTreat: "Who have to treat the reminders ?",
    natureOfAlert: "nature of reminder",
    alertMeBefore: "reminder me before",
    myOblige: "my Oblige",
    goToMyOblige: "go to My Oblige",
    myObligeContractsLive: "contracts under surveillance",
    signInSubtitle: "sign in to your account",
    through: "through",
    notifications: "notifications",
    noNotifications: "no notifications",
    noAlertSubscribe: "you have not subscribed to any type of reminders",
    noAlertSubscribeDesc:
      "unfortunately you don't have any reminder configured in Oblige. Don't be surprised by a deadline and take a few minutes to set up your automatic reminders!",
    goToMyRappel: "go to my reminders",
    selectTenantPlaceholder: "enter 3 characters to start the search",

    displayName: "display name",
    yourInformation: "my informations",
    communicationMethod: "how to join me ?",
    dateEvent: "event date",
    actionCarriedOutBy: "action carried out by",
    contractDetection: "launch of a detection",
    contractStore: "addition of contract",
    documentAdd: "addition of amendment or appendix",
    contractTreatmentInProgress: "being processed",
    null: "undetermined",
    nature: "nature",
    naturecontractDetection: "detection",
    naturecontractStore: "contract",
    naturedocumentAdd: "document",
    naturecontractWorkflow: "workflow",
    naturecontractManualDirectValue: "characteristics",
    naturecontractManualRemoveValue: "characteristics",
    naturecontractManualNoValue: "characteristics",
    naturecontractManualMentionValue: "characteristics",
    naturecontractManualForceSkeleton: "contract",
    contractNatureUnknow: "contract nature cannot be determined",
    undefined: "undefined",
    other: "other",
    _other: "other",
    version: "version",
    reasonGraph: "stock of your contracts which need an action",
    reasonTable: "contracts which need an action by reasons",
    validate: "validate",
    naturecontractTicket: "creation of the nature ticket",
    contractTicket: "contract ticket",
    contractTicketRegistration: "ticket register",
    comment: "comments",
    addComment: "add a comment",
    suppComment: "delete comment",
    confirmComment: "warning, you are about to delete the comment",
    contractAutoExpired: "auto expired",
    confirmSuppWidget: "warning, you are about to delete the whidget",
    inNameContract: "search in the name of the contract",
    myContratheque: "my contract library",
    naturecontractManualRemoveSkeleton: "contract nature modification",
    contractManualRemoveSkeleton: "manual modification of the contract nature",
    naturecontractShare: "contract sharing",
    contractShare: "a contract share has been made",
    noContractsYet: "no contracts yet",
    allWidgetsUsed: "all widgets are already used",
    myDataRoom: "my data room",
    ANALYSE: "analysis",
    AnalyseInProgress: "analysis in progres",
    AnalyseInProgressText:
      "your contract is being analyzed. You will receive an email to notify you as soon as it is available for your journal !",
    renewal_pending: "renewal",
    edit: "edit",
    ifValueIncorrect: "if the value {value} is incorrect: ",
    FRAMEWORK_AGREEMENT: "cadre",
    APPLICATION_AGREEMENT: "enfant cadre",
    longFRAMEWORK_AGREEMENT: "contrat cadre",
    longAPPLICATION_AGREEMENT: "child contract of a framework contract",
    createTag: "Click on enter to create",
    tagManagement: "tag management",
    allMyContracts: "all my contracts",
    originalQuery: "original search",
    contractPendingAnalysisOverview: "contract awaiting analysis",
    viewContract: "look at the contract",
    notAccessTenant: "you do not have enough in this account / workbook",
    statusFilter: "filter by status",

    //MoveContract
    documentMultipleContractsDetail:
      "the document contain many contracts, document separation is necessary ",
    documentLanguageNotManagedDetail:
      "the document / contract is in an language that the system not supported",
    documentNatureNotManagedDetail:
      "the document is not a contract (other unrelated document)",
    documentNotContractDetail:
      "the document is an addendum, it should be attached to an existing contract in Oblige",

    //faq
    theme: "theme",
    general: "general",
    recall: "recall",
    widget: "widget",
    profile: "profile",
    contract: "contract",
    billing: "billing",
    faq: "faq",

    //audit
    auditContract: "audit contract",
    audit: "audit",
    consultAudits: "consult the audits",
    contractWorkflow: "contract move from {0} to {1}",
    contractManualDirectValue: "characteristics {0} change manually ",
    contractManualRemoveValue: "features {0} delete manually",
    contractManualMentionValue: "characteristics {0} change manually",
    contractManualNoValue: "characteristics {0} pass to not applicable",
    contractManualForceSkeleton: "contract template go manually to {0}",

    // contrat bail
    dateSignatureBail: "signing date",
    natureBail: "nature of the lease",
    dureeBail: "lease term",
    preavisBail: "notice period",
    dateEffetBail: "lease effective date",
    preneur: "taker",
    dateExpirationBail: "lease end date",
    bailleur: "lessor",
    periodeTermeBail: "lease term frequency",
    expiryBail: "term of lease",
    Bail: "lease",
    birthdayBail: "birthday Bail",
    priorNoticeBail: "prior notice bail",
    contratBail: "leases",
    priseEffetBail: "taking effect lease",
    dureeContratBail: "contract duration",
    represantantBailleur: "lessor representative",
    representantPreneur: "lessee representative",
    preavisResiliationBail: "notice of termination",
    periodeBail: "lease period",
    adresseBienBail: "property address",
    surfaceBienBail: "property surface",
    droitApplicableBail: "applicable law",
    droitFr: "french law",
    juridictionBail: "jurisdiction lease",
    montantLoyerBienBail: "rent",
    revisionLoyerBienBail: "revision of property rent",
    travauxChargesBienBail: "work charges",
    depotGarantieBienBail: "deposit guaranteed",
    representantBailleur: "representant bailleur",
    bailProfessionnel: "professional lease",

    // contrat de travail
    contratTravail: "working contract",
    birthdayTravail: "working birthday",
    finPeriodRenouvellementTravail: "end of renewal period",
    dureeTravail: "working duration",
    lieuTravail: "working place",
    coefficientTravail: "coefficient",
    finPeriodeEssaiTravail: "end of probationary period",
    statutEmployeTravail: "employee status",
    indemnitePrecariteTravail: "precarousness allowance",
    indemniteCongesPayesTravail: "compensation paid vacation",
    remunerationVariableTravail: "variable income",
    employeurTravail: "employeur",
    remunerationMensuelleTravail: "monthly remuneration",
    remunerationAnnuelleTravail: "annual remuneration",
    dureeCongesPayesTravail: "duration of paid leave",
    datePrisePosteTravail: "date taken at the post",
    typeContratTravail: "type of employement contract",
    dateSignatureTravail: "signature date",
    dureeRenouvellementEssaiTravail: "duration of test renewal",
    dureePeriodeEssaiTravail: "duration of the trial period",
    dateExpirationTravail: "expiry date",
    priseEffetTravail: "effective",
    salarieTravail: "employee",
    fonctionSalarie: "salaried function",
    intitulePosteTravail: "job title",

    dday: "d",
    showDeadlineHistory: "hidden the past deadlines",

    //prestation de service
    prestationService: "service provision",

    //bail emphytéotique
    emphyteuticLease: "emphyteutic lease",

    welcomeSite: "welcome to",
    // move contract
    moveTheContract: "déplacer ce contrat",
    tooltipsMoveTheContract:
      "déplacer ce contrat dans un autre compte / classeur",
    choiceTenantBu:
      "choisissez le compte où le classeur ou le contrat sera déplacer",
    contractStatusUpdated: "Contract status updated",

    // add reminber and notified
    typeEvent:
      "choisissez la nature de contrat et le type d'évènement sur lequel vous souhaitez positionner un rappel",
    typeEvent1: "toutes les échéances",
    typeEvent2: "terme du contrat",
    typeEvent3: "date limite de fin de projet / livraison",
    typeEvent4: "date de fin de période d'essai",
    typeEvent5: "date anniversaire de signature de contrat",
    typeEvent6: "date limite de demande de versement / paiement",
    typeEvent7: "préavis de résiliation en cas de renouvellement",
    typeEvent8: "date anniversaire de prise d'effet de contrat",
    reminMeBefore: "me rappeler avant (jours)",
    reviveMe: "me relancer tous les (jours)",
    sendInvitation: "m'envoyer une invitation",
    notifyByMail: "m'avertir par mail",
    notifyBySms:
      "m'avertir par SMS (un numéro de mobile doit être renseigner dans votre profil)"
  }
};
