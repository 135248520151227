import Vue from "vue";
import App from "./App.vue";
import axios from "axios";

// VUEX - https://vuex.vuejs.org/
import store from "./store";

// VUE-ROUTER - https://router.vuejs.org/
import router from "./router";

// PLUGINS
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/vue-i18n";
import interceptor from "./class/interceptor";
import "./plugins/vue-google-maps";
import "./plugins/vue-shortkey";
import "./plugins/vue-head";
import "./plugins/vue-gtag";
import "./plugins/apexcharts";
import "./plugins/echarts";
import "./plugins/animate";
import "./plugins/clipboard";
import "./plugins/moment";
import VueCookies from "vue-cookies";

// FILTERS
import "./filters/capitalize";
import "./filters/lowercase";
import "./filters/uppercase";
import "./filters/formatCurrency";
import "./filters/formatDate";
import "./filters/round";

// STYLES
// Main Theme SCSS
import "./assets/scss/theme.scss";
import "./global.css";

// Animation library - https://animate.style/
import "animate.css/animate.min.css";

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

// mettre et garder le token actif dans le header
const { userAuth } = store.getters;

interceptor();

if (userAuth.token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + userAuth.token;
}

axios.defaults.headers.common["x-app"] = process.env.VUE_APP_APPLICATION;

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
Vue.use(VueCookies);
Vue.use({ vuetify });

Vue.directive("blur", {
  inserted: function(el) {
    el.onfocus = ev => ev.target.blur();
  }
});

export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
