<template>
  <div class="d-flex align-center">
    <v-btn
      v-for="(itemMenu, index) of itemsMenu"
      :key="index"
      class="ml-1 no-active"
      :class="white_Text(itemMenu)"
      :to="itemMenu.link"
      @click="delete_query_origin()"
      :outlined="is_actived(itemMenu)"
      :text="!is_actived(itemMenu)"
      :color="active_color(itemMenu)"
    >
      <span class="white--text">{{ $t(itemMenu.name) | capitalize }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsMenu: [
        {
          name: "oblige.myOblige",
          link: { name: "dashboard-my-oblige" },
          complement: []
        },
        {
          name: "oblige.myContracts",
          link: { name: "my-contract-list", query: { all: "true" } },
          complement: [
            { name: "contract-detail" },
            { name: "change-characteristic" },
            { name: "audit-contract" }
          ]
        },
        {
          name: "oblige.wMyAlerts",
          link: { name: "user-myAlerts" },
          complement: []
        }
      ]
    };
  },
  methods: {
    // couleur du btn ACTIVE
    active_color(item) {
      if (this.is_actived(item)) {
        return "#FFF500";
      }
    },

    // couleur text non active
    white_Text(item) {
      if (!this.is_actived(item)) {
        return "white--text";
      }
    },

    delete_query_origin() {
      this.$store.commit("update_sauveSelectCategorieListContract", null); // init select categorie
      this.$store.commit("update_queryContractOrigin", null);
      this.$store.commit("update_statusStore", null);
      this.$store.commit("update_tagsStore", null);
      this.$store.commit("update_pageTableContract", null);
      this.$store.commit("update_renderComponentUserTips", false);
    },

    // lien actif
    is_actived(item) {
      if (this.$route.name == item.link.name) {
        return true;
      } else {
        for (const complement of item.complement) {
          if (complement.name == this.$route.name) {
            return true;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
