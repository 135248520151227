<template>
  <v-dialog v-model="openDialog" max-width="800">
    <v-card>
      <v-card-title class="title primary--text">
        Suppression du classeur
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" text dense class="body-2 ma-0">
          En cliquant sur valider vous confirmez supprimer le classeur.
          <strong>{{ service.label }}</strong>
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn
          color="primary"
          text
          min-width="100"
          @click="cancel_dialog"
          :disabled="loadForm"
          >Annuler</v-btn
        >
        <v-btn
          color="primary"
          min-width="100"
          @click="delete_service"
          :loading="loadForm"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tenant from "@/class/tenant.js";
import { mapGetters } from "vuex";

export default {
  name: "DialogModel",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    service: {
      type: Object,
      require: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey"])
  },
  data() {
    return {
      ClassTenant: null,
      loadForm: false
    };
  },

  methods: {
    async delete_service() {
      try {
        this.loadForm = true;
        this.ClassTenant = new Tenant();
        await this.ClassTenant.disable_entity(this.apiKey, this.service);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.$emit("submited");
        this.cancel_dialog();
      }
    },

    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
