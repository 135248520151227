<template>
  <v-navigation-drawer
    permanent
    v-model="drawer"
    :mini-variant.sync="mini"
    color="white"
    height="calc(100vh - 64px)"
    width="300"
  >
    <v-list>
      <v-list-item link @click="mini = !mini">
        <v-list-item-icon>
          <v-icon v-if="mini">mdi-chevron-right</v-icon>
          <v-icon v-if="!mini">mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span v-if="labelTenant">
            {{ labelTenant }}
          </span>
          <span v-else>
            {{ labelCurrent }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        link
        v-for="link of listLinks"
        :key="link.key"
        @click="select_link(link)"
      >
        <v-list-item-icon>
          <v-icon
            :class="linkSelected.key == link.key ? 'primary--text' : ''"
            >{{ link.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-title
          :class="linkSelected.key == link.key ? 'primary--text' : ''"
          >{{ link.label }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "DialogMenuListGrantAuth",
  computed: {
    ...mapState(["currentBu"]),
    ...mapGetters(["labelCurrent", "labelTenant"])
  },
  data() {
    return {
      drawer: true,
      mini: false,
      linkSelected: null,
      listLinks: [
        {
          label: "Gérer les accès",
          icon: "mdi-account-key",
          key: "authorizations"
        },
        {
          label: "Gérer les droits",
          icon: "mdi-chevron-triple-up",
          key: "grant"
        }
      ]
    };
  },

  created() {
    this.select_link(this.listLinks[0]);
  },
  methods: {
    select_link(link) {
      this.linkSelected = link;
      this.$emit("link", link);
    }
  }
};
</script>
