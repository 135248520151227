import axios from "axios";

let urlApiHorusAuth = process.env.VUE_APP_URL_HORUS + "auth/";
// let application = process.env.VUE_APP_APPLICATION; OLD

export default class tenant {
  // add headers: {"x-tenant": apiKey,} car api si @Secured en back (BU apiKey)
  async tenantUsers(apiKey) {
    let request = await axios.get(urlApiHorusAuth + "tenant/users", {
      headers: {
        "x-tenant": apiKey
      }
    });
    if (request == undefined) {
      return undefined;
    } else return request.data;
  }

  async generateLinkSupport(apiKey) {
    let request = await axios.get(urlApiHorusAuth + "tenant/support/build", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async supportTenantInfo(supportPath) {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("token", supportPath);

    let request = await axios.post(
      urlApiHorusAuth + "tenant/support/info",
      data
    );
    return request.data;
  }

  async code_support(code) {
    const request = await axios.get(
      urlApiHorusAuth + "security/tenant/support?search=" + code
    );

    return request.data;
  }

  async authorization_users_list(apiKey, size, page, data) {
    const request = await axios.post(
            urlApiHorusAuth + "tenant/granted?page=" + page +"&size=" + size,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async authorization_overview(apiKey) {
    const request = await axios.post(
      urlApiHorusAuth + "/tenant/overview",
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async create_tenant(name, data) {
    const request = await axios.post(
      urlApiHorusAuth + "/tenant/createTenant?name=" + name,
      data
    );
    return request.data;
  }

  async create_service(apiKey, name, data) {
    const request = await axios.post(
      urlApiHorusAuth + "/tenant/createBusinessUnit?name=" + name,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async entity_rename(apiKey, data) {
    const request = await axios.post(urlApiHorusAuth + "tenant/update", data, {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async disable_entity(apiKey, data) {
    const request = await axios.post(urlApiHorusAuth + "tenant/disable", data, {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async ungrant(apiKey, userPath, data) {
    const request = await axios.post(
      urlApiHorusAuth + "tenant/ungrant?userPath=" + userPath,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async grant(apiKey, userPath, status, data) {
    const request = await axios.post(
      urlApiHorusAuth +
        "tenant/grant?userPath=" +
        userPath +
        "&profilPath=" +
        status,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async invite(apiKey, status, data) {
    const request = await axios.post(
      urlApiHorusAuth + "tenant/guess?profilPath=" + status,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async resent_user_invitation(apiKey, userPath) {
    const request = await axios.post(
      urlApiHorusAuth + "user/guess/again?userPath=" + userPath,
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async edit_grant(apiKey, userPath, status, data) {
    const request = await axios.post(
      urlApiHorusAuth +
        "tenant/grant/update?userPath=" +
        userPath +
        "&profilPath=" +
        status,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async get_list_tenant(apiKey) {
    const request = await axios.post(
      urlApiHorusAuth + "tenant/list",
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async user_status(apiKey) {
    const request = await axios.get(urlApiHorusAuth + "tenant/profils", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async display_grant_overview(apiKey, profilPath) {
    const request = await axios.get(
      urlApiHorusAuth + "profil/overview?profilPath=" + profilPath,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async create_grant(apiKey, data) {
    const request = await axios.post(urlApiHorusAuth + "profil/update", data, {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async edit_grant_level(apiKey, profilPath, data) {
    const request = await axios.post(
      urlApiHorusAuth + "profil/module?profilPath=" + profilPath,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
}
