<template>
  <div class="d-flex flex-grow-1 backgroundPages">
    <!-- backgroundImgDoodle -->
    <!-- websocket -->
    <Websocket />

    <!-- Toolbar -->
    <v-app-bar app class="primary">
      <!-- search input mobile -->
      <v-text-field
        v-if="showSearch"
        append-icon="mdi-close"
        placeholder="Chercher"
        prepend-inner-icon="mdi-magnify"
        hide-details
        dark
        solo
        flat
        autofocus
        @click:append="showSearch = false"
      >
      </v-text-field>

      <!-- Select tenants/Bu -->
      <div v-else class="d-flex flex-grow-1 align-center">
        <SelectTenantsBtn />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              style="width: 120px; cursor: pointer"
              class="ml-1"
              @click="to_dashboard_my_oblige()"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                src="@/assets/images/logo-white.svg"
                class="mr-1"
                height="45px"
                contain
              ></v-img>
            </div>
          </template>
          <span> {{ labelCurrent }}</span>
        </v-tooltip>

        <SelectTenantsBtnLabel :labelCurrent="labelBtn" />

        <MenuNav />

        <v-spacer></v-spacer>

        <!-- search input desktop -->
        <v-text-field
          ref="search"
          v-model="search"
          class="mx-1 hidden-xs-only ml-10"
          :label="labelSearch"
          prepend-inner-icon="mdi-magnify"
          hide-details
          outlined
          filled
          rounded
          dense
          dark
          @click:append="viewContractList(search)"
          @keyup.native.enter="viewContractList(search)"
          @focus="focus_search"
          @blur="blur_search"
          style="max-width: 500px"
          background-color="#007A60"
        >
        </v-text-field>

        <v-spacer class="d-block d-sm-none"></v-spacer>

        <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
          <UserTips />
        </div>
        <ToolbarUser v-if="renderComponent && currentBu" />
      </div>
    </v-app-bar>

    <v-main id="mainLayout">
      <v-container class="pa-0" fluid>
        <slot v-if="localeOk && renderSlot"></slot>
      </v-container>
      <scrollTop />
    </v-main>

    <ZendeskInit />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

// navigation menu configurations
import config from "../configs";
import scrollTop from "../tools/scrollTop";
import MainMenu from "../components/navigation/MainMenu";
import ToolbarUser from "../components/toolbar/ToolbarUser";
import UserTips from "../components/userTips/UserTips";
import Websocket from "../components/common/websocket";
import MenuNav from "../components/common/MenuNav";
import SupportInfo from "@/components/common/SupportInfo";
import SelectTenantsBtnLabel from "../components/common/SelectTenantsBtnLabel.vue";
import ZendeskInit from "../components/zendesk/ZendeskInit.vue";

export default {
  name: "DefaultLayout",

  components: {
    MainMenu,
    ToolbarUser,
    UserTips,
    SelectTenantsBtn: () => import("../components/common/SelectTenantsBtn"),
    SelectTenantsBtnLabel,
    scrollTop,
    Websocket,
    MenuNav,
    SupportInfo,
    ZendeskInit
  },

  computed: {
    ...mapGetters([
      "userAuth",
      "currentBu",
      "version",
      "labelCurrent",
      "labelTenant"
    ]),
    ...mapState("app", [
      "isContentBoxed",
      "toolbarTheme",
      "isToolbarDetached",
      "queryContractOrigin",
      "userConnected"
    ]),

    labelBtn() {
      return this.labelTenant == null ? this.labelCurrent : this.labelTenant;
    }
  },

  data() {
    return {
      showSearch: false,
      navigation: config.navigation,
      modalCGV: false,
      search: null,
      tenantsApiKey: "",
      localeOk: false,
      renderComponent: true,
      renderSlot: true,
      userIsConnected: false,
      labelSearch: this.$options.filters.capitalize(this.$t("oblige.search"))
    };
  },

  watch: {
    currentBu() {
      if (!this.changeBu) {
        this.tenantsApiKey = this.currentBu;
      }
      this.forceRerender();
    }
  },

  mounted() {
    this.local();
    this.tenantsApiKey = this.currentBu;
  },

  methods: {
    to_dashboard_my_oblige() {
      if (this.$route.name != "dashboard-my-oblige") {
        this.$router.push({ name: "dashboard-my-oblige" });
        this.$store.commit("update_sauveSelectCategorieListContract", null); // Init select categorie.
        this.$store.commit("update_queryContractOrigin", null);
        this.$store.commit("update_statusStore", null);
        this.$store.commit("update_tagsStore", null);
        this.$store.commit("update_pageTableContract", null);
      }
    },

    local() {
      this.localeOk = false;
      if (this.userAuth.locale == "fr_FR" || this.userAuth.locale == "fr") {
        this.$i18n.locale = "fr";
        this.$moment.locale("fr");
      } else {
        this.$i18n.locale = "en";
        this.$moment.locale("en");
      }
      this.localeOk = true;
    },

    viewContractList(search) {
      if (search != null && search != "" && search.length >= 3) {
        this.$store.commit(
          "update_queryContractOrigin",
          JSON.stringify({
            predicates: [
              {
                type: "match",
                value: search
              }
            ]
          })
        );
        if (this.$route.name != "my-contract-list") {
          this.$router.push({ name: "my-contract-list" });
        } else {
          this.$router
            .push({
              name: "my-contract-list",
              query: { search: search }
            })
            .catch(() => {});
        }
        this.search = null;
      }
    },

    focus_search() {
      this.labelSearch = this.$options.filters.capitalize(
        this.$t("oblige.search3Temper")
      );
    },

    blur_search() {
      this.labelSearch = this.$options.filters.capitalize(
        this.$t("oblige.search")
      );
    },

    forceRerenderSlot() {
      this.renderSlot = false;
      this.$nextTick(() => {
        this.renderSlot = true;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  }
};
</script>

<style scoped>
.backgroundImgDoodle {
  background-image: url("/images/illustrations/background-doodle-yellow.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>
