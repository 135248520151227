<template>
  <v-row dense>
    <v-col cols="12">
      <Tenant-infos v-if="renderComponent" @services-updated="forceRerender" />
    </v-col>
    <v-col cols="12">
      <User-list
        v-if="renderComponent && tenantOverview"
        :tenantOverview="tenantOverview"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";
import UserList from "./manageUsersAuthorizations/UserList.vue";
import TenantInfos from "./manageWorkspaces/TenantInfos.vue";

export default {
  name: "PageAuthorizations",
  components: { UserList, TenantInfos },
  computed: {
    ...mapGetters(["currentBu", "apiKey"])
  },
  data() {
    return {
      renderComponent: true,
      ClassTenant: null,
      tenantOverview: null
    };
  },
  watch: {
    currentBu() {
      if (
        this.currentBu !== null &&
        this.currentBu.authorizations["manageTenant@auth"] == "ACCESSIBLE"
      ) {
        this.forceRerender();
      } else if (this.currentBu !== null) {
        this.$router.push({ name: "myOsmose" });
      }
    }
  },

  created() {
    this.ClassTenant = new Tenant();
    this.get_list_services();
  },

  methods: {
    async get_list_services() {
      try {
        const response = await this.ClassTenant.authorization_overview(
          this.apiKey
        );
        this.tenantOverview = response;
      } catch (error) {
        console.error(error);
      }
    },

    // old
    async forceRerender() {
      await this.get_list_services();
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  }
};
</script>
