<template>
  <v-dialog v-model="openDialog" max-width="1000">
    <v-form
      v-model="validForm"
      ref="formNewUserInvitation"
      @submit.prevent="submit_form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title primary--text">
          Inviter un collaborateur
        </v-card-title>

        <v-card-subtitle>
          <v-alert text dense type="warning" class="body-2 mb-0 mt-1">
            Attention, donner l'accès au compte à un utilisateur lui donnera
            accès à tous les services associés.
          </v-alert>
        </v-card-subtitle>

        <v-card-text
          style="max-height: 500px; overflow-y: auto"
          class="thinscrollbar pt-2"
        >
          <v-row dense>
            <v-col cols="6">
              <v-row dense>
                <!-- Who ? -->
                <v-col cols="12">
                  <span class="subtitle-1 primary--text"
                    >À qui souhaitez-vous envoyer l'invitation ?</span
                  >
                </v-col>
                <!-- Acces Tenant/BU -->
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="form.email"
                    label="Adresse e-mail du collaborateur"
                    :rules="[
                      rules.required,
                      rules.maxLength(128),
                      rules.emailValid(form.email)
                    ]"
                    :disabled="loadForm"
                    dense
                    outlined
                    color="primary"
                  ></v-text-field>
                </v-col>

                <!-- Autorization -->
                <v-col cols="12">
                  <span class="subtitle-1 primary--text"
                    >Quel seront ses droits d'accès ?
                  </span>
                </v-col>
                <v-col cols="12" md="10">
                  <v-select
                    v-model="form.profilPath"
                    :items="grantList"
                    label="Rôle de l'utilisateur"
                    item-text="profilLabel"
                    item-value="profilPath"
                    :rules="[rules.required]"
                    :loading="loadGrantList"
                    :disabled="loadForm"
                    dense
                    outlined
                    color="primary"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="10">
                  <v-checkbox
                    v-model="form.asFavorite"
                    label="Ajouter dans la liste des favoris"
                    class="ma-0 pt-1"
                    inset
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <!--ListBU -->
            <v-col cols="6">
              <v-row dense>
                <v-col cols="12">
                  <span class="subtitle-1 primary--text"
                    >Sur quel compte/classeur aura-t-il accès ?</span
                  >
                  <br />
                  <span> (sélection obligatoire)</span>
                </v-col>
                <v-col
                  cols="12"
                  style="max-height: 300px; overflow-y: auto"
                  class="thinscrollbar"
                >
                  <v-row dense>
                    <!-- Tenant card btn -->
                    <v-col cols="12" sm="6">
                      <v-card
                        min-height="100"
                        height="100%"
                        :color="
                          tenantOverview.tenant.backColor != null
                            ? tenantOverview.tenant.backColor
                            : 'secondary'
                        "
                        @click="unitSelected = tenantOverview.tenant"
                        class="d-flex flex-column justify-space-between"
                        :disabled="loadForm"
                      >
                        <v-card-title class="pa-1 body-1">
                          <span
                            :style="'color:' + tenantOverview.tenant.textColor"
                          >
                            {{ tenantOverview.tenant.label }}
                          </span>
                        </v-card-title>
                        <v-card-actions>
                          <span
                            class="caption"
                            :style="'color:' + tenantOverview.tenant.textColor"
                            >Compte</span
                          >
                        </v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.6"
                          color="active"
                          :value="unitSelected == tenantOverview.tenant"
                        >
                          <v-icon x-large>mdi-check</v-icon>
                        </v-overlay>
                      </v-card>
                    </v-col>
                    <!-- BU cards btn -->
                    <v-col
                      cols="12"
                      sm="6"
                      v-for="unit of tenantOverview.units"
                      :key="unit.businessUnitKey"
                    >
                      <v-card
                        min-height="100"
                        height="100%"
                        :color="
                          unit.backColor != null ? unit.backColor : 'secondary'
                        "
                        @click="unitSelected = unit"
                        class="d-flex flex-column justify-space-between"
                        :disabled="loadForm"
                      >
                        <v-card-title class="pa-1 body-1">
                          <span :style="'color:' + unit.textColor">
                            {{ unit.label }}
                          </span>
                        </v-card-title>
                        <v-card-actions>
                          <span
                            class="caption"
                            :style="'color:' + unit.textColor"
                            >Classeur</span
                          >
                        </v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.6"
                          color="active"
                          :value="unitSelected == unit"
                        >
                          <v-icon x-large>mdi-check</v-icon>
                        </v-overlay>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            @click="cancel_dialog"
            :disabled="loadForm"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            type="Submit"
            :loading="loadForm"
            :disabled="!validForm"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";

export default {
  name: "DialogNewUserInvitation",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    tenantOverview: {
      type: Object,
      default: null,
      require: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey"])
  },
  data() {
    return {
      classTenant: null,
      validForm: false,
      loadForm: false,
      form: {
        profilPath: null,
        email: null,
        asFavorite: true
      },
      unitSelected: {
        type: null,
        tenantKey: null,
        businessUnitKey: null
      },
      grantList: [],
      loadGrantList: false,
      rules: {
        required: value =>
          !!value || this.$options.filters.capitalize(this.$t("form.required")),
        maxLength: len => v =>
          (v || "").length <= len || `${len} caractères maximum`,
        emailValid: v =>
          (v || "").length == 0 || /.+@.+\..+/.test(v) || "E-mail invalide"
      }
    };
  },

  created() {
    this.ClassTenant = new Tenant();
    this.assign_default_service();
    this.get_grant_list();
  },
  methods: {
    // Init
    async get_grant_list() {
      try {
        this.loadGrantList = true;
        const response = await this.ClassTenant.user_status(this.apiKey);
        this.grantList = response;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadGrantList = false;
      }
    },

    assign_default_service() {
      this.unitSelected = this.tenantOverview.tenant;
    },

    // Submit

    submit_form() {
      if (this.$refs.formNewUserInvitation.validate()) {
        this.post_invitation();
      } else {
        this.$refs.formNewUserInvitation.validate();
      }
    },

    async post_invitation() {
      try {
        this.loadForm = true;
        this.unitSelected.email = this.form.email;
        this.unitSelected.asFavorite = this.form.asFavorite;
        await this.ClassTenant.invite(
          this.apiKey,
          this.form.profilPath, // url param
          this.unitSelected // body
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("submited"); // TODO;
        this.loadForm = false;
        this.cancel_dialog();
      }
    },

    // Others

    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
