export default {
  // global theme for the app
  globalTheme: 'light', // light | dark

  // side menu theme, use global theme or custom
  menuTheme: 'global', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: 'global', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    backgroundPages: '#FFFFF1',
    soft: "#f3f7f7",
    primary: '#0096c7',
    secondary: '#CCDCDC',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#fb8c00'
  },
  
  // light theme colors
  light: {
    backgroundPages: '#FFFFF1',
    soft: "#f3f7f7", // utiliser sur "wUploadFile" et "wContractNBType", mais non relié.
    primary: '#004637',
    secondary: '#C2D6D6',
    accent: '#FFF500',
    error: '#FF7A00',
    info: '#039BE5',
    success: '#00CC66',
    warning: '#fb8c00',
  }
}

// origine LUX
//   background: "#ffffff",
//   surface: "#f2f5f8",
//   primary: "#0096c7",
//   secondary: "#a0b9c8",
//   accent: "#048ba8",
//   error: "#ef476f",
//   info: "#2196F3",
//   success: "#06d6a0",
//   warning: "#ffd166"
