import axios from "axios";

let urlApi = process.env.VUE_APP_URL_HORUS;

export default class billing {
  async billingInfo(apiKey) {
    let request = await axios.get(urlApi + "billing/entity", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async setBillingInfo(apiKey, newInfo) {
    let request = await axios.post(urlApi + "billing/entity", newInfo, {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async availables_card(apiKey) {
    let request = await axios.post(
      urlApi + "billing/entity/card/availables",
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async check_discount(apiKey, discountCode) {
    let request = await axios.post(
      urlApi + "billing/entity/checkDiscount?offerPack=" + discountCode,
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async get_availables_typologies(apiKey) {
    const request = await axios.post(
      urlApi + "billing/entity/typology/availables",
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
}
