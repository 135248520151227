import menuPages from "./menus/pages.menu";

export default {
  // main navigation - side menu
  menu: [
    {
      text: "",
      key: "",
      items: [
        {
          icon: "mdi-view-dashboard-outline",
          text: "oblige.myOblige",
          link: "/myoblige"
        },
        {
          icon: "mdi-file-outline",
          text: "oblige.myContracts",
          link: { path: "/contratslist", query: { all: "true" } },
        },
        {
          icon: "mdi-bell-outline",
          text: "oblige.wMyAlerts",
          link: "/myalerts"
        }
      ]
    }
  ],

  // footer links
  footer: [
    {
      // text: 'Docs',
      // key: 'menu.docs',
      // href: 'https://vuetifyjs.com',
      // target: '_blank'
    }
  ]
};
