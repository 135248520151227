<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>

    <Snackbar v-if="this.notification.open" />
    <IsUpdateBuild />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import config from "./configs";

// Layouts
import defaultLayout from "./layouts/DefaultLayout";
import authLayout from "./layouts/AuthLayout";
import errorLayout from "./layouts/ErrorLayout";

import IsUpdateBuild from "./components/isUpdateBuild/IsUpdateBuild.vue";

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    authLayout,
    errorLayout,
    Snackbar: () => import("./components/common/notification"),
    IsUpdateBuild
  },

  computed: {
    ...mapGetters(["notification"]),
    ...mapState("app", ["toast"]),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true;

      return false;
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || "default";

      return layout + "Layout";
    }
  },

  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map(href => ({ rel: "stylesheet", href }))
    ]
  }
};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* 
@font-face {
  font-family: "GintoNordMedium";
  src: local("GintoNordMedium"),
    url("./assets/fonts/GintoNord/ABCGintoNord-Medium.woff") format("truetype");
}

@font-face {
  font-family: "GintoNordRegular";
  src: local("GintoNordRegular"),
    url("./assets/fonts/GintoNord/ABCGintoNord-Regular.woff") format("truetype");
} */
</style>
