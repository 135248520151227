<template>
  <div class="d-flex justify-start align-center ml-2">
    <div class="mr-2 ">
      <span class="white--text subtitle-2 text-truncate">{{
        userAuth.displayName
      }}</span>
      <br />
      <span class="grey--text subtitle-2">
        {{ new Date() | formatDate }}
      </span>
    </div>

    <v-menu
      offset-y
      left
      transition="slide-y-transition"
      :close-on-content-click="false"
      :close-on-click="onOffClickOut"
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon class="elevation-2" v-on="on">
          <v-avatar size="40" color="accent">
            <v-img src="/images/avatars/user-avatar-oblige.png"></v-img>
          </v-avatar>
        </v-btn>
      </template>

      <!-- user menu list -->
      <v-list nav width="300">
        <template v-for="(item, index) in menuTri">
          <div :key="index">
            <v-divider
              class="my-1"
              v-if="item.text == 'alertManagement'"
            ></v-divider>

            <v-list-item
              @click="item.dialog ? openDialog(item) : goToRoute(item.link)"
              :exact="item.exact"
              :disabled="item.disabled"
              link
            >
              <v-list-item-icon>
                <v-icon small :class="{ 'grey--text': item.disabled }">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.key ? $t(item.key) : item.text | capitalize
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>

        <v-divider class="my-1" v-if="menuTri.length != 0"></v-divider>

        <v-list-item @click="(clickSupportInfo = true), menuParam()">
          <v-list-item-icon>
            <v-icon small>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <SupportInfo
                :locale="this.$i18n.locale"
                type="menu"
                :click="clickSupportInfo"
                @clickFalse="clickSupportInfo = false"
                @onOffClickOut="onOffClickOutM()"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-1" v-if="menuTri.length != 0"></v-divider>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon small>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("menu.logout") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <FullPageDialog />
    <User-space-dialog isConnect :showDialog.sync="clickAuthorizations" />
  </div>
</template>

<script>
import config from "@/configs";
import auth from "@/class/auth";
import { mapGetters } from "vuex";
import FullPageDialog from "../common/fullPageDialog.vue";
import SupportInfo from "@/components/common/SupportInfo";
import UserSpaceDialog from "../userSpaceConfigurations/UserSpaceDialog.vue";

/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  name: "ToolbarUser",
  computed: {
    ...mapGetters(["currentBu", "userAuth", "fullPageDialog"])
  },
  components: {
    FullPageDialog,
    SupportInfo,
    UserSpaceDialog
  },
  data() {
    return {
      menu: config.toolbar.user,
      menuOpen: false,
      menuTri: [],
      clickSupportInfo: false,
      clickAuthorizations: false,
      onOffClickOut: true
    };
  },
  mounted() {
    this.autorizations();
  },
  methods: {
    autorizations() {
      this.menuTri = [];
      for (let i = 0; i < this.menu.length; i++) {
        if (
          this.menu[i].text == "billing" &&
          this.currentBu.authorizations["manageBilling@billing"] == "ACCESSIBLE"
        ) {
          this.menuTri.push(this.menu[i]);
        } else if (
          this.menu[i].text == "authorizations" &&
          this.currentBu.authorizations["manageUser@auth"] == "ACCESSIBLE"
        ) {
          this.menuTri.push(this.menu[i]);
        } else if (this.menu[i].text == "profile") {
          this.menuTri.push(this.menu[i]);
        } else if (this.menu[i].text == "alertManagement") {
          this.menuTri.push(this.menu[i]);
        } else if (this.menu[i].text == "rate") {
          this.menuTri.push(this.menu[i]);
        }
      }
    },

    goToRoute(route) {
      this.$router.push({ path: route });
    },

    openDialog(item) {
      if (item.key != "menu.authorizations") {
        this.$store.commit("update_fullPageDialog", {
          title: item.key,
          component: item.dialogComponent
        });
      } else {
        this.clickAuthorizations = true;
      }
    },

    menuParam() {
      this.onOffClickOut = false;
    },

    onOffClickOutM() {
      this.onOffClickOut = true;
      this.menuOpen = false;
    },

    async logout() {
      this.authService = new auth();
      try {
        await this.authService.logout();
        this.$router.push({
          name: "auth-signin"
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
