<template>
  <v-dialog v-model="openDialog" max-width="600">
    <v-form
      v-model="validForm"
      ref="formEditOrganization"
      @submit.prevent="submit_form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title primary--text">
          Renomer le compte
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                :rules="[rules.required, rules.maxLength(64)]"
                label="Nom du compte"
                outlined
                dense
                color="primary"
                :disabled="loadForm"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            @click="cancel_dialog"
            :disabled="loadForm"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            type="submit"
            :loading="loadForm"
            :disabled="!validForm"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";

export default {
  name: "DialogEditOrganization",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    tenantInfo: {
      type: Object,
      require: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey", "currentBu", "labelCurrent", "labelTenant"])
  },
  data() {
    return {
      ClassTenant: null,
      validForm: false,
      loadForm: false,
      form: {
        type: null,
        tenantKey: null,
        name: null
      },
      rules: {
        required: v => !!v || "Ce champ est requis.",
        maxLength: len => v =>
          (v || "").length <= len || `${len} caractères maximum`
      }
    };
  },

  created() {
    this.ClassTenant = new Tenant();

    // Assign current data
    let tenantInfo = JSON.parse(JSON.stringify(this.tenantInfo));
    if (this.labelTenant) {
      this.form.name = JSON.parse(JSON.stringify(this.labelTenant));
    } else {
      this.form.name = JSON.parse(JSON.stringify(this.labelCurrent));
    }

    this.form.type = tenantInfo.type;
    this.form.tenantKey = tenantInfo.tenantKey;
  },
  methods: {
    // Submit
    submit_form() {
      if (this.$refs.formEditOrganization.validate()) {
        this.rename_tenant();
      } else {
        this.$refs.formEditOrganization.validate();
      }
    },
    async rename_tenant() {
      try {
        this.loadForm = true;

        let response = await this.ClassTenant.entity_rename(
          this.apiKey,
          this.form
        );

        if (!this.labelTenant) {
          this.$store.commit("update_labelCurrent", response.label);
        } else {
          this.$store.commit("update_labelTenant", response.label);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.cancel_dialog();
      }
    },

    // Dialog
    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
