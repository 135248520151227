export default {
  form: {
    required: "obligatoire",
    validate: "valider",
    cancel: "Annuler",
    fieldsRequired: "certain champs obligatoires ne sont pas renseigner",
    addressEmail: "adresse e-mail",
    invalidEmail: "email non valide",
    invalidDate: "format de date invalide",
    invalidDate1900: "attention, année inférieur à 1900",
    invalidDate3000: "attention, année inférieur à 3000",
    noAction: "pas d'action possible",
    securityPasswordDifferent: "les mots de passe sont différents",
    or: "ou",
    success: "opération effectuée",
    error: "erreur",
    errorSelect: "sélection un champs",
    confirmationMissing: "merci de confirmer en cochant la case",
    maxCharacters: "caractères maximum",
    authenticationMode: "mode de connexion",
    EnterCodeReceivedBySms: "Saisissez le code reçu par SMS"
  },

  support: {
    support: "support",
    codeSupport: "code support",
    generateSupportLink: "lien support",
    keySupport: "accès support",
    keyMsg: "merci de copier ce code est de le transmettre au support",
    copyToClipboard: "copier dans le presse-papier",
    close: "fermer",
    msgToClipBoard: "lien copier dans le presse papier",
    generateCodeSupport: "générer un code support",
    regenerateCodeSupport: "regénérer un code support",
    generateAbout:
      "le bouton ci dessous permet de générer un code que vous pourrez envoyer au support afin qu'ils accedent à votre espace"
  },

  auth: {
    welcome: "bienvenue",
    choiceTenants: "Choisir mon compte/classeur",
    returnSignInPage: "Retourner à la page de connexion",
    forgotPassword: "mot de passe oublié ?",
    resetPass: "réinitialiser",
    resetPassForm: "changer le mot de passe",
    yourPassword: "votre mot de passe",
    changePass: "votre mot de passe va expiré",
    expiredPass: "votre mot de passe est expiré",
    confirmYour: "confirmer votre",
    loginPage: "page de connexion",
    signupPage: "Créez votre compte Osmose",
    forgotPage: "mot de passe oublié",
    loginButton: "connexion",
    logout: "deconnexion",
    noAccount: "Vous n'avez pas de compte ?",
    createAccount: "Créez-en un ici",
    registrationButton: "inscription",
    accountAlreadyExist: "vous avez déjà un compte ?",
    signinRedirection: "connectez-vous",
    forgotSubtitle:
      "entrez l'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    signInNOK:
      "mot de passe incorrect, verifiez si votre compte est valide ou si le couple login / mot de passe est correct",
    changePassOk: "votre mot de passe a bien été changé",
    tokenExpired: "temps de session expiré veuillez vous reconnecter",
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    passwordPolicyOk: "votre mot de passe est valide",
    choosePassword: "Veuillez renseigner votre mot de passe",
    confirmPassword: "Veuillez confirmer votre mot de passe",
    toAllow: "vous n'étes pas autorisé à acceder à cette ressource",
    youHaveDaysLeftChangePwd:
      "il vous reste {days} jours pour changer votre mot de passe",
    changePwd: "voulez-vous le changer ?",
    signUpOk: "votre inscription a bien été prise en compte",
    userDisabled:
      "votre compte est désactivé, pour plus d'informations contacter le support.",
    securityUserUnknown:
      "le compte n'existe pas, si vous pensez que votre compte existe bien, contactez le support",
    securityTokenInvalid:
      "votre session est expirée, merci de vous reconnecter",
    securityAccessDenied:
      "vous n'êtes plus autorisés à vous connecter au dernier compte ou classeur auquel vous vous êtes connecté(e)s en dernier, essayez de vous reconnecter à nouveau.",
    dataTooMany: "l'email est indéterminé (multiples)",
    accountExists: "un compte avec cette adresse existe déjà.",
    modalLiveText: "le compte attaché à l'adresse",
    modalLiveTextAction:
      "souhaitez-vous réinitialiser le mot de passe de ce compte ?",
    accountUnconfirmedSignUp:
      "Ce compte existe déjà mais n'a jamais été validé",
    textSignUp:
      "nous avons bien pris en compte votre demande de création de compte. Vous recevrez très prochainement un email à l'adresse",
    textSignUpAfter:
      "contenant les instructions à suivre pour accéder à Osmose.",
    registrationDate: "date de première inscription :",
    pwdValidateDuration: "votre nouveau mot de passe sera valide pendant",
    userDisabledTitle: "compte utilisateur désactivé",
    renewCredentialsExpired:
      "il est possible que la période de renouvellement du mot de passe soit dépassée, dans ce cas veuillez cliquer sur mot de passe oublié",
    passwordPolicyHistory:
      "Ne doit pas avoir été utilisé depuis moins de {value}",
    passwordPolicyMinDigit: "contenir au moins {value} chiffre",
    passwordPolicyMinLen: "avoir une taille minimale de {value} caractères",
    passwordPolicyMaxLen: "avoir une taille maximale de {value} caractères",
    passwordPolicyMinSpecial: "contenir au moins {value} caractère spécial",
    passwordPolicyMinUpper: "contenir au moins {value} majuscule",
    passwordValidity: "il sera valide pendant {value}",
    passwordHadToContain: "Votre mot de passe doit :"
  },

  profile: {
    password: "mot de passe",
    name: "nom",
    language: "langue",
    email: "email",
    mobilePhone: "téléphone mobile",
    displayName: "nom d'affichage",
    myProfil: "mon profil",
    slackChannel: "canal slack",
    slackId: "token slack",
    yourInformation: "vos informations",
    communicationMethod: "comment me joindre ?",
    country: "pays",
    profileTypes: {
      administrator: "administrateur",
      manager: "gestion",
      consultant: "consultant",
      guess: "invité"
    }
  },

  common: {
    add: "Ajouter",
    description: "La description",
    delete: "Effacer",
    title: "Titre",
    save: "sauver",
    faq: "FAQ",
    contact: "Nous contacter",
    acceptance: "En cliquant sur le bouton d'inscription, vous acceptez les",
    tos: "Conditions d'utilisation",
    policy: "Politique de confidentialité",
    none: "aucun"
  },
  board: {
    titlePlaceholder: "Entrez un titre pour cette carte",
    deleteDescription: "Voulez-vous vraiment supprimer cette carte?",
    editCard: "Modifier la carte",
    deleteCard: "Supprimer la carte",
    state: {
      TODO: "FAIRE",
      INPROGRESS: "EN COURS",
      TESTING: "ESSAI",
      DONE: "TERMINÉ"
    }
  },
  chat: {
    online: "Utilisateurs en ligne ({count})",
    addChannel: "Ajouter une chaîne",
    channel: "Channel | Chaînes",
    message: "Message"
  },
  email: {
    compose: "Écrire un email",
    send: "Envoyer",
    subject: "Matière",
    labels: "Étiquettes",
    emptyList: "Liste de diffusion vide",
    inbox: "Boîte de réception",
    sent: "Expédié",
    drafts: "Brouillons",
    starred: "Favoris",
    trash: "Poubelle",
    work: "Travail",
    invoice: "Facture d'achat"
  },
  todo: {
    addTask: "Ajouter une tâche",
    tasks: "Tâches",
    completed: "Terminé",
    labels: "Étiquettes"
  },
  dashboard: {
    activity: "Activité",
    weeklySales: "Ventes hebdomadaires",
    sales: "Ventes",
    recentOrders: "Dernières commandes",
    sources: "Sources de trafic",
    lastweek: "vs la semaine dernière",
    orders: "Ordres",
    customers: "Les clients",
    tickets: "Billets d'assistance",
    viewReport: "Voir le rapport"
  },
  usermenu: {
    profile: "Profil",
    signin: "Se connecter",
    dashboard: "Tableau de bord",
    signout: "Déconnexion"
  },
  error: {
    notfound: "Page non trouvée",
    other: "Une erreur s'est produite"
  },
  check: {
    title: "Definir un nouveau mot de passe",
    backtosign: "Retour à la connexion",
    newpassword: "nouveau mot de passe",
    button: "Définissez un nouveau mot de passe et connectez-vous",
    error: "Le lien d'action n'est pas valide",
    verifylink: "Vérification du lien ...",
    verifyemail: "Vérification de l'adresse e-mail ...",
    emailverified: "Email verifié! Redirection ..."
  },
  forgot: {
    title: "Mot de passe oublié?",
    subtitle:
      "Entrez l'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    email: "Email",
    button: "Demander la réinitialisation du mot de passe",
    backtosign: "Retour à la connexion"
  },
  login: {
    title: "Se connecter",
    email: "Email",
    password: "Mot de passe",
    button: "Se connecter",
    orsign: "Ou connectez-vous avec",
    forgot: "Mot de passe oublié ?",
    noaccount: "Vous n'avez pas de compte ?",
    create: "Créez-en un ici",
    error: "La combinaison e-mail / mot de passe n'est pas valide",
    returnSignInPage: "Retourner à la page de connexion"
  },
  register: {
    ctaSignin: "vous avez déjà un compte ?",
    ctaSignup: "je n'ai pas encore de compte 🙁",
    title: "créez votre compte Oblige",
    displayName: "Nom d'affichage",
    tenantName: "Nom de l'entreprise",
    email: "Adresse email",
    password: "Mot de passe",
    button: "suivant",
    firstName: "Prénom",
    lastName: "Nom",
    registration: "je m'inscris",
    orsign: "Ou inscrivez-vous avec",
    agree: "En vous inscrivant, vous acceptez le",
    account: "Vous avez déjà un compte?",
    signin: "Se connecter"
  },
  utility: {
    maintenance: "En maintenance"
  },
  faq: {
    call: "Vous avez d'autres questions? Veuillez contacter"
  },
  rate: {
    rateCard: "plan tarifaire",
    rateCards: "plans tarifaires",
    noRateSelected: "vous n'avez aucun plan tarifaire de sélectionné",
    changeRate: "Changer de plan tarifaire",
    noDiscountActive: "Aucun code promotionnel actif",
    access: "access",
    essential: "essentiel",
    premium: "premium",
    month: "mois",
    twelveMonths: "engagement 12 mois",
    upTo: "jusqu'à {number} contrats",
    noEngagement: "sans engagement",
    oneUser: "1 seul utilisateur",
    numberUser: "jusqu'à {number} utilisateurs",
    contratheque: "contrathèque",
    notifications: "notifications automatiques",
    support: "support",
    email: "e-mail",
    chat: "chat",
    phone: "téléphone",
    mapExport: "export de la cartographie",
    trialPeriod: "essai gratuit 30 jours",
    moreContract: "vous avez au-delà de 250 contrats à stocker ?",
    consultOffer: "consultez-nous pour une offre personnalisée",
    moreQuestion: "Pour toute question, contactez-nous !",
    annualCheckout: "paiement annuel",
    mensualCheckout: "paiement mensuel",
    twentyDiscount: "Economisez 20%",
    expiryDate: "Expire le",
    setInfoBilling: "editer les informations de facturation",
    titleNoInfoBilling: "Aucune information de facturation",
    textNoInfoBilling:
      "Vous ne pouvez pas changer de forfait, car aucune information de facturation n'est renseignée"
  },
  ecommerce: {
    products: "Des produits",
    filters: "Filtres",
    collections: "Les collections",
    priceRange: "Échelle des prix",
    customerReviews: "Avis des clients",
    up: "et jusqu'à",
    brand: "Marque",
    search: "Rechercher un produit",
    results: "Résultats ({0} sur {1})",
    orders: "Ordres",
    shipping: "livraison",
    freeShipping: "Livraison gratuite",
    inStock: "En stock",
    quantity: "Quantité",
    addToCart: "Ajouter au chariot",
    buyNow: "Acheter maintenant",
    price: "Prix",
    about: "À propos de cet article",
    description: "La description",
    reviews: "Commentaires",
    details: "détails du produit",
    cart: "Chariot",
    summary: "Récapitulatif de la commande",
    total: "Total",
    discount: "Remise",
    subtotal: "Total",
    continue: "Continuer vos achats",
    checkout: "Check-out"
  },
  menu: {
    search: "Recherche (appuyez sur 'ctrl + /' pour faire la mise au point)",
    dashboard: "Tableau de bord",
    logout: "Se déconnecter",
    profile: "Mon profil utilisateur",
    management: "Gestion",
    blank: "Page blanche",
    pages: "Des pages",
    others: "Autres",
    email: "Email",
    chat: "Bavarder",
    todo: "Faire",
    board: "Tableau des tâches",
    users: "Utilisateurs",
    usersList: "liste",
    usersEdit: "Éditer",
    ecommerce: "Commerce électronique",
    ecommerceList: "Des produits",
    ecommerceProductDetails: "détails du produit",
    ecommerceOrders: "Ordres",
    ecommerceCart: "Chariot",
    auth: "Pages d'authentification",
    authLogin: "Connexion / Connexion",
    authRegister: "Inscription / Inscription",
    authVerify: "Vérifier les courriels",
    authForgot: "Mot de passe oublié",
    authReset: "réinitialiser le mot de passe",
    errorPages: "Pages d'erreur",
    errorNotFound: "Non trouvé / 404",
    errorUnexpected: "Inattendu / 500",
    utilityPages: "Pages utilitaires",
    utilityMaintenance: "Entretien",
    utilitySoon: "Bientôt disponible",
    utilityHelp: "FAQ / Aide",
    levels: "Niveaux de menu",
    disabled: "Menu désactivé",
    docs: "Documentation",
    feedback: "Retour d'information",
    support: "Soutien",
    landingPage: "Page de destination",
    pricingPage: "Page de tarification",
    billing: "Factures et paiement",
    rate: "Plans tarifaires",
    authorizations: "Gérer mon espace",
    authorizations2: "Gestion"
  },
  $vuetify: {
    badge: "Badge",
    close: "Fermer",
    dataIterator: {
      noResultsText: "Aucun enregistrements correspondants trouvés",
      loadingText: "Chargement des éléments ..."
    },
    dataTable: {
      itemsPerPageText: "Lignes par page:",
      ariaLabel: {
        sortDescending: "Tri par ordre décroissant.",
        sortAscending: "Tri par ordre croissant.",
        sortNone: "Pas trié.",
        activateNone: "Activez pour supprimer le tri.",
        activateDescending: "Activez pour trier par ordre décroissant.",
        activateAscending: "Activez pour trier par ordre croissant."
      },
      sortBy: "Trier par"
    },
    dataFooter: {
      itemsPerPageText: "Objets par page:",
      itemsPerPageAll: "Tout",
      nextPage: "Page suivante",
      prevPage: "Page précédente",
      firstPage: "Première page",
      lastPage: "Dernière page",
      pageText: "{0} - {1} sur {2}"
    },
    datePicker: {
      itemsSelected: "{0} sélectionné",
      nextMonthAriaLabel: "Le mois prochain",
      nextYearAriaLabel: "L'année prochaine",
      prevMonthAriaLabel: "Le mois précédent",
      prevYearAriaLabel: "Année précédente"
    },
    noDataText: "Pas de données disponibles",
    carousel: {
      prev: "Visuel précédent",
      next: "Visuel suivant",
      ariaLabel: {
        delimiter: "Diapositive du carrousel {0} sur {1}"
      }
    },
    calendar: {
      moreEvents: "{0} de plus"
    },
    fileInput: {
      counter: "{0} fichiers",
      counterSize: "{0} fichiers ({1} au total)"
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    },
    pagination: {
      ariaLabel: {
        wrapper: "Navigation par pagination",
        next: "Page suivante",
        previous: "Page précédente",
        page: "Aller à la page {0}",
        currentPage: "Page actuelle, page {0}"
      }
    }
  },
  uploadFile: {
    sendingCompleted: "le fichier a bien été envoyé",
    sendingCompleteds: "les fichiers ont bien été envoyé",
    multipleSendingCompleted: "les {size} fichiers ont bien été envoyés",
    listUploadFiles: "liste des fichiers téléchargés",
    success_infoSendingFile:
      "votre fichier est désormais en cours d'analyse. Vous recevez un email pour vous prévenir dès qu'il sera prêt pour être revu par vos soins. Vous êtes à une étape de vous sécuriser sur ce contrat.",
    success_infoSendingFiles:
      "vos fichiers sont désormais en cours d'analyse. Vous recevez un email pour vous prévenir dès qu'ils seront prêt pour être revus par vos soins. Vous êtes à une étape de vous sécuriser sur ces contrats.",
    maxSize: "le fichier dépasse les {size} Mo",
    error_multipleFiles: "de vos fichiers n'ont pas pu être envoyés",
    error_singleFiles: "de vos fichiers n'a pas pu être envoyé",
    error: "votre fichier n'a pu être envoyé",
    errors: "vos fichiers n’ont pu être envoyé",
    warning: "{success} fichier(s) ont bien été envoyer et {error} en erreur ",
    invalideTokenOblige: "veuillez vous reconnecter à Oblige merci",
    invalidTypeFile: "vous ne pouvez pas télécharger de fichiers de ce type."
  },
  oblige: {
    next: "suivant",
    previous: "précedent",
    select: "sélectionner",
    //TEXT AUTH
    signIn: "connexion",
    forgotPassword: "mot de passe oublié",
    resetPass: "réinitialiser",
    resetPassForm: "changer le mot de passe",
    signInTitle: "se connecter",
    signInOK: "vous êtes bien connecter",
    logOut: "déconnexion",
    changePass: "personnalisation du mot de passe",
    confirmYour: "confirmer votre",
    loginPage: "page de connexion",
    signupPage: "page d'inscription",
    forgotPage: "mot de passe oublié",
    loginButton: "connexion",
    noAccount: "vous n'avez pas de compte ?",
    createAccount: "Créez-en un ici",
    registrationButton: "inscription",
    accountAlreadyExist: "vous avez déjà un compte ?",
    signinRedirection: "connectez-vous",
    signUp: "inscription",
    forgotSubtitle:
      "entrez l'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    yourRoleIis: "Votre rôle est",

    infoSociety: "information sur la société",

    //Auth Message
    helpAuth: "aide à la connexion",
    helpAuthLink: "besoin d'aide pour la connexion ?",
    signInNOK:
      "mot de passe incorrect, verifiez si votre compte est valide ou si le couple login / mot de passe est correct",
        missingPassword:
      "aucun mot de passe n'est renseigné pour ce compte. Cliquez sur mot de passe oublié afin de le réinitialiser",
    changePassOk: "votre mot de passe a bien été changé",
    tokenExpired: "temps de session expiré veuillez vous reconnecter",
    pwdForgot:
      "les instructions pour réinitialiser votre mot de passe vous ont été envoyées par email",
    passeNotIdentical: "les mots de passe ne sont pas identiques",
    toAllow: "vous n'étes pas autorisé à acceder à cette ressource",
    daysLeftBeforeChangePwd:
      "jours restant avant le changement de votre mot de passe : ",
    youHaveDaysLeftChangePwd:
      "il vous reste {days} jours pour changer votre mot de passe",
    changePwd: "voulez-vous changer le mot de passe ?",
    editMyPassword: "modifier mon mot de passe",
    toChangePassword: "changer le mot de passe",
    signUpOk: "votre inscription a bien été prise en compte",
    signUpUserExist:
      "l'utilisateur existe déjà, si vous avez un compte : Osmose, connectez-vous avec",
    logOutMessage: "vous êtes bien déconnecté",
    userDisabled:
      "Oups, votre compte a été désactivé, veuillez contacter le support",
    userDisabledTitle: "compte utilisateur désactivé",
    userNoconfirmedTitle: "compte utilisateur non comfirmé",
    userNoconfirmed:
      "Vous y êtes presque ! validez votre compte  en cliquant sur le lien envoyé dans votre boîte mail",
    renewCredentialsExpired:
      "il est possible que la période de renouvellement du mot de passe soit dépassée, dans ce cas veuillez cliquer sur mot de passe oublié",
    securityUserUnknown:
      "le compte n'existe pas, si vous pensez que votre compte existe bien, contactez le support",
    securityTokenInvalid:
      "votre session est expirée, merci de vous reconnecter",
    dataTooMany: "l'email est indéterminé (multiples)",
    securityAccessDenied:
      "vous n'êtes plus autorisés à vous connecter au dernier compte ou classeur auquel vous vous êtes connecté(e)s en dernier, essayez de vous reconnecter à nouveau.",
    accountExists: "un compte avec cette adresse existe déjà",
    modalLiveText: "le compte attaché à l'adresse",
    modalLiveTextAfter:
      "existe déjà. En cas d'oubli vous pouvez changer votre mot de passe. Dans ce cas un email vous sera adressé contenant un lien vous permettant de le réinitialiser.",
    modalLiveTextAction:
      "souhaitez-vous réinitialiser le mot de passe de ce compte ?",
    accountUnconfirmedSignUp:
      "Ce compte existe déjà mais n'a jamais été validé",
    textSignUp:
      "nous avons bien pris en compte votre demande de création de compte. Vous recevrez très prochainement un email à l'adresse",
    textSignUpAfter:
      "contenant les instructions à suivre pour accéder à Oblige.",
    titlePwdForgot: "demande de renouvellement de mot de passe effectuée",
    textPwdForgot: "un email a été envoyé à l'adresse",
    textPwdForgotAfter:
      "contenant un lien de réinitialisation de votre mot de passe.",
    registrationDate: "date de première inscription :",
    userNotCertified:
      "action non autorisé, valider votre compte pour pouvoir effectuer cette action",
    //FIN

    newContract: "nouveau contrat",
    nameContract: "nom du contract",
    whereIAm: "compte/classeur dans lequel je suis connecté",
    fileLocation: "compte/classeur dans lequel est stocké le contrat",
    noTag: "aucun tag",
    changeWorkspace: "changer de compte/classeur",
    chooseWorkspace: "choisir mon compte/classeur",
    moreOptions: "plus d'options",
    contract: "contrat",
    total: "total",
    iAccept: "j'accepte",
    iRefuse: "je refuse",
    cookiePolicy: "politique en matière de confidentialité et de cookies",
    messageAcceptCookieP1:
      "Oblige vous demande d'accepter ses cookies afin de vous permettre la sauvegarde de votre adresse email sur la page de connexion vous permettant ainsi de gagner en efficacité lors de votre connexion ou d'une éventuelle perte de mot de passe, mais également pour vous reconnecter sur le dernier compte ou classeur que vous avez visité lors de votre dernière connexion. Pour obtenir d'avantages d'informations sur ces cookies et le traitement de vos données personnelles, consultez notre ",
    messageAcceptCookieP2: "sur nos",
    messageAcceptCookieP3:
      "Acceptez-vous ces cookies ainsi que les implications associées à l'utilisations de vos données personnelles ?",
    cookieParameter: "vos paramètres de cookies",
    memberArea: "espace membre",
    signIn: "connexion",
    forgotPassword: "mot de passe oublié",
    password: "nouveau mot de passe",
    close: "fermer",
    later: "plus tard",
    closeMsg: "fermer le message",
    resetPass: "réinitialiser",
    resetPassForm: "changer le mot de passe",
    warningFormLengthDate:
      "attention la saisie de date ne doit pas être correcte",
    signInTitle: "se connecter",
    logOut: "déconnexion",
    changePass: "personnalisation du mot de passe",
    confirmYour: "confirmer votre",
    yes: "oui",
    no: "non",
    true: "oui",
    false: "non",
    search: "chercher",
    search3Temper: "chercher (min 3 caractères)",
    research: "rechercher",
    noSpaceFound: "aucun espace trouvé",
    favoris: "favoris",
    hello: "bonjour",
    researchTooltip: "lancer la recherche",
    home: "accueil",
    homeDashboard: "accueil",
    profile: "profil",
    Error: "erreurs",
    french: "français",
    english: "anglais",
    lastName: "nom",
    firstName: "prénom",
    signUp: "inscription",
    invite: "inviter un collaborateur",
    edit: "modifier",
    send: "envoyer",
    sendDocument: "ajouter un document",
    choiceTenants: "choisisser un compte",
    management: "gestion",
    managementMyAlert: "gérer mes rappels",
    NumberOfContracts: "contrats",
    NumberOfContractsToValidate: "contrats à valider",
    loading: "chargement",
    nextDeadlines: "prochaines échéances",
    nextDeadlines5: "mes 5 prochaines échéances",
    nextDeadline: "prochaine échéance",
    nextDeadlines_noData: "aucune échéance déduite",
    nextCallBack: "prochain rappel",
    statusOfContracts: "état des contrats",
    allUnknownContract: "tout les contrats de nature indéterminée",
    wContractNav: "contrats",
    wContractNavTitle: "contrat | contrats",
    wContractDetail: "contrat",
    downloadFile: "télécharger le fichier",
    download: "télécharger",
    welcome: "bienvenue",
    signInText: "ne manquez plus une échéance",
    returnListContracts: "retourner à la liste des contrats",
    returnContracts: "retourner au détail du contrat",
    moveADocument: "déplacer un document",
    moveDocument: "déplacer le document",
    MyAlerts: "rappels",
    wMyAlerts: "mes rappels",
    year: "an",
    month: "mois",
    week: "semaine",
    day: "jour | jours",
    days4: "4 jours",
    dayss: "jours",
    today: "aujourd'hui",
    ongoingTreatment: "traitement en cours",
    processingError: "erreur de traitement",
    numberOfDocuments: "nombre de document  | nombre de documents ",
    viewFile: "visualiser le fichier",
    thereIs: "il y à",
    and: "et",
    notDefined: "non définie",
    CGVStart: "je reconnais avoir pris connaissance des",
    CGVLink: "conditions générales d'utilisations",
    agreeCGV: "j'accepte les",
    CGVLink: "conditions générales d'utilisations",
    CGVStart: "je reconnais avoir pris connaissance des",
    legalNotice: "mentions Légales",
    legalNotice: "mentions Légales",
    contact: "contact",
    sendInvitation: "invitation envoyée",
    sendMessage: "message envoyé",
    success: "succès",
    title: "titre",
    message: "message",
    messageSuccess: "message bien envoyé",
    messageSentText: "votre message a bien été envoyé",
    language: "langue",
    contractName: "contrat",
    contractsPerPage: "contrats par pages",
    backHome: "retourner à l'accueil",
    mentions: "mentions",
    mentions_noData: "aucune mention déduite",
    deadlines: "echéances",
    characteristics: "caractéristiques",
    in: "dans",
    otherCharacteristic: "autres caractéristiques",
    document: "documents",
    documentss: "documents",
    processing: "traitement",
    status: "statut",
    from: "du",
    to: "au",
    every: "tous les",
    fromThe: "à partir du",
    the: "le",
    support: "support",
    faq: "faq",
    _unknown: "indéterminé",
    draft: "pour revue",
    DRAFT: "pour revue",
    live: "sous surveillance",
    LIVE: "actif",
    outOfScope: "hors de portée",
    out_Of_Scope: "non suivis",
    out_of_scope: "non suivi",
    OUT_OF_SCOPE: "non suivi",
    expired: "expiré",
    Expired: "expiré",
    EXPIRED: "expiré",
    ARCHIVED: "archivé",
    archivedDetail: "Ce contrat est désormais archivé.",
    DONE: "terminé",
    IN_PROGRESS: "en cours",
    DONE_WITH_ERROR: "erreur",
    contractAttribut: "attributs du contrat",
    documents: "document | documents",
    rate: "confiance",
    rateLevel: "niveau de confiance de détection",
    MANUAL_FREE: "saisie manuelle",
    MANUAL_MENTION: "sélectionné manuellement",
    manual: "manuel",
    AUTOMATIC: "automatique",
    DEDUCTION: "déduction automatique",
    deduction: "déduction",
    deleteValue: "mettre la valeur par défaut",
    deleteDoc: "supprimer le document",
    years: "années",
    text: "texte",
    addAddendum: "ajouter un avenant",
    addAppendix: "ajouter une annexe",
    addContract: "ajouter un contrat",
    addContractTooltip: "permet d'ajouter un contrat dans Oblige",
    duplicateDocument2:
      "ce document semble déjà enregistré, veuillez passer en brouillon si ce contrat n'est pas déjà enregistré",
    contactSupport: "contacter le support",
    authorization: "autorisation",
    authorizations: "gérer ma société",
    alertManagement: "Gérer mes rappels",
    contractSignature: "date de signature",
    signDate: "signé le",
    law: "droit",
    username: "nom d'utilisateur",
    fillField: "Merci de remplir au moins 1 des champs",
    noSubscribeAlert: "ATTENTION ! Aucun rappel n'a été programmé",
    errorDublicateFile:
      "impossible d'ajouter ce fichier, il est déjà présent dans ce contrat.",
    openNewTab: "ouvrir dans un nouvel onglet",
    closeTicket: "voulez-vous cloturer ce rappel ?",
    tooltip_closeTicket: "cloturer ce rappel ?",
    deadlinePath: "type de rappel",
    deadlineDate: "date du rappel",
    ticketCreation: "date de création",
    lastChase: "date de la dernière notification",
    firstChase: "date de la première notification",
    noAlert: "pas de rappel",
    resultSearch: "résultat de la recherche",
    noResult: "pas de résultat",
    contractsInError: "contrat en erreur",
    contractsInProgress: "traitement en cours",
    reachThreshold:
      "Votre quota de contrats 'Sous surveillance' est atteint. Veuillez renseigner vos données de facturations afin de pouvoir poursuivre le suivi de vos contrats.",
    deadlineCommingSoon: "prochaine echéance",
    activityCode: "code d'activité",
    activityLabel: "etiquette d'activité",
    category: "catégorie",
    creationDate: "date de création",
    headquarterCode: "code du siège",
    headquarterLocation: "Adresse enregistrée",
    identifier: "SIREN",
    lastAdministrativeDate: "dernière date administrative",
    legalType: "type légal",
    registeredCountry: "pays d'enregistré",
    size: "taille",
    vatin: "TVA intracommunautaire",
    references: "références",
    contractUnknownLanguage: "contrat langue inconnue",
    notify10DaysBeforeEvent: "notifie 10 jours calendaires avant l'événement",
    toComplete: "a compléter",
    confirmSupp: "confirmer la suppression",
    tenantSupp: "tenant supprimer",
    supp: "supprimer",
    createTenant: "créer un nouveau compte",
    manageAccounts: "gérer mes comptes et mes classeurs",
    createBu: "créer un classeur",
    buLabel: "Classeur",
    tenantLabel: "Compte",
    tenantCreated: "comtpe créé",
    buCreated: "classeur créé",
    hours: "heures",
    deleted: "supprimé",
    archived: "archivé",
    removeValueDetect: "passer à non applicable",
    contractChangeCharacteristic: "changement de la caractéristique",
    actiondraft: "passer en Version de Travail",
    actionout_of_scope: "exclure de la surveillance",
    actionarchived: "archiver le contrat",
    actiondeleted: "supprimer le contrat",
    actionlive: "mettre sous surveillance",
    alertMe: "rappeler",
    alertMe2: "me rappeler",
    beforeDeadline: "avant l'échéance",
    before: "avant",
    notApplicable: "non applicable",
    address: "adresse",
    blocpopup:
      "merci de désactiver votre bloqueur de PopUp pour visualiser le document ",
    seeOnMaps: "voir sur Maps",
    actionclean: "retirer les valeurs manuelles",
    actionreprocess: "relancer une nouvelle détection",
    youDoNotAccount: "vous n'avez pas de compte ?",
    registerForFree: "inscrivez-vous gratuitement",
    administrator: "administrateur",
    manager: "gestion",
    consultant: "consultant",
    guess: "invité",
    mapExport: {
      title: "export de la cartographie",
      tooltip: "exporter votre contrathèque sous différents formats",
      files: {
        label_overview: "liste des contrats",
        label_cartography: "organisation des contrats",
        label_taxonomy: "taxonomie des contrats",
        fileExcel: "fichier Excel"
      }
    },
    noActionPossible: "pas d'action possible",
    myContracts: "mes contrats",
    contractList: "liste des contrats",
    contractDetail: "détails du contrat",
    seeContractDetail: "Voir le détail du contrat",
    myFilters: "filtre",
    myNatures: "catégorie",
    filteredView: "vue filtrée",
    value: "valeur",
    confidenceRate: "% de confiance",
    changeCharacteristic: "changement caractéristique",
    person: "personne",
    organization: "organisation",
    service: "service",
    reason: "raison",
    myProfil: "mon profil utilisateur",
    editProfil: "modifier mon profil",
    noData: "pas de données",
    durationResolutionTicket: "durée moyenne de résolution d'un ticket",
    name: "nom",
    email: "email",
    profil: "profil",
    state: "état",
    lastActivity: "derniere activité",
    action: "action",
    reloadInvite: "envoyer une nouvelle invitation",
    removeUser: "retirer l'utilisateur",
    personnWhoTreat: "qui doit traiter les rappels ?",
    myOblige: "mon Oblige",
    goToMyOblige: "aller sur Mon oblige",
    myObligeContractLive: "contrat sous surveillance",
    myObligeContractsLive: "contrats sous surveillance",
    eventCalendar: "evenement du calendrier",
    alertBy: "me rappeler par",
    billingInformation: "vos informations de facturation",
    facturation: "mes données de facturation",
    myInfosContact: "mes informations de contact",
    companyDetails: "coordonnées de l'entreprise",
    billingAddress: "adresse de facturation",
    invalidIban: "IBAN ou BIC invalide",
    promoCodeInvalid: "le code promotionnel est invalide",
    offerPack: "offre spéciale",
    offerPackCode: "code",
    bankDetails: "coordonnées bancaires",
    companyName: "nom de l'entreprise",
    company: "entreprise",
    contactName: "nom",
    contactEmail: "email",
    contactPhone: "téléphone",
    country: "pays",
    address: "adresse",
    city: "ville",
    postCode: "code postal",
    iban: "iban",
    bic: "bic",
    noBillingInformation:
      "vous n'avez pas encore enregistré d'informations de facturations",
    complementAddress: "complément d'adresse",
    calendar: "calendrier des échéances",
    passwordPolicyPattern:
      "votre mot de passe ne remplit pas toutes les conditions requises",
    passwordPolicyMaxLen:
      "votre mot de passe doit faire moins de {size} caractères",
    passwordPolicyMinLen: "doit contenir {size} caractères",
    passwordPolicyMinUpper: "doit contenir {size} majuscules",
    passwordPolicyMinDigit: "doit contenir {size} nombres",
    passwordPolicyMinSpecial: "doit contenir {size} caractères spécial",
    passwordPolicyHistory:
      "doit être différent de ceux utilisés il y a moins de {size}",
    // passwordPolicyMinLen:
    //   "votre mot de passe doit contenir au moins {size} caractères",
    // passwordPolicyPattern:
    //   "votre mot de passe ne remplit pas toutes les conditions requises",
    // passwordPolicyMaxLen:
    //   "votre mot de passe doit faire moins de {size} caractères",
    // passwordPolicyMinUpper:
    //   "votre mot de passe doit contenir au moins {size} majuscules",
    // passwordPolicyMinDigit:
    //   "votre mot de passe doit contenir au moins {size} nombres",
    // passwordPolicyMinSpecial:
    //   "votre mot de passe doit contenir au moins {size} caractères spécial",
    // passwordPolicyHistory:
    //   "votre mot de passe doit être différent de ceux utilisés il y a moins de {size}",
    natureUnknown: "nature non reconnue",
    changeCharacteristic_indication:
      "sélectionner une valeur parmi les propositions surlignées dans le texte",
    changeCharacteristic_btnSaisie: "saisir manuellement",
    identification: "SIRET",
    analyst: "analyste",
    userNeverLogIn: "l'utilisateur s'est jamais connecté",
    userHasLoggedIn: "l'utilisateur s'est connecté",
    rememberContract: "rappels",
    accessBillingModule: "accéder au module facturation",
    filterByStatus: "filtrer par statut",
    filterByTags: "filtrer par tags",
    infoAboutTags:
      "apparemment, vous n'avez pas mis en place de Tags pour faciliter votre classement et vos recherches. Prenez quelques minutes pour vous faciliter la vie et créer les Tags les plus pertinents possibles !",
    user: "utilisateur",
    questionSupport:
      "pour toute question, n'hésitez pas à nous contacter: support@oblige.fr ",
    thankYouTrust: "Merci de votre confiance,",
    clickToBillingPage: "cliquer ici pour acceder au module de facturation",
    addReminderAndNotified: "ajouter un rappel et être prévenu",
    contractWaitingDocumentDetail: "contrat sans document",

    //reason
    numberRenewal:
      "Ce contrat a été renouvelé {count} fois, dernière reconduction {renewalType} le",
    seeOriginalContract: "voir le contrat original",
    contractMissingCharacteristic: "a compléter",
    contractPendingReview: "a valider",
    contractReachTreshold: "seuil atteint",
    maxLiveContract: "Oups, vous dépassez votre quota de contrats !",
    maxLiveContractLong:
      "Votre quota de contrats est atteint. Veuillez changer de plan tarifaire. Pour profiter des services d'Oblige sur plus de contrats, vous devez changer de plan tarifaire. Nous vous invitons à le renseigner dans votre espace facturation. Profitez en pour vérifier que tout est en ordre.",
    expiredRateCard: "Oups, votre période d'essai est arrivée à échéance !",
    expiredRateCardLong:
      "pour profiter des services d'Oblige sur plus de contrats, vous devez sélectionner un plan tarifaire. Cela nous permettra de vous établir une facture le mois prochain et de prélever son montant automatiquement. Nous vous invitons à le saisir dans votre espace facturation. Profitez en pour vérifier que vos informations de paiements sont en ordre.",
    invalidPaymentDetails: "Oups, vos informations de paiements sont expirées.",
    invalidPaymentDetailsLong:
      "pour profiter des services d'Oblige sur plus de contrats, nous avons besoin de vos coordonnées bancaires. Cela nous permettra de vous établir une facture le mois prochain et de prélever son montant automatiquement. Nous vous invitons à les saisir dans votre espace facturation. Profitez en pour vérifier que tout est en ordre.",
    missingIBAN: "merci de remplir un IBAN",
    invalidIBAN: "l'IBAN renseigné est invalide",
    missingGCC: "veuillez approuver les conditions tarifaires",
    contractUnknownNature: "nature non reconnue",
    contractUnknownLanguage: "langue non reconnue",
    duplicateDocument: "a verifier (doublon suspecté)",
    internalServerError: "erreur interne",
    contractAutoExpired: "expiré automatiquement",
    contractTreatmentPending: "en attente de traitement",
    contractLiveDetail:
      "votre contrat est sous surveillance. Ajouter des rappels afin d'être averti d'une prochaine échéance",
    documentNotSignedDetail: "ce contrat n'est pas signé",

    //OverviewReason
    contractMissingCharacteristicOverview: "les contrats que je dois compléter",
    contractPendingReviewOverview: "les contrats que je dois valider",
    ContractReachTresholdOverview:
      "les contrats qui sont en attente car le seuil forfaitaire est atteint",
    contractUnknownLanguageOverview:
      "les contrats dont la langue n'est pas prise en charge",
    contractUnknownNatureOverview:
      "les contrats dont la nature n'a pas pu etre reconnue",
    duplicateDocumentOverview:
      "les contrats pour lesquels il y a une suspicion de duplicata",
    internalServerErrorOverview:
      "les contrats qui n'ont pas été correctement traité par le système (contacter le support)",
    contractExpiredOverview: "votre contrat est expiré.",
    contractAutoExpiredOverview: "expiré automatiquement",
    _otherOverview: "autre",
    contractTreatmentInProgressOverview: "les contrats en cours de traitement",
    contractTreatmentPendingOverview: "les contrats en attentes de traitement",
    contractRenewalPendingOverview:
      "Les contrats semblent être expirés et sans reconductions tacites",
    documentNotSignedOverview: "ces contrat ne sont pas signé",
    contractBadConfidenceOverview: "les contrats que je dois valider",
    contractWaitingDocument: "Contrats sans document",

    //DetailReason
    contractPendingAnalysisDetail: "contrat en attente de l'analyse détaillée",
    contractPendingAnalysisAnalyste:
      "Veuillez renseigner les informations manquantes puis passer pour revue",
    contractRenewalPendingTaciteDetail:
      "Ce document contient une clause de reconduction tacite et sa durée initiale ou reconduite est arrivée à échéance. Nous vous remercions d'indiquer si le contrat à été résilié.",
    contractRenewalPendingDetail:
      "La durée de ce contrat est arrivée à échéance. Ce contrat ne contient pas de clause de reconduction tacite. Nous vous remercions d'indiquer s'il a été reconduit ou s'il est expiré.",
    contractMissingCharacteristicDetail:
      "Je dois compléter le contrat, certaines caractéristiques obligatoires sont manquantes",
    contractMissingCharacteristicAnalyste:
      "certaines caractéristiques obligatoires sont manquantes",
    contractPendingReviewDetail:
      "les caractéristiques obligatoires du contrat sont renseignées, je dois valider le contrat pour revue",
    contractReachTresholdDetail:
      "mettre à jour votre forfait, seuil maximal atteint",
    contractUnknownLanguageDetail: "Oblige ne prend pas en charge cette langue",
    contractUnknownNatureDetail:
      "Oblige n'est pas parvenu à reconnaitre la nature de ce contrat veuillez la renseigner manuellement",
    contractUnknownNatureAnalyste:
      "Oblige n'est pas parvenu à reconnaitre la nature de ce contrat veuillez la renseigner manuellement",
    duplicateDocumentDetail:
      "ce contrat a déjà été mis dans votre espace Oblige, veuillez vérifier, puis supprimer ou forcer selon le cas",
    internalServerErrorDetail:
      "Oblige n'est pas parvenu à traiter ce contrat, veuillez contacter le support",
    internalServerErrorAnalyste:
      "Une erreur est survenue durant le traitement automatique. Contacter le support.",
    contractExpiredDetail: "votre contrat a été expiré {renewalTypeAdv} le ",
    contractAutoExpiredDetail: "expiré automatiquement",
    contractTreatmentInProgressDetail:
      "Oblige est en train de traiter ce contrat",
    contractTreatmentPendingDetail:
      "ce contrat est en attente de traitement, il sera traité dès que possible",
    contractTreatmentPendingAnalyste:
      "ce contrat est en attente de traitement, il sera traité dès que possible",
    contractTreatmentInProgressAnalyste:
      "le contrat est actuellement pris en charge par l'IA",
    contractBadConfidenceDetail:
      "les caractéristiques obligatoires du contrats sont renseignées mais avec un taux de confiance inférieure à 80%.",
    contractBadConfidenceAnalyste:
      "Je dois vérifier le contrat, les caractéristiques obligatoires du contrats sont renseignées mais avec un taux de confiance inférieure à 80%.",

    // widgets MyContract
    widgets_noData: {
      reasonTable:
        "consultez le nombre d’action à mener sur vos contrats en stocks !",
      deadlines: "visualisez ici vos 5 prochaines échéances !",
      contractByStatus:
        "Consultez le statut de vos contrats et prenez les actions nécessaires pour finaliser leur enregistrement !",
      contractNotLive:
        "Prenez les actions nécessaires pour finaliser l’enregistrement de vos contrats !",
      graphContracts:
        "Visualisez vos contrats triés par nature (bail commercial, contrat de travail, abonnement….)",
      graphContractsReason:
        "Visualisez la quantité d’actions à mener sur vos contrats en un clin d’œil !",
      validateAlert: "Consultez vos rappels en cours !",
      ticketsByAge: "Analysez vos rappels qui ne sont pas encore validés !",
      closedTicketsByValidator:
        "Consultez qui valide les rappels dans votre équipe !",
      ticketsVariation:
        "Analyser l'évolution de la quantité de rappels que vous recevez !",
      ticketResolutions: "Analysez le temps de validation de vos rappels !",
      ticketsByValidator:
        "Consultez le nombre de rappels créés par les membres de votre équipe",
      eventsByMonth:
        "Anticipez les échéances à venir en observant le calendrier. Plus les cases sont foncées, plus il y a d'échéances",
      treeMapClassification:
        "Visualisez en clin d'œil votre data room de contrats"
    },
    calendar_explication:
      "Visualisez vos prochaines échéances sur ce calendrier !",

    reasonGraph: "mes contrats en un clin d'oeil ",
    reasonGraphDescription:
      "ce graphique vous donne un rapide visuel sur votre quantité de contrat selon les actions à mener dessus.",
    reasonTable: "les actions à mener sur mes contrats",
    reasonTableDescription: "nombre de contrat ayant une action à mener",
    chartTypeContracts: "mes contrats par nature",
    chartTypeContractsDescription:
      "graphique représentant les parts de chaque nature de contrats sur votre total de contrats.",
    uploadContract: "chargez ici vos contrats",
    uploadContractDescription:
      "permet d’ajouter vos contrats sur Oblige, vous pouvez déposer vos contrats soit en les glissants sur l’icône, soit en cliquant dessus et en les sélectionnant, soit en envoyant un mail depuis votre adresse utilisé sur oblige à l’adresse :  ",
    uploadDropbox_btn: "utilisez Dropbox pour alimenter Oblige",
    uploadDropbox_modal_title: "utiliser un lien Dropbox",
    uploadDropbox_placeholder: "coller votre lien ici",
    uploadDropbox_linkMatch: "le lien doit contenir",
    dropboxText:
      "chargez ici vos contrats par simple glisser-déposer ou choisissez les dans votre Dropbox.",
    dropboxDetail1: "les fichiers compressés (zip) sont également acceptés.",
    dropboxDetail2:
      "vous pouvez également envoyer ou transférer directement un mail  contenant un ou plusieurs contrat(s) à",
    listOfContractsFollowed: "liste des contrats à traiter",
    listOfContractsFollowedDescription:
      "liste des contrats affichants les actions à mener associées pour chacun",
    TypeNbContract: "mes contrats par statut",
    TypeNbContractDescription:
      "liste des contrats par nature (ex : bail, contrat de travail, …) triés selon leur statut. ",
    MyContractByStatusChevron: "mes contrats par statut",
    MyContractByStatusChevronDescription: "liste des contrats par status",
    contractListTree: "ma contrathèque",
    contractListTreeDescription:
      "liste des contrats triés par famille de nature de contrats",
    myContractsByStatusChevron: {
      title: "Mes contrats par statut"
    },
    tutorialAddContract: "aide à l'ajout de contrat",
    wTutorialAddContract: "aide à l'ajout de contrat",
    wTutorialAddContractDescription:
      "ce widget à pour objectif de présenter un carrousel d'images permettant de vous guider de l'ajout d'un contrat à la création de vos rappels",

    // widgets MyAlerts
    WCalendar: "calendrier",
    WCalendarDescription:
      "un calendrier permettant de voir sur le long terme les échéances importantes à venir.",
    WNextDeadlines: "5 prochaines échéances",
    WNextDeadlinesDescription:
      "une liste des 5 échéances ayant la date la plus proche de la date du jour.",
    WValidateAlert: "rappels",
    WValidateAlertDescription:
      "liste des rappels actuellement en cours avec la possibilité de les clôturer s'ils ne sont plus d’actualités.",
    ticketsByAge: "nombre de rappels non résolus par âge",
    ticketsByAgeDescription:
      "un graphique représentant le nombre de rappels non validés selon des périodes d’anciennetés définies",
    closedTicketsByValidator: "qui valide les rappels dans mon équipe",
    closedTicketsByValidatorDescription:
      "un graphique représentant la quantité de rappels validés parmis les utilisateurs ayant accès à l’organisation",
    ticketsVariation: "evolution par mois du nombre de rappels identifiés",
    ticketsVariationDescription:
      "graphique linéaire représentant l’évolution du nombre de rappels émis par rapport au nombre de rappels résolus",
    ticketResolutions: "durée moyenne de résolution d'un ticket",
    ticketResolutionsDescription:
      "temps moyen entre l’ouverture et la fermeture d’un ticket de façon mensuelle. ",
    ticketsByValidator: "qui doit traiter les rappels ?",
    ticketsByValidatorDescription:
      "nombre de rappels pour chaque utilisateur individuel sur l’organisation commune",
    eventsByMonth: "echéances à l'horizon",
    eventsByMonthDescription:
      "calendrier par type de rappels coloriés en fonction du nombre de rappels par type par mois",
    alertManagementDescription:
      "liste des rappels actuellement en cours avec la possibilité de les clôturer si ils ne sont plus d’actualités.",

    changeStatus: "changer le statut",
    signinSubtitle: "gérez simplement vos contrats !",
    numberAlertByAge: "nombre de rappels non résolus par âge",
    personnWhoValidate: "Qui valide les rappels dans mon équipe ?",
    historyValidateAlert: "Liste des rappels validés (historique)",
    numberAlertMonth: "evolution par mois du nombre de rappels identifiés",
    contractsInDraft: "contrats en pour revue",
    editWidgets: "edition des widgets",
    save: "sauvegarder",
    addWidgets: "ajouter des widgets",
    addWidgetsOk: "le widget a bien été ajouté ",
    moveWidgets: "déplacer le widget",
    deleteWidgets: "supprimer le widget",
    mobilePhone: "téléphone mobile",
    noMobilePhone: "vous n'avez pas renseigné de numéro de téléphone",
    slackChannel: "canal slack",
    slackId: "token slack",
    natureOfAlert: "nature du rappel",
    alertMeBefore: "me rappeler avant",
    signInSubtitle: "connectez vous à votre compte",
    through: "par",
    notifications: "notifications",
    noNotifications: "vous n'avez aucun message",
    noAlertSubscribe: "vous n'avez souscrit à aucun type de rappels",
    noAlertSubscribeDesc:
      "malheureusement vous n'avez aucun rappel configuré dans Oblige. Ne vous faites pas surprendre par une échéance et prenez quelques minutes pour paramétrer vos rappels automatiques !",
    goToMyRappel: "aller à mes rappels",
    selectTenantPlaceholder: "taper 3 caractères pour démarrer la recherche",

    displayName: "nom d'affichage",
    yourInformation: "mes informations",
    communicationMethod: "comment me joindre ?",
    dateEvent: "date de l'événement",
    actionCarriedOutBy: "action réalisée par",
    contractDetection: "lancement d'une détection",
    contractStore: "ajout du contrat",
    documentAdd: "ajout d'un annexe ou avenant",
    contractTreatmentInProgress: "en cours de traitement",
    null: "indéterminé",
    nature: "nature",
    naturecontractDetection: "détection",
    naturecontractStore: "contrat",
    naturedocumentAdd: "document",
    naturecontractWorkflow: "workflow",
    naturecontractManualDirectValue: "caractéristiques",
    naturecontractManualRemoveValue: "caractéristiques",
    naturecontractManualNoValue: "caractéristiques",
    naturecontractManualMentionValue: "caractéristiques",
    naturecontractManualForceSkeleton: "contrat",
    contractNatureUnknow: "la nature du contrat n'a pas pu être déterminée",
    undefined: "indeterminé",
    other: "autre",
    _other: "autre",
    version: "version",
    number: "nombres",
    money: "montant",
    naturecontractTicket: "création du ticket nature",
    contractTicket: "contrat ticket",
    contractTicketRegistration: "ticket enregistrer",
    comment: "notes",
    addComment: "ajouter une note",
    suppComment: "supprimer la note",
    confirmComment: "attention, vous êtes sur le point de supprimer la note",
    contractAutoExpired: "expiré automatiquement",
    confirmSuppWidget:
      "attention, vous êtes sur le point de supprimer le widget",
    inNameContract: "chercher dans le nom du contrat",
    myContratheque: "ma contrathèque",
    naturecontractManualRemoveSkeleton: "changement nature contrat",
    contractManualRemoveSkeleton: "changement manuel de la nature du contrat",
    natureType: "type de nature",
    naturecontractShare: "partage du contrat",
    contractShare: "un partage du contrat à été effectué",
    noContractsYet: "Aucun contrat",
    allWidgetsUsed: "tous les widgets sont déjà utilisés",
    myDataRoom: "ma data room",
    ANALYSE: "analyse",
    AnalyseInProgress: "en cours d'analyse",
    AnalyseInProgressText:
      "Votre contrat est en cours d’analyse par nos experts pour s’assurer que toutes les informations qu’il contient sont parfaitement vérifiées. </br>Cela peut prendre jusque 24h mais nous faisons nos meilleurs efforts pour contenir ce délai au maximum.</br>Vous recevrez un email pour vous prévenir dès qu'il sera disponible pour votre revue !",
    birthdayBail: "anniversaire signature du bail",
    renewal_pending: "reconduction",
    edit: "editer",
    ifValueIncorrect: "si la valeur {value} est incorrecte : ",
    FRAMEWORK_AGREEMENT: "cadre",
    APPLICATION_AGREEMENT: "application",
    longFRAMEWORK_AGREEMENT: "contrat cadre",
    longAPPLICATION_AGREEMENT: "contrats d'application",
    createTag: "cliquer sur entrer pour créer",
    tagManagement: "gestion des tags",
    allMyContracts: "tous mes contrats",
    originalQuery: "recherche originale",
    keywordSearch: "recherche mot clef",
    contractPendingAnalysisOverview: "contrat en attente d'analyse",
    viewContract: "voir le contrat",
    notAccessTenant: "vous n'avez pas accès a ce compte / classeur",
    statusFilter: "filtrer par statut",

    //MoveContract
    documentMultipleContractsDetail:
      "le document contient plusieurs contrats, séparation du document nécessaire",
    documentLanguageNotManagedDetail:
      "le document / contrat est dans une langue non prise en charge par le système",
    documentNatureNotManagedDetail:
      "le document n’est pas un contrat(autre document n’ayant aucun rapport)",
    documentNotContractDetail:
      "le document est un avenant, celui-ci doit être attaché à une contrat existant dans Oblige",
    documentFormatNotManagedDetail:
      "le document n'est pas un contrat (document n'ayant aucun rapport avec le juridique)",

    //MoveContract
    documentMultipleContractsOverview:
      "le document contient plusieurs contrats, séparation du document nécessaire",
    documentLanguageNotManagedOverview:
      "le document / contrat est dans une langue non prise en charge par le système",
    documentNatureNotManagedOverview:
      "le document n’est pas un contrat(autre document n’ayant aucun rapport)",
    documentNotContractOverview:
      "le document est un avenant, celui-ci doit être attaché à une contrat existant dans Oblige",
    documentFormatNotManagedOverview:
      "le document n'est pas un contrat (document n'ayant aucun rapport avec le juridique)",

    //faq
    theme: "thème",
    general: "générale",
    recall: "rappel",
    widget: "widget",
    profile: "profil",
    contract: "contrat",
    billing: "Factures et paiements",
    faq: "faq",

    //audit
    auditContract: "audit contrat",
    audit: "audit",
    consultAudits: "consulter les audits",
    contractWorkflow: "Passage de {0} à {1}",
    contractManualDirectValue: "modification {0} ",
    contractManualRemoveValue: "suppression {0} ",
    contractManualMentionValue: "changement {0}",
    contractManualNoValue: "retrait {0}",
    contractManualForceSkeleton: "modification de la nature en {0}",
    contractRenewalApply: "reconduction du contrat",
    naturecontractRenewalApply: "reconduction",
    naturecontractRenewalPending: "reconduction",
    contractRenewalPending:
      "Ce contrat semble être expiré et sans reconduction tacite",
    naturecontractEditTag: "tags",
    contractEditTag: "modification des tags du contrat",
    naturecontractAutoExpired: "expiration",
    contractRename: "contrat renommé",
    naturecontractRename: "contrat",

    // contrat bail
    dateSignatureBail: "date de signature",
    natureBail: "nature du bail",
    dureeBail: "durée du bail",
    preavisBail: "durée du préavis",
    dateEffetBail: "date d'effet du bail",
    preneur: "preneur",
    dateExpirationBail: "terme du bail",
    bailleur: "bailleur",
    periodeTermeBail: "fréquence de terme du bail",
    expiryBail: "terme du bail",
    Bail: "bail",
    birthdayBail: "anniversaire signature du bail",
    priorNoticeBail: "préavis du bail",
    contratBail: "baux",
    priseEffetBail: "prise effet bail",
    dureeContratBail: "durée contrat",
    represantantBailleur: "représentant bailleur",
    representantPreneur: "représentant preneur",
    preavisResiliationBail: "préavis résiliation",
    periodeBail: "période bail",
    adresseBienBail: "adresse du bien",
    surfaceBienBail: "surface du bien",
    droitApplicableBail: "droit applicable",
    droitFr: "droit français",
    juridictionBail: "juridiction",
    montantLoyerBienBail: "montant du loyer du bien",
    revisionLoyerBienBail: "révision loyer bien bail",
    travauxChargesBienBail: "charges des travaux du bien",
    depotGarantieBienBail: "dépot garantie bien bail",
    representantBailleur: "representant bailleur",
    bailProfessionnel: "bail professionnelx²",

    // contrat de travail
    contratTravail: "Contrat travail",
    birthdayTravail: "anniversaire de travail",
    finPeriodRenouvellementTravail: "fin période de renouvellement",
    dureeTravail: "durée de travail",
    lieuTravail: "lieu de travail",
    coefficientTravail: "coéfficient",
    finPeriodeEssaiTravail: "fin de période d'essai de travail",
    statutEmployeTravail: "statut employé",
    indemnitePrecariteTravail: "indémnite précarité",
    indemniteCongesPayesTravail: "indémnite congés payés",
    remunerationVariableTravail: "rémunération variable",
    employeurTravail: "employeur",
    remunerationMensuelleTravail: "rémunération mensuelle",
    remunerationAnnuelleTravail: "rémunération annuelle",
    dureeCongesPayesTravail: "durée congés payés",
    datePrisePosteTravail: "date de la prise de poste",
    typeContratTravail: "type de contrat de travail",
    dateSignatureTravail: "date de signature",
    dureeRenouvellementEssaiTravail: "durée du renouvellement de l'essai",
    dureePeriodeEssaiTravail: "durée de la période d'essai",
    dateExpirationTravail: "date d'éxpiration",
    priseEffetTravail: "prise d'éffet",
    salarieTravail: "salarié",
    fonctionSalarie: "fonction salarié",
    intitulePosteTravail: "intitulé du poste",

    dday: "j",
    showDeadlineHistory: "masquer les échéances passées",

    //prestation de service
    prestationService: "prestation de service",

    //bail emphytéotique
    emphyteuticLease: "bail emphytéotique",

    welcomeSite: "bienvenue sur",

    // move contract
    moveTheContract: "déplacer ce contrat",
    tooltipsMoveTheContract:
      "déplacer ce contrat dans un autre compte / classeur",
    choiceTenantBu: "Sélectionner l'espace cible du contrat",
    contractStatusUpdated: "Le statut du contrat a été mis à jour",

    // add reminber and notified
    typeEvent:
      "choisissez la nature de contrat et le type d'évènement sur lequel vous souhaitez positionner un rappel",
    typeEvent1: "toutes les échéances",
    typeEvent2: "terme du contrat",
    typeEvent3: "date limite de fin de projet / livraison",
    typeEvent4: "date de fin de période d'essai",
    typeEvent5: "date anniversaire de signature de contrat",
    typeEvent6: "date limite de demande de versement / paiement",
    typeEvent7: "préavis de résiliation en cas de renouvellement",
    typeEvent8: "date anniversaire de prise d'effet de contrat",
    reminMeBefore: "me rappeler avant (jours)",
    reviveMe: "me relancer tous les (jours)",
    sendInvitation: "m'envoyer une invitation",
    notifyByMail: "m'avertir par mail",
    notifyBySms:
      "m'avertir par SMS (un numéro de mobile doit être renseigner dans votre profil)",
    successAddAlert: "le rappel a bien été créé"
  }
};
