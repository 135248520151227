import axios from "axios";

let urlApiOblige = process.env.VUE_APP_URL_HORUS;

export default class oblige {
  async upload_document_dropbox(apiKey, url) {
    let FormData = require("form-data");
    let data = new FormData();

    if (url != undefined) {
      data.append("url", url);
    } else {
      data = url;
    }

    let request = await axios.post(
      urlApiOblige + "oblige/contract/store/dropbox",
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async new_contract(apiKey, form) {
    let request = await axios.post(
      urlApiOblige + "oblige/contract/store/dummy",
      form,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async list_contract_nature(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/natures", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async countDocuments(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/count", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async getDocuments(apiKey, size, page, query) {
    let FormData = require("form-data");
    let data = new FormData();

    if (query != undefined) {
      data.append("query", query);
    } else data = query;

    let request = await axios.post(
      urlApiOblige + "oblige/contract?size=" + size + "&page=" + page,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async listOfContractsFollowed(apiKey, size, page) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/search/todo?size=" +
        size +
        "&page=" +
        page,
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async currentDocument(apiKey, contractPath) {
    let request = await axios.get(
      urlApiOblige + "oblige/contract/path?contractPath=" + contractPath,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    if (request != undefined) {
      return request.data;
    } else return "404";
  }

  async downloadDocument(apiKey, contractPath, documentPath) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/contract/document?contractPath=" +
        contractPath +
        "&documentPath=" +
        documentPath,
      {
        headers: {
          "x-tenant": apiKey
        },
        responseType: "blob"
      }
    );
    return request.data;
  }

  async previewDocument(apiKey, contractPath, documentPath) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/contract/preview?contractPath=" +
        contractPath +
        "&documentPath=" +
        documentPath,
      {
        headers: {
          "x-tenant": apiKey
        },
        responseType: "blob"
      }
    );
    return request.data;
  }

  async processContract(contractPath, apiKey) {
    let request = await axios.post(
      urlApiOblige + "oblige/contract/process?contractPath=" + contractPath,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async moveDocument(currentContract, newContract, documentPath, apiKey) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/moveDocument?currentContract=" +
        currentContract +
        "&targetContract=" +
        newContract +
        "&documentPath=" +
        documentPath,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async dataModel(modelKey, apiKey) {
    let request = await axios.get(
      urlApiOblige + "oblige/contract/model?modelKey=" + modelKey + "@ui",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async getDeadline(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/deadlines", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async countContract(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/status", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async contractReason(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/draft", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }
  async tips(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/tips", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async next(apiKey, contractPath) {
    let request = await axios.get(
      urlApiOblige + "oblige/contract/next?contractPath=" + contractPath,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
  async eventCalendar(apiKey, floor, cap) {
    let request = await axios.get(
      urlApiOblige + "oblige/contract/events?floor=" + floor + "&cap=" + cap,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async move_contract_tenant_bu(apiKey, contractPath, tenantBu) {
    let request = await axios.post(
      urlApiOblige + "oblige/contract/tenant?contractPath=" + contractPath,
      tenantBu,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async contract_custom_field(apiKey, nature, familly, property) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/custom/field?nature=" +
        nature +
        "&familly=" +
        familly,
      property,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async workflow(apiKey, contractPath, status) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/workflow?contractPath=" +
        contractPath +
        "&status=" +
        status,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
  async mentions(apiKey, contractPath, characteristicKey, prefixe, suffixe) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/train/mentions?contractPath=" +
        contractPath +
        "&characteristicKey=" +
        characteristicKey +
        "&prefixe=" +
        prefixe +
        "&suffixe=" +
        suffixe,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async mentionsHtml(apiKey, contractPath, characteristicKey) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/train/html?contractPath=" +
        contractPath +
        "&characteristicKey=" +
        characteristicKey,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async train(
    apiKey,
    contractPath,
    characteristicKey,
    localisation,
    directValue,
    forceNoValue
  ) {
    let changeCharacteristic = {};
    changeCharacteristic.contractPath = contractPath;
    changeCharacteristic.characteristicKey = characteristicKey;
    changeCharacteristic.localization = localisation;

    if (directValue == null) {
      forceNoValue
        ? (changeCharacteristic.forceNoValue = true)
        : (changeCharacteristic.forceNoValue = false);
    } else {
      if (typeof directValue == "string") {
        changeCharacteristic.directValue = directValue;
      } else {
        changeCharacteristic.directValue = JSON.stringify(directValue);
      }
    }

    let request = await axios.post(
      urlApiOblige + "oblige/train/train",
      changeCharacteristic,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request;
  }

  async alert(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/alert", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async registerAlert(apiKey, alertTypePath, deadlinePath, alertTriggerDays) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/alert/register?alertTypePath=" +
        alertTypePath +
        "&deadlinePath=" +
        deadlinePath +
        "&alertTrigger=" +
        alertTriggerDays,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
  async unregisterAlert(apiKey, alertTypePath, deadlinePath) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/alert/unregister?alertTypePath=" +
        alertTypePath +
        "&deadlinePath=" +
        deadlinePath,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async ticketsList(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/alert/tickets", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async ticketsByContract(apiKey, contractPath) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/alert/ticketsByContract" +
        "?contractPath=" +
        contractPath,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async search(apiKey, size, page, natural) {
    let FormData = require("form-data");
    let data = new FormData();

    if (natural != undefined) {
      data.append("natural", natural);
    } else data = natural;

    let request = await axios.post(
      urlApiOblige + "oblige/contract/search?size=" + size + "&page=" + page,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async advance_search(apiKey, page, size, query, status, tags) {
    let data = {};
    data.query = query;
    if (status != null) {
      data.status = status;
    }
    if (tags != null) {
      data.tags = tags;
    }
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/search/advance?page=" +
        page +
        "&size=" +
        size,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async overview(apiKey, fileType) {
    let request = await axios.get(urlApiOblige + "oblige/export/" + fileType, {
      headers: {
        "x-tenant": apiKey
      },
      responseType: "blob"
    });
    return request.data;
  }

  async delDoc(apiKey, documentPath, contractPath) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/delete?contractPath=" +
        contractPath +
        "&documentPath=" +
        documentPath,
      "",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async ticketsByAge(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/alert/ticketsByAge", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async ticketsByValidator(apiKey) {
    let request = await axios.get(
      urlApiOblige + "oblige/alert/ticketsByValidator",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async ticketVariations(apiKey) {
    let request = await axios.get(
      urlApiOblige + "oblige/alert/ticketVariations",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
  async closedTicketsByValidator(apiKey) {
    let request = await axios.get(
      urlApiOblige + "oblige/alert/closedTicketsByValidator",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async ticketResolutions(apiKey) {
    let request = await axios.get(
      urlApiOblige + "oblige/alert/ticketResolutions",
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async eventsByMonth(apiKey, deadlineNature, minMonth, maxMonth) {
    let request = await axios.get(
      urlApiOblige +
        "oblige/contract/eventsByMonth?deadlineNature=" +
        deadlineNature +
        "&maxMonth=" +
        maxMonth +
        "&minMonth" +
        minMonth,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async searchOverview(apiKey, query, status, tags) {
    let data = {};
    data.query = query;
    data.withQuery = true;
    if (status != null) {
      data.status = status;
    }
    if (tags != null) {
      data.tags = tags;
    }
    // console.log(data);
    let request = await axios.post(
      urlApiOblige + "oblige/contract/search/overview",
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async allTags(apiKey) {
    let request = await axios.get(urlApiOblige + "oblige/contract/tag", {
      headers: {
        "x-tenant": apiKey
      }
    });
    return request.data;
  }

  async addTags(apiKey, contractPath, tags) {
    let request = await axios.post(
      urlApiOblige + "oblige/contract/tag?contractPath=" + contractPath,
      tags,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async childsAgreement(apiKey, contractPath, page, size) {
    let request = await axios.post(
      urlApiOblige +
        "oblige/contract/search/childs?contractPath=" +
        contractPath +
        "&page=" +
        page +
        "&size=" +
        size,
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
}
