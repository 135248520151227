<template>
  <v-card>
    <v-card-text>
      <v-row dense align="center">
        <v-col cols="4">
          <v-select
            v-model="grantSelected"
            :items="grantList"
            label="Sélectionner un profil"
            item-text="profilLabel"
            return-object
            hide-details
            :loading="loadList"
            dense
            outlined
          >
            <template v-slot:selection="{ item }">
              <span class="primary--text subtitle-2">{{
                item.profilLabel
              }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col class="d-flex align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-2"
                @click="open_dialog_set_grant('edit')"
                v-bind="attrs"
                v-on="on"
                :disabled="grantSelected && !grantSelected.custom"
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier le profil sélectionné</span>
          </v-tooltip>

          <v-btn color="primary" @click="open_dialog_set_grant('add')">
            <v-icon small left>mdi-plus</v-icon>
            Ajouter un nouveau profil
          </v-btn>
        </v-col>

        <v-col class="d-flex align-center">
          <v-text-field
            v-model="searchTxt"
            label="Rechercher"
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-alert
            type="warning"
            text
            dense
            class="body-2 ma-0"
            v-if="grantSelected && !grantSelected.custom"
          >
            Le profil {{ grantSelected.profilLabel }} n'est pas modifiable
          </v-alert>
          <v-alert v-else type="info" text dense class="body-2 ma-0">
            Glisser et déposer les cartes à l'aide de votre souris
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <Dialog-set-grant
      v-if="dialogSetGrant"
      :showDialog.sync="dialogSetGrant"
      :isEdit="dialogSetGrantModeEdit"
      :editData="grantSelected"
      @submited="get_grant_list"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";
import DialogSetGrant from "./DialogSetGrant.vue";

export default {
  components: { DialogSetGrant },
  name: "GrantHeader",
  computed: {
    ...mapGetters(["apiKey", "currentBu"]),
    filteredNatures() {
      return this.grantList.filter(e => {
        return (
          e.moduleLabel
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .indexOf(
              this.searchTxt
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) > -1
        );
      });
    }
  },
  data() {
    return {
      ClassTenant: null,
      isCreated: false,
      loadList: false,
      grantList: [],
      grantSelected: null,

      dialogSetGrant: false,
      dialogSetGrantModeEdit: false,
      // Search
      searchTxt: null
    };
  },

  created() {
    if (this.ClassTenant == null) {
      this.ClassTenant = new Tenant();
    }
    this.isCreated = true;
    this.get_grant_list();
  },
  watch: {
    grantSelected(newValue) {
      this.$emit("grantSelected", newValue);
    },
    searchTxt(newVal) {
      this.$emit("searchModules", newVal);
    }
  },
  methods: {
    async get_grant_list() {
      try {
        this.loadList = true;
        let response = await this.ClassTenant.user_status(this.apiKey);
        this.grantList = response;
        this.grantSelected = response[0];
      } catch (error) {
        console.error(error);
      } finally {
        if (this.isCreated != false) {
          this.isCreated = false;
        }
        this.loadList = false;
      }
    },

    open_dialog_set_grant(context) {
      if (context == "add") {
        this.dialogSetGrantModeEdit = false;
        this.dialogSetGrant = true;
      } else if (context == "edit") {
        this.dialogSetGrantModeEdit = true;
        this.dialogSetGrant = true;
      }
    }
  }
};
</script>
