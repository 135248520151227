<template>
  <v-card
    height="100%"
    min-height="120"
    :color="
      service.backColor != null && service.backColor != ''
        ? service.backColor
        : 'secondary'
    "
    class="d-flex flex-column justify-space-between"
  >
    <v-card-title
      class="pa-1 subtitle-1"
      :style="service.textColor ? 'color:' + service.textColor : 'color:black'"
    >
      {{ service.label }}
    </v-card-title>

    <v-card-actions>
      <v-tooltip bottom v-if="!isCurrentService">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :color="service.textColor ? service.textColor : 'black'"
            @click="open_delete_dialog"
            fab
            icon
            x-small
          >
            <v-icon>mdi-delete-empty</v-icon>
          </v-btn>
        </template>
        <span>Supprimer le classeur</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :color="service.textColor ? service.textColor : 'black'"
            @click="open_edit_dialog"
            fab
            icon
            x-small
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Modifier le classeur</span>
      </v-tooltip>
    </v-card-actions>

    <Dialog-set-service
      v-if="dialogSetService"
      :showDialog.sync="dialogSetService"
      :editData="service"
      isEdit
      @submited="service_updated"
    />

    <Dialog-delete-service
      v-if="dialogDeleteService"
      :showDialog.sync="dialogDeleteService"
      :service="service"
      @submited="service_updated"
    />
  </v-card>
</template>

<script>
import DialogDeleteService from "./DialogDeleteService.vue";
import DialogSetService from "./DialogSetService.vue";

export default {
  name: "CardService",
  components: {
    DialogSetService,
    DialogDeleteService
  },
  props: {
    service: {
      type: Object,
      require: true,
      default: null
    },
    isCurrentService: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      dialogSetService: false,
      dialogDeleteService: false
    };
  },

  methods: {
    service_updated() {
      this.$emit("updated");
    },

    open_edit_dialog() {
      this.dialogSetService = true;
    },
    open_delete_dialog() {
      this.dialogDeleteService = true;
    }
  }
};
</script>
