<template>
  <div></div>
</template>

<script>
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { mapGetters } from "vuex";

export default {
  name: "websocket",
  computed: {
    ...mapGetters(["currentBu", "contractDetectionEnd"])
  },
  data() {
    return {
      connected: false,
      idSub: "",
      headers: null
    };
  },
  mounted() {
    this.headers = { "x-app": process.env.VUE_APP_APPLICATION };
    this.connect();
  },
  watch: {
    currentBu() {
      this.change();
    }
  },
  destroyed() {
    this.disconnect();
  },
  methods: {
    connect() {
      this.socket = new SockJS(
        process.env.VUE_APP_URL_HORUS + "setting/registration"
      );
      this.stompClient = Stomp.over(
        this.socket,
        { debug: false },
        { timeout: 15000 }
      );
      this.stompClient.connect(
        this.headers,
        () => {
          this.connected = true;
          this.tenantConnect(this.currentBu.tenant.apiKey);
        },
        error => {
          console.error(error);
          this.connected = false;
        }
      );
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },

    async change() {
      await this.supptenant();
      await this.tenantConnect();
    },

    supptenant() {
      this.stompClient.unsubscribe(this.idSub);
    },

    tenantConnect() {
      // supprime le SP- de l'apiKey quand ont ai en mode support
      let apiKey = this.currentBu.tenant.apiKey.replace("SP-", "");

      let subscribe = this.stompClient.subscribe(
        "/event/" + process.env.VUE_APP_APPLICATION + "/" + apiKey,
        tick => {
          let msg = JSON.parse(tick.body);
          this.actionMsg(msg.payload);
        },
        this.headers
      );
      this.idSub = subscribe.id;
    },
    async actionMsg(msg) {
      // console.log("websocket", msg);
      if (msg.eventKey == "contractDetectionEnd") {
        await this.$store.commit("update_contractDetectionEnd", msg);
      }
    }
  }
};
</script>

<style scoped></style>
