import axios from "axios";

export default class ClearCache {
  async build_version() {
    const request = await axios.get("meta.json");
    if (request) {
      return request.data.buildDate;
    } else return request;
  }
}
