<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-form
      v-model="validForm"
      ref="formSetService"
      @submit.prevent="submit_form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title primary--text">
          <span v-if="isEdit">Modifier les informations du classeur</span>
          <span v-if="!isEdit">Ajouter un nouveau classeur</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.name"
                label="Nom du classeur"
                placeholder="Ex : Chartres centre"
                :rules="[rules.required, rules.maxLength(64)]"
                :disabled="loadForm"
                outlined
                dense
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="form.mnemo"
                label="Trigramme"
                placeholder="Ex : MCC"
                :rules="[rules.maxLength(3)]"
                maxlength="3"
                hint="Champ optionnel"
                persistent-hint
                :disabled="loadForm"
                counter
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <Select-color-pallet
                :colorSelected.sync="form.backColor"
                label="Couleur personnalisée (optionnel)"
                grow
                dense
              />
            </v-col>
            <v-col cols="12" md="5" offset-md="1">
              <v-checkbox
                v-model="form.asFavorite"
                label="Ajouter dans la liste mes favoris"
                class="ma-0 pt-1"
                inset
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            @click="cancel_dialog"
            :disabled="loadForm"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            type="submit"
            :loading="loadForm"
            :disabled="!validForm"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectColorPallet from "@/components/common/SelectColorPalet.vue";
import Tenant from "@/class/tenant.js";

export default {
  components: { SelectColorPallet },
  name: "DialogSetService",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    isEdit: {
      type: Boolean,
      require: true,
      default: false
    },
    editData: {
      type: Object,
      require: false,
      default: null
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey", "labelCurrent"])
  },
  data() {
    return {
      ClassTenant: null,
      validForm: false,
      loadForm: false,
      editCurrentLabel: false,
      form: {
        tenantKey: null,
        businessUnitKey: null, // for edit.
        type: null,
        name: null,
        mnemo: null,
        backColor: null,
        asFavorite: true // ajouter dans mes favoris
      },
      rules: {
        required: v => !!v || "Ce champ est requis.",
        maxLength: len => v =>
          (v || "").length <= len || `${len} caractères maximum`
      }
    };
  },

  created() {
    this.ClassTenant = new Tenant();
    if (this.isEdit) {
      this.assign_edit_data();
    }
  },
  methods: {
    // Edit
    assign_edit_data() {
      if (this.labelCurrent == this.editData.label) {
        this.editCurrentLabel = true;
      }

      this.form.tenantKey = this.editData.tenantKey;
      this.form.businessUnitKey = this.editData.businessUnitKey;
      this.form.type = this.editData.type;
      this.form.name = this.editData.label;
      this.form.mnemo = this.editData.mnemo;
      this.form.backColor = this.editData.backColor;
    },

    // Submit
    submit_form() {
      if (this.$refs.formSetService.validate()) {
        if (this.isEdit) {
          this.edit_service();
        } else {
          this.add_service();
        }
      } else {
        this.$refs.formSetService.validate();
      }
    },
    async add_service() {
      try {
        this.loadForm = true;
        await this.ClassTenant.create_service(
          this.apiKey,
          this.form.name,
          this.form
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.$emit("submited");
        this.cancel_dialog();
      }
    },

    async edit_service() {
      try {
        this.loadForm = true;
        await this.ClassTenant.entity_rename(this.apiKey, this.form);

        if (this.editCurrentLabel) {
          this.$store.commit("update_labelCurrent", this.form.name);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = true;
        this.$emit("submited");
        this.cancel_dialog();
      }
    },

    // Dialog
    cancel_dialog() {
      this.editCurrentLabel = false;
      this.openDialog = false;
    }
  }
};
</script>
