<template>
  <div>
    <v-card>
      <v-card-title class="d-flex align-center title primary--text">
        Compte
        <span class="ml-1 title primary--text">
          <span v-if="labelTenant"> {{ labelTenant }} </span>
          <span v-else>
            {{ labelCurrent }}
          </span>
        </span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="ml-1"
              x-small
              fab
              icon
              @click="open_dialog_edit_organization()"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Renommer le compte</span>
        </v-tooltip>
        <!-- <v-spacer></v-spacer>
        <v-btn color="primary" class="mt-1 ml-1" @click="open_create_service">
          <v-icon left>mdi-plus</v-icon>
          {{ $t("oblige.createTenant") | capitalize }}
        </v-btn> -->
      </v-card-title>

      <v-card-subtitle>
        L'accès à un compte donne accès aux classeurs qu'il contient
      </v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="2" class="pa-1">
            <v-card
              color="primary white--text"
              @click="dialogCreateService = true"
              class="pa-2 d-flex flex-column justify-center align-center"
              min-height="120"
              height="100%"
            >
              <v-icon large color="white">mdi-plus</v-icon>
              <div class="text-center mt-1 body-1">
                Ajouter un Classeur <br />
              </div>
            </v-card>
          </v-col>
          <v-col cols="2" v-for="(service, i) of listServices" :key="i">
            <Card-service
              :service="service"
              :isCurrentService="
                currentBu.tenant.businessUnitKey == service.businessUnitKey
              "
              @updated="service_updated"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Dialog-edit-organization
      v-if="dialogEditOrganization"
      :showDialog.sync="dialogEditOrganization"
      :tenantInfo="tenantInfo"
    />

    <Dialog-set-service
      v-if="dialogCreateService"
      :showDialog.sync="dialogCreateService"
      @submited="service_updated"
    />
    <!-- <DialogSetTenant
      v-if="dialogCreateTenant"
      :show-dialog.sync="dialogCreateTenant"
      @submited="open_choice_tenant"
    /> -->
    <DialogSelectTenant
      v-if="openSelectTenant"
      :showDialog.sync="openSelectTenant"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";

import DialogEditOrganization from "./DialogEditOrganization.vue";
import CardService from "./CardService.vue";
import DialogSetService from "./DialogSetService.vue";
import DialogSelectTenant from "@/components/common/DialogSelectTenant.vue";
import DialogSetTenant from "@/components/userSpaceConfigurations/elements/authorization/manageWorkspaces/DialogSetTenant.vue";

export default {
  name: "TenantInfos",
  components: {
    DialogEditOrganization,
    CardService,
    DialogSetService,
    DialogSelectTenant,
    DialogSetTenant
  },
  computed: {
    ...mapGetters(["currentBu", "labelCurrent", "labelTenant"])
  },
  data() {
    return {
      ClassTenant: null,
      dialogEditOrganization: false,
      tenantInfo: null, // Used in dialogEditOrganization.
      dialogCreateService: false,
      listServices: [],
      dialogCreateService: false,
      openSelectTenant: false
      // dialogCreateTenant: false
    };
  },

  created() {
    this.ClassTenant = new Tenant();
    this.get_overview();
  },
  methods: {
    // Init

    async get_overview() {
      try {
        let response = await this.ClassTenant.authorization_overview(
          this.currentBu.tenant.apiKey
        );
        this.tenantInfo = response.tenant;
        this.listServices = response.units;
      } catch (error) {
        console.error(error);
      }
    },

    // Edit organization

    open_dialog_edit_organization() {
      this.dialogEditOrganization = true;
    },

    // Services

    service_updated() {
      this.$emit("services-updated");
    },

    // open_create_service() {
    //   this.dialogCreateTenant = true;
    // },
    open_choice_tenant() {
      this.openSelectTenant = true;
    }
  }
};
</script>
