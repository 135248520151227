export default [
  {
    path: "/auth/signin",
    name: "auth-signin",
    component: () =>
      import(
        /* webpackChunkName: "auth-signin" */ "@/pages/auth/SigninPage.vue"
      ),
    props: route => ({ query: route.query.expired }),
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/auth/signup",
    name: "auth-signup",
    component: () =>
      import(
        /* webpackChunkName: "auth-signup" */ "@/pages/auth/SignupPage.vue"
      ),
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/auth/forgot-password",
    name: "auth-forgot",
    component: () =>
      import(
        /* webpackChunkName: "auth-forgot" */ "@/pages/auth/ForgotPage.vue"
      ),
    meta: {
      layout: "auth"
    }
  },
  // TODO personaliser la page 404
  {
    path: "/error/not-found",
    name: "error-not-found",
    component: () =>
      import(
        /* webpackChunkName: "error-not-found" */ "@/pages/error/NotFoundPage.vue"
      ),
    meta: {
      layout: "error"
    }
  },
  {
    path: "/error/unexpected",
    name: "error-unexpected",
    component: () =>
      import(
        /* webpackChunkName: "error-unexpected" */ "@/pages/error/UnexpectedPage.vue"
      ),
    meta: {
      layout: "error"
    }
  },
  {
    path: "/utility/maintenance",
    name: "utility-maintenance",
    component: () =>
      import(
        /* webpackChunkName: "utility-maintenance" */ "@/pages/utility/MaintenancePage.vue"
      ),
    meta: {
      layout: "auth"
    }
  }
];
