<template>
  <v-card color="transparent" flat :loading="loadList">
    <template v-slot:progress>
      <v-progress-linear
        color="secondary"
        indeterminate
        rounded
        height="3"
      ></v-progress-linear>
    </template>
    <v-container fluid class="pa-0">
      <v-row dense justify="space-between">
        <v-col
          cols="12"
          md="6"
          v-for="(col, i) of colsData"
          :key="col.label + i"
        >
          <v-card color="bgLvl1" height="100%">
            <v-card-title class="title primary--text">
              {{ col.label }}
            </v-card-title>
            <v-card-text style="height: 98%">
              <Vue-draggable
                v-model="col.data"
                :delay="sortDelay"
                animation="250"
                class="thinscrollbar"
                style="height: 100%"
                group="statusGroup"
                :disabled="selectedGrant.profilPath == 'administrator'"
                v-bind="dragOptions"
                @change="change_column($event, i)"
              >
                <GrantDetail
                  v-for="(el, j) of col.data"
                  :key="el.moduleName + j"
                  :data="el"
                  class="mb-2"
                  :class="editable ? 'grabbable' : 'noDrop'"
                />
              </Vue-draggable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import VueDraggable from "vuedraggable";
import { mapGetters } from "vuex";
import tenant from "@/class/tenant";
import GrantDetail from "./GrantDetail.vue";

export default {
  name: "GrantList",

  components: {
    VueDraggable,
    GrantDetail
  },

  props: {
    selectedGrant: {
      type: Object,
      default: null
    },
    searchModules: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapGetters(["apiKey", "currentBu"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  data() {
    return {
      loadList: false,
      sortDelay: 0,
      serviceSelected: null,
      classTenant: null,
      colsData: [],
      defaultResultCols: [],
      editable: null
    };
  },

  mounted() {
    if (this.classTenant == null) {
      this.classTenant = new tenant();
    }
  },

  watch: {
    selectedGrant(newValue) {
      if (newValue != null) {
        this.init_list_modules();
      }
    },
    searchModules(newVal) {
      this.filter_modules(newVal);
    }
  },

  methods: {
    filter_modules(search) {
      if (search === null || search === "") {
        this.colsData = JSON.parse(JSON.stringify(this.defaultResultCols));
      } else {
        const filter_func = arr => {
          return arr.filter(module => {
            return (
              module.moduleLabel
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  search
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) > -1 ||
              module.moduleDescription
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  search
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) > -1
            );
          });
        };

        const colsData = JSON.parse(JSON.stringify(this.defaultResultCols));

        for (let i = 0; i < colsData.length; i++) {
          this.colsData[i].data = filter_func(colsData[i].data);
        }
      }
    },

    async init_list_modules() {
      await this.fecth_list_modules();
      this.colsData = JSON.parse(JSON.stringify(this.defaultResultCols));
    },

    async fecth_list_modules() {
      try {
        this.loadList = true;
        const response = await this.classTenant.display_grant_overview(
          this.apiKey,
          this.selectedGrant.profilPath
        );
        this.editable = response.editable;
        const cols = [
          {
            label: "Non autorisé",
            data: response.moduleDenieds
          },
          {
            label: "Autorisé",
            data: response.moduleWrites
          }
        ];

        this.defaultResultCols = JSON.parse(JSON.stringify(cols));
      } catch (error) {
        console.error(error);
      } finally {
        this.loadList = false;
      }
    },
    change_column(evt, indexColumn) {
      if (evt.added) {
        // Event: when dropped in a new column.
        this.update_opp_status(evt.added.element, indexColumn);
      } else if (evt.moved) {
        // Event: sort in column.
      }
      // Others events are available.
    },
    async update_opp_status(status, indexColumn) {
      try {
        let level = "";
        switch (indexColumn) {
          case 0:
            level = "DENIED";
            break;
          case 1:
            level = "ACCESSIBLE";
            break;
        }
        let data = {};
        data.level = level;
        data.moduleName = status.moduleName;
        await this.classTenant.edit_grant_level(
          this.apiKey,
          this.selectedGrant.profilPath,
          data
        );
        await this.fecth_list_modules();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
.noDrop {
  cursor: no-drop;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing !important;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
