<template>
  <v-dialog
    v-model="supportInfoModals"
    width="900"
    max-width="900"
    @keydown.esc="close_modals"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="type == 'btn'"
        small
        color="primary"
        class="elevation-0"
        max-width="200"
        v-bind="attrs"
        v-on="on"
        @click="get_support_info"
      >
        {{ $t("support.codeSupport") | capitalize }}
      </v-btn>
      <span
        v-if="type == 'lien' || type == 'menu'"
        v-bind="attrs"
        @click="get_support_info"
        v-on="on"
        :class="type == 'menu' ? '' : 'text-decoration-underline'"
      >
        {{ $t("support.codeSupport") | capitalize }}
      </span>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("support.codeSupport") | capitalize }}
        <v-spacer></v-spacer>
        <span class="primary--text caption"
          >{{ $t("oblige.version") | capitalize }} {{ version }}</span
        >
      </v-card-title>
      <v-card-subtitle>
        <span v-if="type == 'lien' || type == 'menu'" class="subtitle-1">{{
          tenantName
        }}</span>
      </v-card-subtitle>

      <v-card-text>
        <v-row dense v-if="type == 'lien' || type == 'menu'">
          <v-col cols="12">
            <v-alert type="info" text dense class="ma-0 body-2">
              Le code support permet au support de se connecter à votre espace (compte
              ou classeur), il vous sera demandé en cas de besoin.
            </v-alert>
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <span class="subtitle-1">Code support</span>
          </v-col>

          <v-col cols="12" class="d-flex justify-center pl-8">
            <span
              class="text-h3 primary--text px-2 py-1"
              style="font-weight:bold; border:solid 1px black; border-radius:5px"
              >{{ supportCode }}</span
            >

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="codeSupport"
                  class="ml-1"
                >
                  <v-icon size="32" color="primary" style="cursor:pointer"
                    >mdi-reload</v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                $t("support.regenerateCodeSupport") | capitalize
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close_modals" text>
          {{ $t("oblige.close") | capitalize }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from "@/class/auth";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentBu", "version", "labelCurrent"])
  },

  props: {
    locale: String,
    type: String,
    click: Boolean
  },

  data() {
    return {
      supportInfoModals: false,
      tenantName: "",
      supportCode: "--- --- ---"
    };
  },

  watch: {
    click(newValue) {
      if (newValue) {
        this.get_support_info();
        this.$emit("clickFalse");
      }
    }
  },
  methods: {
    async get_support_info() {
      try {
        let info = null;
        this.authService = new auth();
        if (this.currentBu != null) {
          info = await this.authService.infoSupportTenant(
            this.locale,
            this.currentBu.tenant.apiKey
          );
        } else {
          info = await this.authService.infoSupport(this.locale);
        }
        if (info.tenantName) {
          this.tenantName = info.tenantName;
        }
        if (info.supportCode) {
          this.formatCode(info.supportCode);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.supportInfoModals = true;
      }
    },

    async formatCode(code) {
      this.supportCode =
        code.substring(0, 3) +
        " " +
        code.substring(3, 6) +
        " " +
        code.substring(6, code.length);
    },

    async codeSupport() {
      try {
        this.authService = new auth();
        let supportCode = await this.authService.codeSupport(
          this.currentBu.tenant.apiKey
        );
        await this.formatCode(supportCode.toString());
      } catch (error) {
        console.error(error);
      }
    },

    close_modals() {
      this.$emit("onOffClickOut");
      this.supportInfoModals = false;
    }
  }
};
</script>
