<template>
  <v-dialog v-model="openDialog" width="600" persistent>
    <v-card>
      <v-card-title>
        {{ $t("oblige.chooseWorkspace") | capitalize }}
      </v-card-title>
      <v-card-subtitle>
        Vous pouvez ajouter des classeurs dans votre compte sur la page "gérer
        mon espace"
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <SelectTenantLogin
          v-if="selectTenantDialog"
          @closeDialog="openDialog = false"
        />
      </v-card-text>
      <v-card-actions class="d-flex align-end justify-end">
        <v-btn
          min-width="100"
          text
          color="primary"
          outlined
          @click="openDialog = false"
          >{{ $t("oblige.close") | capitalize }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogSelectTenant",

  components: {
    SelectTenantLogin: () => import("@/components/common/SelectTenantLogin")
  },
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    }
  },
  data() {
    return {
      selectTenantDialog: true
    };
  }
};
</script>

<style lang="scss" scoped></style>
