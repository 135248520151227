<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";
import Billing from "@/class/billing.js";

export default {
  name: "ZendeskInit",

  computed: {
    ...mapState(["currentBu"])
  },

  data() {
    return {
      ClassBilling: null,
      subscriberSrc: null // save the old type to compare with the new. (zendesk).
    };
  },

  watch: {
    currentBu(newVal) {
      if (newVal && newVal.tenant && newVal.tenant.apiKey) {
        this.zendesk_init();
      }
    }
  },

  mounted() {
    this.zendesk_init();
  },

  methods: {
    async zendesk_init() {
      const oldSubscriberSrc = JSON.parse(JSON.stringify(this.subscriberSrc));

      if (this.ClassBilling == null) {
        this.ClassBilling = new Billing();
      }

      const resBilling = await this.ClassBilling.billingInfo(
        this.currentBu.tenant.apiKey
      );

      this.subscriberSrc = resBilling.zenDesk.zenDeskScriptSrc;
      const token = resBilling.zenDesk.token;

      if (this.subscriberSrc != null && token != null) {
        if (
          oldSubscriberSrc == null ||
          oldSubscriberSrc != this.subscriberSrc
        ) {
          let scriptZendesk =
            document.getElementById("ze-snippet") ||
            document.createElement("script");

          scriptZendesk.src = this.subscriberSrc;

          scriptZendesk.id = "ze-snippet";
          document.getElementsByTagName("head")[0].appendChild(scriptZendesk);
        }

        if (
          oldSubscriberSrc != null &&
          oldSubscriberSrc != this.subscriberSrc
        ) {
          window.location.reload(); // if zendesk script change ("PRENIUM" or "CLASSIC"), reload page.
        }

        // Wait for zendesk to be properly mounted before using its functions.

        setTimeout(() => {
          this.connect_zendesk(token);
        }, 2000);
      } else {
        // Esle : Access chat NONE
        let elZendesk = document.getElementById("ze-snippet");
        if (elZendesk) {
          elZendesk.remove();
          window.location.reload();
        }
      }
    },

    async connect_zendesk(token) {
      zE("messenger", "close");

      await zE("messenger", "loginUser", function(callback) {
        callback(token);
      });

      // if (process.env.NODE_ENV != "production") {
      //   zE("messenger:set", "zIndex", 99999);
      // } else {
      //   zE("messenger:set", "zIndex", -100);
      // }
    }
  }
};
</script>
