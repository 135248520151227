import axios from "axios";

let urlApi = process.env.VUE_APP_URL_HORUS;

export default class setting {
  async actionsGet(apiKey, key, env) {
    let request = await axios.get(
      urlApi + "setting/user?key=" + key + "&env=" + env,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async actionsPost(apiKey, key, data, env) {
    let request = await axios.post(
      urlApi + "setting/user?key=" + key + "&env=" + env,
      data,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async broadcastAck(apiKey, documentId, ackType) {
    let request = await axios.post(
      urlApi +
        "setting/broadcast/ack?documentId=" +
        documentId +
        "&ackType=" +
        ackType,
      {},
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }

  async set_broadcast_message(apiKey, form) {
    let request = await axios.post(
      urlApi + "setting/broadcast/store",
      form,
      {
        headers: {
          "x-tenant": apiKey
        }
      }
    );
    return request.data;
  }
}
