<template>
  <v-dialog v-model="openDialog" max-width="800">
    <v-card>
      <v-card-title class="title primary--text">
        Confirmez la suppression de l'accès
      </v-card-title>
      <v-card-text class="mt-2">
        <v-alert type="warning" text dense class="body-2 ma-0">
          En cliquant sur valider vous confirmez vouloir révoquer les accès de
          l'utilisateur <strong>{{ userSelected.displayName }}</strong> sur
          <span
            v-if="
              serviceSelected &&
                serviceSelected.tenant &&
                serviceSelected.tenant.businessUnitKey == undefined
            "
            >le compte
            <strong>{{ serviceSelected.tenant.label }} </strong></span
          >
          <span v-else
            >le classeur <strong>{{ serviceSelected.tenant.label }}</strong>
          </span>
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn
          color="primary"
          text
          min-width="100"
          @click="cancel_dialog"
          :disabled="loadForm"
          >Annuler</v-btn
        >
        <v-btn
          color="primary"
          min-width="100"
          @click="revoke_access_service_for_user"
          :loading="loadForm"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tenant from "@/class/tenant.js";
import { mapGetters } from "vuex";

export default {
  name: "DialogRevokeUserAuthorization",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    serviceSelected: {
      type: Object,
      default: null,
      require: true
    },
    userSelected: {
      type: Object,
      default: null,
      require: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey"])
  },
  data() {
    return {
      ClassTenant: null,
      loadForm: false
    };
  },

  created() {
    this.ClassTenant = new Tenant();
  },
  methods: {
    async revoke_access_service_for_user() {
      try {
        this.loadForm = true;
        let data = {
          type: this.serviceSelected.tenant.type,
          tenantKey: this.serviceSelected.tenant.tenantKey,
          businessUnitKey: this.serviceSelected.tenant.businessUnitKey
            ? this.serviceSelected.tenant.businessUnitKey
            : null
        };

        await this.ClassTenant.ungrant(
          this.apiKey,
          this.userSelected.userPath,
          data
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("submited"); // this.fetch_users_list();
        this.loadForm = false;
        this.cancel_dialog();
      }
    },

    cancel_dialog() {
      this.$emit("canceled");
      this.openDialog = false;
    }
  }
};
</script>
