<template>
  <div>
    <v-chip
      @click="open_select_tenant"
      label
      color="accent"
      text-color="primary"
      class="mx-1"
      style="height: 36px;"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="text-truncate font-weight-bold"
            style="max-width: 200px"
            v-bind="attrs"
            v-on="on"
          >
            {{ labelCurrent }}
          </div>
        </template>
        <span>{{ $t("oblige.changeWorkspace") | capitalize }}</span>
      </v-tooltip>
    </v-chip>
    <DialogSelectTenant
      v-if="openSelectTenant"
      :showDialog.sync="openSelectTenant"
    />
  </div>
</template>

<script>
import DialogSelectTenant from "./DialogSelectTenant.vue";
export default {
  name: "SelectTenantsBtnLAbel",
  components: {
    DialogSelectTenant
  },
  props: {
    labelCurrent: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      openSelectTenant: false
    };
  },
  methods: {
    open_select_tenant() {
      this.openSelectTenant = true;
    }
  }
};
</script>
