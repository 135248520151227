<template>
  <v-dialog
    v-model="openDialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile class="backgroundPages">
      <Curtain-toolbar label="Gérer mon espace" @clicked="cancel_dialog" />

      <v-container fluid class="px-2 py-0" v-if="openDialog">
        <v-row dense>
          <v-col cols="auto" class="ml-n2 pb-0">
            <Dialog-menu-list @link="open_page" />
          </v-col>

          <v-col
            style="max-height: calc(100vh - 64px);"
            class="overflow-y-auto thinscrollbar py-2 mt-1"
          >
            <PageAuthorizations v-if="pageSelected == 'authorizations'" />
            <PageGrant v-if="pageSelected == 'grant'" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CurtainToolbar from "@/components/common/CurtainToolbar.vue";
import DialogMenuList from "./DialogMenuList.vue";
import PageAuthorizations from "./elements/authorization/PageAuthorizations.vue";
import PageGrant from "./elements/grant/PageGrant.vue";

export default {
  name: "UserSpaceDialog",

  components: {
    CurtainToolbar,
    DialogMenuList,
    PageAuthorizations,
    PageGrant
  },
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    }
  },
  data() {
    return {
      pageSelected: ""
    };
  },

  methods: {
    open_page(link) {
      this.pageSelected = link.key;
    },

    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
