<template>
  <div>
    <v-menu offset-y left transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <span v-if="count != 0">
          <v-badge
            color="accent black--text"
            style="font-weight:bold"
            :content="count"
            offset-x="22"
            offset-y="22"
          >
            <v-btn icon v-on="on">
              <v-icon color="white">mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
        </span>
        <span v-else>
          <v-btn icon v-on="on">
            <v-icon color="white">mdi-bell-outline</v-icon>
          </v-btn>
        </span>
      </template>

      <!-- dropdown card -->
      <v-card width="600">
        <v-card-title class="d-flex justify-space-between py-1 pr-1">
          {{ $t("oblige.notifications") | capitalize }}
          <v-btn small color="primary" @click.stop="dialogSetBroadcast = true"
            ><v-icon small left>mdi-plus</v-icon> Diffuser un message
          </v-btn>
        </v-card-title>

        <v-alert
          type="info"
          text
          dense
          v-if="count == 0"
          class="body-2 mb-0 mt-1 mx-1"
        >
          {{ $t("oblige.noNotifications") | capitalize }}
        </v-alert>

        <v-list subheader three-line class="pa-1">
          <template v-for="(item, index) of tips">
            <div :key="index">
              <UserTipsBroadcast
                v-if="item.nature == 'broadcast'"
                :tip="item"
                @refresh="init()"
              />
              <UserTipsBillingStatus
                v-else-if="item.nature == 'billingStatus'"
                :tip="item"
              />

              <UserTipsMessage v-else :tip="item" />

              <v-divider v-if="index < tips.length - 1"></v-divider>
            </div>
          </template>
        </v-list>
      </v-card>

      <Dialog-set-brodcast-message
        v-if="dialogSetBroadcast"
        :showDialog.sync="dialogSetBroadcast"
        @submited="init"
      />
    </v-menu>

    <div v-if="$route.name == 'dashboard-my-oblige'">
      <span v-for="(tip, index) in tipsHigh" :key="index">
        <DialogTipsHigh :tip="tip" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import oblige from "@/class/oblige";
import DialogSetBrodcastMessage from "./DialogSetBrodcastMessage.vue";

export default {
  name: "UserTips",

  computed: {
    ...mapGetters(["currentBu", "renderComponentUserTips"]),
    count() {
      return this.tips.length;
    }
  },
  components: {
    UserTipsBroadcast: () => import("./tipsElements/UserTipsBroadcast"),
    UserTipsMessage: () => import("./tipsElements/UserTipsMessage"),
    UserTipsBillingStatus: () => import("./tipsElements/UserTipsBillingStatus"),
    DialogSetBrodcastMessage,
    DialogTipsHigh: () => import("./DialogTipsHigh.vue")
  },
  data() {
    return {
      tips: [],
      dialogSetBroadcast: false,
      tipsHigh: []
    };
  },
  watch: {
    currentBu() {
      if (this.currentBu != null) {
        this.init();
      }
    },
    renderComponentUserTips() {
      if (this.renderComponentUserTips == false) {
        this.init();
      }
    }
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.tipsHigh = [];
        this.obligeService = new oblige();
        this.tips = await this.obligeService.tips(this.currentBu.tenant.apiKey);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("update_renderComponentUserTips", true);
        this.is_high_tips();
      }
    },

    is_high_tips() {
      this.tips.forEach(tip => {
        if (tip.priority == "HIGH") {
          this.tipsHigh.push(tip);
        }
      });
    }
  }
};
</script>
