<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";

import ClearCache from "./ClearCache";

export default {
  name: "IsUpdateBuild",

  computed: {
    ...mapGetters(["buildDate"])
  },

  data() {
    return {
      ClassClearCache: null,
      checkBuildDate: null
    };
  },

  created() {
    this.ClassClearCache = new ClearCache();
  },

  mounted() {
    this.verif_buildDate();
    this.checkBuildDate = setInterval(async () => {
      this.verif_buildDate();
    }, 1800000); // toute les 30 minutes
  },

  beforeDestroy() {
    clearTimeout(this.checkBuildDate);
  },

  methods: {
    async verif_buildDate() {
      try {
        // console.log(
        //   "date verif build date",
        //   this.$options.filters.formatDate(new Date().getTime(), "LLL")
        // );
        let metaBuildDate = await this.ClassClearCache.build_version();
        // console.log(
        //   "build date in store",
        //   this.$options.filters.formatDate(this.buildDate, "LLL")
        // );
        // console.log(
        //   "log meta",
        //   this.$options.filters.formatDate(metaBuildDate, "LLL")
        // );

        if (metaBuildDate != undefined) {
          if (this.buildDate == undefined) {
            // console.log("in if undefined");
            this.$store.commit("upd_buildDate", new Date().getTime());
            if (this.buildDate < metaBuildDate) {
              location.reload(true);
            }
          } else if (this.buildDate < metaBuildDate) {
            // console.log("this.buildDate < metaBuildDate");
            this.$store.commit("upd_buildDate", metaBuildDate);
            location.reload(true);
          } else {
            // console.log("on fait rien");
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
