<style scoped>
.blockColor {
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-content: center;
}
.blockColor:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.fakeInput {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  color: rgba(0, 0, 0, 0.6) !important;

  /* corresponds to the colors of the vuetify input outlined */
}
.densePadding {
  padding: 5px 8px;
}
.normalPadding {
  padding: 9px 12px;
}

.blockHint {
  padding: 0 12px;
  margin-bottom: 8px;
  min-height: 14px;
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.hintText {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding: 1px;
}
</style>

<template>
  <div style="width: 100%">
    <v-card
      flat
      outlined
      class="fakeInput d-flex justify-space-between align-center"
      :class="dense ? 'densePadding' : 'normalPadding'"
    >
      <div v-if="label != null" class="mr-2 subtitle-2">{{ label }}</div>
      <v-btn
        @click.stop="dialogPallet = true"
        :color="colorSelected"
        :disabled="disabled"
        :small="dense"
        width="50"
        :loading="loading"
      >
        <v-icon small :color="colorSelected ? 'white' : 'black'"
          >mdi-palette</v-icon
        >
      </v-btn>

      <v-dialog v-model="dialogPallet" max-width="600">
        <v-card>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="1"
                v-for="(Rowcolor, index) of colors"
                :key="index + '-rowClr'"
                class="d-flex flex-column"
              >
                <div
                  class="blockColor"
                  v-for="(color, index) of Rowcolor"
                  :key="index + '-clr'"
                  @click="select_color(color)"
                  :style="'background:' + color"
                  style="widht: 42px; height: 42px; margin: 2px"
                >
                  <v-icon v-if="color == null">mdi-water-off</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card>
    <div v-if="!hideDetails" class="blockHint">
      <span class="hintText">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectColorPallet",
  props: {
    colorSelected: String,
    label: {
      type: String,
      require: false,
      default: null
    },
    hint: {
      type: String,
      require: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select_color(color) {
      this.$emit("update:colorSelected", color);
      this.dialogPallet = false;
      // sur le composant parent :colorSelected.sync="myValue"
    }
  },
  data() {
    return {
      dialogPallet: false,
      colors: [
        [
          null,
          "#607D8B",
          "#CFD8DC",
          "#90A4AE",
          "#546E7A",
          "#37474F",
          "#263238"
        ],
        [
          "#F44336",
          "#FFCDD2",
          "#E57373",
          "#E53935",
          "#C62828",
          "#FF8A80",
          "#FF1744"
        ],
        [
          "#E91E63",
          "#F8BBD0",
          "#F06292",
          "#D81B60",
          "#AD1457",
          "#FF80AB",
          "#F50057"
        ],
        [
          "#9C27B0",
          "#E1BEE7",
          "#BA68C8",
          "#8E24AA",
          "#6A1B9A",
          "#EA80FC",
          "#D500F9"
        ],
        [
          "#673AB7",
          "#D1C4E9",
          "#9575CD",
          "#5E35B1",
          "#4527A0",
          "#B388FF",
          "#651FFF"
        ],
        [
          "#2196F3",
          "#BBDEFB",
          "#64B5F6",
          "#1E88E5",
          "#1565C0",
          "#82B1FF",
          "#2979FF"
        ],
        [
          "#00BCD4",
          "#B2EBF2",
          "#4DD0E1",
          "#00ACC1",
          "#00838F",
          "#84FFFF",
          "#00E5FF"
        ],
        [
          "#009688",
          "#B2DFDB",
          "#4DB6AC",
          "#00897B",
          "#00695C",
          "#A7FFEB",
          "#1DE9B6"
        ],
        [
          "#4CAF50",
          "#C8E6C9",
          "#81C784",
          "#43A047",
          "#2E7D32",
          "#B9F6CA",
          "#00E676"
        ],
        [
          "#CDDC39",
          "#F0F4C3",
          "#DCE775",
          "#C0CA33",
          "#9E9D24",
          "#F4FF81",
          "#C6FF00"
        ],
        [
          "#FFEB3B",
          "#FFF9C4",
          "#FFF176",
          "#FDD835",
          "#F9A825",
          "#FFFF8D",
          "#FFEA00"
        ],
        [
          "#FF9800",
          "#FFE0B2",
          "#FFB74D",
          "#FB8C00",
          "#EF6C00",
          "#FFD180",
          "#FF9100"
        ]
      ]
    };
  }
};
</script>
