<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile class="backgroundPages">
      <!-- <v-toolbar
        dark
        color="primary"
        style="box-shadow: 0px 5px 15px -5px rgba(0,0,0, 0.6)!important"
      >
        <v-container fluid class="pa-0">
          <v-row
            dense
            class="cursor-pointer"
            align="center"
            justify="center"
            @click="close_dialog"
          >
            <v-icon x-large>mdi-chevron-down</v-icon>
            <h3 class="mx-2">
              {{ $t(fullPageDialog.title) | capitalize }}
            </h3>
            <v-icon x-large>mdi-chevron-down</v-icon>
          </v-row>
        </v-container>
      </v-toolbar> -->
      <Curtain-toolbar
        :label="$t(fullPageDialog.title) | capitalize"
        @clicked="close_dialog"
      />
      <component :is="fullPageDialog.component"> </component>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CurtainToolbar from "./CurtainToolbar.vue";
export default {
  computed: {
    ...mapGetters(["fullPageDialog"])
  },
  components: {
    profil: () => import("../../pages/myProfil/profil.vue"),
    authorizations: () =>
      import("../../pages/authorizations/authorizations.vue"),
    billing: () => import("../../pages/billing/billing.vue"),
    Rate: () => import("../../pages/billing/Rate.vue"),
    managementalerts: () =>
      import("../../pages/myAlerts/myAlertManagement.vue"),
    CurtainToolbar
  },
  watch: {
    fullPageDialog(newValue) {
      if (newValue.title != "") {
        this.dialog = true;
      } else this.dialog = false;
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    close_dialog() {
      this.$store.commit("update_fullPageDialog", {
        title: "",
        component: ""
      });
    }
  }
};
</script>
