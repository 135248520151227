<template>
  <v-dialog v-model="openDialog" max-width="1000">
    <v-form
      v-model="validForm"
      ref="formNewUserInvitation"
      @submit.prevent="submit_form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title primary--text">
          Ajouter un accès à un compte/classeur
        </v-card-title>

        <v-card-subtitle>
          <v-alert text dense type="warning" class="body-2 mb-0 mt-1">
            Attention, donner l'accès au compte à un utilisateur lui donnera
            accès à tous les classeurs associés.
          </v-alert>
        </v-card-subtitle>

        <v-card-text
          style="max-height: 600px; overflow-y: auto"
          class="thinscrollbar"
        >
          <v-row dense>
            <v-col cols="6">
              <v-row dense>
                <!-- Who ? -->
                <v-col cols="12">
                  <span class="subtitle-1 grey--text">Pour </span>
                  <span class="subtitle-1 primary--text">{{
                    userSelected.displayName
                  }}</span>
                </v-col>
                <!-- Autorization -->
                <v-col cols="12" class="mt-2">
                  <span class="subtitle-1 primary--text"
                    >Quel seront ses droits d'accès ?
                  </span>
                </v-col>
                <v-col cols="12" md="10">
                  <v-select
                    v-model="form.profilPath"
                    :items="grantList"
                    label="Rôle de l'utilisateur"
                    item-text="profilLabel"
                    item-value="profilPath"
                    :rules="[rules.required]"
                    :loading="loadGrantList"
                    :disabled="loadForm"
                    dense
                    outlined
                    color="primary"
                  ></v-select>
                </v-col>

                <!-- Favori -->
                <v-col cols="12">
                  <v-checkbox
                    v-model="unitSelected.asFavorite"
                    label="Ajouter dans la liste des favoris"
                    class="ma-0"
                    inset
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row dense>
                <!-- Selection classeur -->
                <v-col cols="12">
                  <span class="subtitle-1 primary--text"
                    >Sur quel comtpe/classeur ajouter l'accès ?</span
                  >
                  <span> (sélection obligatoire)</span>
                </v-col>
                <v-alert
                  v-model="showAlertServiceRequire"
                  dismissible
                  type="warning"
                  dense
                  text
                  class="body-2 mx-0 mt-1 mb-1"
                >
                  Attention ! La sélection d'un compte/classeur est obligatoire
                </v-alert>
                <v-col
                  cols="12"
                  style="max-height: 300px; overflow-y: auto"
                  class="thinscrollbar"
                >
                  <v-row dense>
                    <!-- Tenant card btn -->
                    <v-col cols="12" sm="6" v-if="tenantsFiltered.length > 0">
                      <v-card
                        min-height="100"
                        height="100%"
                        :color="
                          tenantsFiltered[0].backColor != null
                            ? tenantsFiltered[0].backColor
                            : 'secondary'
                        "
                        @click="unitSelected = tenantsFiltered[0]"
                        class="d-flex flex-column justify-space-between"
                        :disabled="loadForm"
                      >
                        <v-card-title class="pa-1 body-1">
                          <span
                            :style="'color:' + tenantsFiltered[0].textColor"
                          >
                            {{ tenantsFiltered[0].label }}
                          </span>
                        </v-card-title>
                        <v-card-actions>
                          <span
                            class="caption"
                            :style="'color:' + tenantsFiltered[0].textColor"
                            >Compte</span
                          >
                        </v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.6"
                          color="active"
                          :value="unitSelected == tenantsFiltered[0]"
                        >
                          <v-icon x-large>mdi-check</v-icon>
                        </v-overlay>
                      </v-card>
                    </v-col>
                    <!-- BU cards btn -->
                    <v-col
                      cols="12"
                      sm="6"
                      v-for="unit of unitsFiltered"
                      :key="unit.businessUnitKey"
                    >
                      <v-card
                        min-height="100"
                        height="100%"
                        :color="
                          unit.backColor != null ? unit.backColor : 'secondary'
                        "
                        @click="unitSelected = unit"
                        class="d-flex flex-column justify-space-between"
                        :disabled="loadForm"
                      >
                        <v-card-title class="pa-1 body-1">
                          <span :style="'color:' + unit.textColor">
                            {{ unit.label }}
                          </span>
                        </v-card-title>
                        <v-card-actions>
                          <span
                            class="caption"
                            :style="'color:' + unit.textColor"
                            >Service</span
                          >
                        </v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.6"
                          color="active"
                          :value="unitSelected == unit"
                        >
                          <v-icon x-large>mdi-check</v-icon>
                        </v-overlay>
                      </v-card>
                    </v-col>
                    <v-alert
                      type="warning"
                      dense
                      text
                      class="body-2 mx-0 mt-0 mb-0"
                      v-if="!servicesIsSelectable"
                    >
                      Aucun compte/classeur n'est disponible
                    </v-alert>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            @click="cancel_dialog"
            :disabled="loadForm"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            type="Submit"
            :loading="loadForm"
            :disabled="!validForm"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Tenant from "@/class/tenant.js";

export default {
  name: "DialogAddServiceAccessForUser",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    tenantOverview: {
      type: Object,
      default: null,
      require: true
    },
    userSelected: {
      type: Object,
      require: true
    },
    currentUserServices: {
      type: Array,
      default: [],
      require: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["apiKey"])
  },
  data() {
    return {
      classTenant: null,
      validForm: false,
      loadForm: false,
      form: {
        profilPath: null
      },
      unitsFiltered: [], // tenantOverview.units filtered.
      tenantsFiltered: [], // tenantOverview.tenant filtered.
      servicesIsSelectable: false, // if at least one tenant or BU(unit) is available.
      unitSelected: {
        type: null,
        tenantKey: null,
        businessUnitKey: null,
        asFavorite: true
      },
      showAlertServiceRequire: false,
      grantList: [],
      loadGrantList: false,
      rules: {
        required: value =>
          !!value || this.$options.filters.capitalize(this.$t("form.required"))
      }
    };
  },

  created() {
    this.ClassTenant = new Tenant();
    this.get_grant_list();
    this.sort_units_availables();
  },
  methods: {
    // Init
    async get_grant_list() {
      try {
        this.loadGrantList = true;
        const response = await this.ClassTenant.user_status(this.apiKey);
        this.grantList = response;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadGrantList = false;
      }
    },
    sort_units_availables() {
      if (this.currentUserServices.length > 0) {
        let unitsFiltered = [];
        // Check if BU is already subscribed
        for (const unit of this.tenantOverview.units) {
          const idx = this.currentUserServices
            .map(s => {
              return s.tenant.businessUnitKey;
            })
            .indexOf(unit.businessUnitKey);

          if (idx == -1 && unit.businessUnitKey) {
            unitsFiltered.push(unit);
          }
        }
        this.unitsFiltered = unitsFiltered;

        // check if tenant is already subscribed

        // uTenant is the tenant in the units list.
        const uTenant = this.currentUserServices.filter(t => {
          return t.tenant.tenantKey && !t.tenant.businessUnitKey;
        });

        if (
          uTenant.length > 0 &&
          this.tenantOverview.tenant.tenantKey == uTenant[0].tenant.tenantKey
        ) {
          // the user is already subscribed to the tenant
        } else {
          this.tenantsFiltered.push(this.tenantOverview.tenant);
        }

        // check if at least one service is available
        if (this.unitsFiltered.length > 0 || this.tenantsFiltered.length > 0) {
          this.servicesIsSelectable = true;
        } else {
          this.servicesIsSelectable = false;
        }
      } else {
        this.unitsFiltered = this.tenantOverview.units;
        this.tenantsFiltered.push(this.tenantOverview.tenant);
      }
    },

    // Submit

    submit_form() {
      if (this.$refs.formNewUserInvitation.validate()) {
        if (
          this.unitSelected.tenantKey != null ||
          this.unitSelected.businessUnitKey != null
        ) {
          this.showAlertServiceRequire = false;
          this.post_service_access();
        } else {
          this.showAlertServiceRequire = true;
        }
      } else {
        this.$refs.formNewUserInvitation.validate();
      }
    },

    async post_service_access() {
      try {
        this.loadForm = true;
        await this.ClassTenant.grant(
          this.apiKey,
          this.userSelected.userPath,
          this.form.profilPath,
          this.unitSelected
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("submited");
        this.loadForm = false;
        this.cancel_dialog();
      }
    },

    // Others

    cancel_dialog() {
      this.openDialog = false;
    }
  }
};
</script>
