<template>
  <v-card>
    <v-card-title class="subtitle-1 primary--text">
      {{ data.moduleLabel }}

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="info" v-bind="attrs" v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ data.moduleDescription }}</span>
      </v-tooltip> -->
    </v-card-title>
    <v-card-subtitle>
      {{ data.moduleDescription }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "GrantDetail",
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style scoped>
.test {
  white-space: normal;
  word-break: break-all;
}
</style>
