import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

// Global vuex
import AppModule from "./app";

Vue.use(Vuex);

// VuexPersistence garde les element suivant dans le store après un refresh de la page
const vuexLocal = new VuexPersistence({
  key: "ObligeStore",
  storage: window.sessionStorage,
  reducer: state => ({
    userConnected: state.userConnected,
    userAuth: state.userAuth,
    currentBu: state.currentBu,
    noChangePass: state.noChangePass,
    queryListContract: state.queryListContract,
    currentDocument: state.currentDocument,
    pageTableContract: state.pageTableContract,
    currentCharacteristic: state.currentCharacteristic,
    contractPath: state.contractPath,
    currrentContractName: state.currrentContractName,
    breadcrumbsFirstPage: state.breadcrumbsFirstPage,
    cookie: state.cookie,
    querySignIn: state.querySignIn,
    labelCurrent: state.labelCurrent,
    labelTenant: state.labelTenant,
    buildDate: state.buildDate,
    showUserTutorials: state.showUserTutorials,
  })
});

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    version: "1.0",
    buildDate: null,
    userConnected: false,
    userAuth: {
      displayName: null,
      locale: null,
      login: null,
      loginDate: null,
      nextRenewCredentialsDate: null,
      token: null
    },
    currentBu: null,
    labelCurrent: null,
    labelTenant: null,
    currentContract: null,
    notification: {
      text: null,
      context: null,
      open: false
    },
    renderComponent: true,
    renderComponentUserTips: true,
    noChangePass: false,
    countListContract: null,
    queryListContract: null, //query bleu
    queryContractOrigin: null, //query rouge
    sauveSelectCategorieListContract: null,
    statusStore: [],
    tagsStore: [],
    currentDocument: {
      mimeType: null,
      fileLink: null,
      resourceName: null,
      size: null
    },
    contractDetectionEnd: null,
    modalCGV: false,
    pageTableContract: 1,
    currentCharacteristic: Object,
    contractPath: "",
    currrentContractName: "",
    breadcrumbsFirstPage: "",
    //margin of the widget
    marginWidget: [20, 20],
    graphColor: [
      "#004637",
      "#32de8a",
      "#a2e8dd",
      "#e09f3e",
      "#9e2a2b",
      "#540b0e",
      "#462255",
      "#313b72",
      "#dba159"
    ],
    cookie: false,
    querySignIn: {},
    errorMsgBilling: null,
    displayNoSubModal: true,
    // ["#004637", "#aee205", "#56cb29", "#00b141", "#009453", "#007760"]
    fullPageDialog: {
      title: "",
      component: ""
    },
    showUserTutorials: {
      welcome: true,
      addendum: true
    },
  },
  mutations: {
    // buildDate
    upd_buildDate(state, buildDate) {
      state.buildDate = buildDate;
    },

    update_userConnected(state, userConnected) {
      state.userConnected = userConnected;
    },

    update_userAuth(state, userAuth) {
      state.userAuth = userAuth;
    },
    update_currentBu(state, currentBu) {
      state.currentBu = currentBu;
    },

    update_labelCurrent(state, labelCurrent) {
      state.labelCurrent = labelCurrent;
    },

    update_labelTenant(state, labelTenant) {
      state.labelTenant = labelTenant;
    },

    update_currentContract(state, currentContract) {
      state.currentContract = currentContract;
    },
    update_notif: (state, data) => {
      state.notification.text = data.text;
      state.notification.context = data.context;
      state.notification.open = true;
    },
    update_renderComponent: (state, renderComponent) => {
      state.renderComponent = renderComponent;
    },
    update_noChangePass: (state, noChangePass) => {
      state.noChangePass = noChangePass;
    },
    update_countListContract: (state, countListContract) => {
      state.countListContract = countListContract;
    },
    update_queryListContract: (state, queryListContract) => {
      //query bleu
      state.queryListContract = queryListContract;
    },
    update_queryContractOrigin: (state, queryContractOrigin) => {
      //query rouge
      state.queryContractOrigin = queryContractOrigin;
    },
    update_sauveSelectCategorieListContract: (
      state,
      sauveSelectCategorieListContract
    ) => {
      state.sauveSelectCategorieListContract = sauveSelectCategorieListContract;
    },
    update_statusStore: (state, statusStore) => {
      state.statusStore = statusStore;
    },
    update_tagsStore: (state, tagsStore) => {
      state.tagsStore = tagsStore;
    },
    update_currentDocument: (state, currentDocument) => {
      state.currentDocument = currentDocument;
    },
    update_contractDetectionEnd: (state, contractDetectionEnd) => {
      state.contractDetectionEnd = contractDetectionEnd;
    },
    update_modalCGV: (state, modalCGV) => {
      state.modalCGV = modalCGV;
    },
    update_pageTableContract: (state, pageTableContract) => {
      state.pageTableContract = pageTableContract;
    },
    update_currentCharacteristic: (state, currentCharacteristic) => {
      state.currentCharacteristic = currentCharacteristic;
    },
    update_contractPath: (state, contractPath) => {
      state.contractPath = contractPath;
    },
    update_renderComponentUserTips: (state, renderComponentUserTips) => {
      state.renderComponentUserTips = renderComponentUserTips;
    },
    update_currrentContractName: (state, currrentContractName) => {
      state.currrentContractName = currrentContractName;
    },
    update_marginWidget: (state, marginWidget) => {
      state.marginWidget = marginWidget;
    },
    update_graphColor: (state, graphColor) => {
      state.graphColor = graphColor;
    },
    update_breadcrumbsFirstPage: (state, breadcrumbsFirstPage) => {
      state.breadcrumbsFirstPage = breadcrumbsFirstPage;
    },
    update_cookie: (state, cookie) => {
      state.cookie = cookie;
    },
    update_querySignIn: (state, querySignIn) => {
      state.querySignIn = querySignIn;
    },
    update_errorMsgBilling: (state, errorMsgBilling) => {
      state.errorMsgBilling = errorMsgBilling;
    },
    update_displayNoSubModal: (state, displayNoSubModal) => {
      state.displayNoSubModal = displayNoSubModal;
    },
    update_fullPageDialog: (state, fullPageDialog) => {
      state.fullPageDialog = fullPageDialog;
    },
    update_show_user_tutorials(state, data) {
      Object.entries(data).map((entry) => {
        state.showUserTutorials[entry[0]] = entry[1];
      });
    },
  },
  getters: {
    version: state => state.version,
    buildDate: state => state.buildDate,
    userConnected: state => state.userConnected,
    userAuth: state => state.userAuth,
    currentBu: state => state.currentBu,
    labelCurrent: state => state.labelCurrent,
    labelTenant: state => state.labelTenant,
    currentContract: state => state.currentContract,
    notification: state => state.notification,
    renderComponent: state => state.renderComponent,
    noChangePass: state => state.noChangePass,
    countListContract: state => state.countListContract,
    queryListContract: state => state.queryListContract, //query bleu
    queryContractOrigin: state => state.queryContractOrigin, //query rouge
    sauveSelectCategorieListContract: state =>
      state.sauveSelectCategorieListContract,
    statusStore: state => state.statusStore,
    tagsStore: state => state.tagsStore,
    currentDocument: state => state.currentDocument,
    contractDetectionEnd: state => state.contractDetectionEnd,
    modalCGV: state => state.modalCGV,
    pageTableContract: state => state.pageTableContract,
    currentCharacteristic: state => state.currentCharacteristic,
    contractPath: state => state.contractPath,
    renderComponentUserTips: state => state.renderComponentUserTips,
    currrentContractName: state => state.currrentContractName,
    marginWidget: state => state.marginWidget,
    graphColor: state => state.graphColor,
    breadcrumbsFirstPage: state => state.breadcrumbsFirstPage,
    cookie: state => state.cookie,
    querySignIn: state => state.querySignIn,
    errorMsgBilling: state => state.errorMsgBilling,
    displayNoSubModal: state => state.displayNoSubModal,
    fullPageDialog: state => state.fullPageDialog,
    apiKey: state => state.currentBu.tenant.apiKey
  },
  modules: {
    app: AppModule
  }
});

export default store;
