<template>
  <v-dialog v-model="openDialog" width="600" persistent>
    <v-card>
      <v-form
        v-model="validForm"
        @submit.prevent="submit_form"
        ref="formBroadcast"
        lazy-validation
      >
        <v-card-title class="title primary--text">
          Diffuser un message à votre équipe
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Titre du message"
                v-model="form.messageShortDescription"
                maxlength="64"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Message"
                v-model="form.messageLongDescription"
                maxlength="1024"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Type de message"
                v-model="form.messageType"
                :items="messageType"
                item-text="label"
                item-value="key"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Niveau de validation"
                v-model="form.messageLevel"
                :items="messageLevel"
                item-text="label"
                item-value="key"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            @click="cancel_form"
            :disabled="loadForm"
            min-width="100"
            color="primary"
            text
          >
            Annuler
          </v-btn>
          <v-btn
            type="submit"
            :loading="loadForm"
            :disabled="!validForm"
            min-width="100"
            color="primary"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Setting from "@/class/setting.js";

export default {
  name: "DialogSetBrodcastMessage",
  props: {
    showDialog: {
      type: Boolean // .sync mandatory
    },
    isEdit: {
      type: Boolean,
      require: true,
      default: false
    },
    editData: {
      type: Object,
      require: false,
      default: null
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      }
    },
    ...mapGetters(["currentBu"])
  },
  data() {
    return {
      validForm: true,
      loadForm: false,
      ClassSetting: null,
      form: {
        messageShortDescription: null,
        messageLongDescription: null,
        messageType: null,
        messageLevel: null,
        documentUUID: null // for edit.
      },
      rules: {
        required: v => !!v || "Ce champ est requis."
      },
      messageType: [
        { label: "Astuce", key: "TIPS_OF_THE_DAY" },
        { label: "Information", key: "ACKNOWLEDGE" },
        { label: "Validation", key: "AGREEMENT" }
      ],
      messageLevel: [
        { label: "Par classeur", key: "BUSINESS_LEVEL" },
        { label: "Par utilisateur", key: "USER_LEVEL" }
      ]
    };
  },

  created() {
    if (this.isEdit) {
      this.assign_edit_data();
    }
  },
  methods: {
    // Edit
    assign_edit_data() {
      for (const key in this.editData) {
        let check = Object.hasOwnProperty.call(this.form, key);
        if (check) {
          this.form[key] = this.editData[key];
        }
      }
    },

    // Submit

    submit_form() {
      if (this.$refs.formBroadcast.validate()) {
        this.set_broadcast();
      } else {
        this.$refs.formBroadcast.validate();
      }
    },
    async set_broadcast() {
      try {
        this.loadForm = true;
        if (this.setting == null) {
          this.ClassSetting = new Setting();
        }
        await this.ClassSetting.set_broadcast_message(
          this.currentBu.tenant.apiKey,
          this.form
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("submited");
        this.loadForm = false;
        this.cancel_form();
      }
    },

    cancel_form() {
      this.openDialog = false;
    }
  }
};
</script>
